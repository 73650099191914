const fontList = {
  list () {
    const list = [
      {
        family: 'Architects Daughter',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v11',
        lastModified: '2020-09-10',
        files: {
          regular: 'http://fonts.gstatic.com/s/architectsdaughter/v11/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Pacifico',
        variants: [
          'regular'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v17',
        lastModified: '2020-09-10',
        files: {
          regular: 'http://fonts.gstatic.com/s/pacifico/v17/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Kaushan Script',
        variants: [
          'regular'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v9',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/kaushanscript/v9/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Mansalva',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v4',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/mansalva/v4/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Molle',
        variants: [
          'italic'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v11',
        lastModified: '2021-03-19',
        files: {
          italic: 'http://fonts.gstatic.com/s/molle/v11/E21n_dL5hOXFhWEsXzgmVydREus.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Permanent Marker',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v10',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/permanentmarker/v10/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Yeseva One',
        variants: [
          'regular'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v15',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/yesevaone/v15/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Damion',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v10',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/damion/v10/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Six Caps',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v11',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/sixcaps/v11/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Medula One',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v12',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/medulaone/v12/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Stint Ultra Condensed',
        variants: [
          'regular'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v11',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/stintultracondensed/v11/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      },
      {
        family: 'BenchNine',
        variants: [
          '300',
          'regular',
          '700'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v9',
        lastModified: '2020-09-02',
        files: {
          300: 'http://fonts.gstatic.com/s/benchnine/v9/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf',
          regular: 'http://fonts.gstatic.com/s/benchnine/v9/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf',
          700: 'http://fonts.gstatic.com/s/benchnine/v9/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Wire One',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v13',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/wireone/v13/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Dorsa',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v13',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/dorsa/v13/yYLn0hjd0OGwqo493XCFxAnQ.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Tulpen One',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v12',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/tulpenone/v12/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Andika',
        variants: [
          'regular'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v12',
        lastModified: '2020-07-23',
        files: {
          regular: 'http://fonts.gstatic.com/s/andika/v12/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Advent Pro',
        variants: [
          '100',
          '200',
          '300',
          'regular',
          '500',
          '600',
          '700'
        ],
        subsets: [
          'greek',
          'latin',
          'latin-ext'
        ],
        version: 'v11',
        lastModified: '2020-09-02',
        files: {
          100: 'http://fonts.gstatic.com/s/adventpro/v11/V8mCoQfxVT4Dvddr_yOwjVmtLZxcBtItFw.ttf',
          200: 'http://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjfWMDbZyCts0DqQ.ttf',
          300: 'http://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjZGPDbZyCts0DqQ.ttf',
          regular: 'http://fonts.gstatic.com/s/adventpro/v11/V8mAoQfxVT4Dvddr_yOwtT2nKb5ZFtI.ttf',
          500: 'http://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjcmODbZyCts0DqQ.ttf',
          600: 'http://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjeWJDbZyCts0DqQ.ttf',
          700: 'http://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjYGIDbZyCts0DqQ.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Signika Negative',
        variants: [
          '300',
          'regular',
          '500',
          '600',
          '700'
        ],
        subsets: [
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v15',
        lastModified: '2021-11-10',
        files: {
          300: 'http://fonts.gstatic.com/s/signikanegative/v15/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAr5S73st9hiuEq8.ttf',
          regular: 'http://fonts.gstatic.com/s/signikanegative/v15/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS73st9hiuEq8.ttf',
          500: 'http://fonts.gstatic.com/s/signikanegative/v15/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqVS73st9hiuEq8.ttf',
          600: 'http://fonts.gstatic.com/s/signikanegative/v15/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAp5TL3st9hiuEq8.ttf',
          700: 'http://fonts.gstatic.com/s/signikanegative/v15/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RApATL3st9hiuEq8.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Montserrat',
        variants: [
          '100',
          '100italic',
          '200',
          '200italic',
          '300',
          '300italic',
          'regular',
          'italic',
          '500',
          '500italic',
          '600',
          '600italic',
          '700',
          '700italic',
          '800',
          '800italic',
          '900',
          '900italic'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v18',
        lastModified: '2021-08-10',
        files: {
          100: 'http://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm45_QphziTn89dtpQ.ttf',
          '100italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUOjIg1_i6t8kCHKm459WxZqi7j0dJ9pTOi.ttf',
          200: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_aZA7g7J_950vCo.ttf',
          '200italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZBg_D-_xxrCq7qg.ttf',
          300: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_cJD7g7J_950vCo.ttf',
          '300italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZYgzD-_xxrCq7qg.ttf',
          regular: 'http://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf',
          italic: 'http://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf',
          500: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC7g7J_950vCo.ttf',
          '500italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZOg3D-_xxrCq7qg.ttf',
          600: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf',
          '600italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrD-_xxrCq7qg.ttf',
          700: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE7g7J_950vCo.ttf',
          '700italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZcgvD-_xxrCq7qg.ttf',
          800: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H7g7J_950vCo.ttf',
          '800italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjD-_xxrCq7qg.ttf',
          900: 'http://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG7g7J_950vCo.ttf',
          '900italic': 'http://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZSgnD-_xxrCq7qg.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Raleway',
        variants: [
          '100',
          '200',
          '300',
          'regular',
          '500',
          '600',
          '700',
          '800',
          '900',
          '100italic',
          '200italic',
          '300italic',
          'italic',
          '500italic',
          '600italic',
          '700italic',
          '800italic',
          '900italic'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v22',
        lastModified: '2021-07-01',
        files: {
          100: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf',
          200: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf',
          300: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf',
          regular: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf',
          500: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf',
          600: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf',
          700: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf',
          800: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf',
          900: 'http://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf',
          '100italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf',
          '200italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf',
          '300italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf',
          italic: 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf',
          '500italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf',
          '600italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf',
          '700italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf',
          '800italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf',
          '900italic': 'http://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Oswald',
        variants: [
          '200',
          '300',
          'regular',
          '500',
          '600',
          '700'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v40',
        lastModified: '2021-08-10',
        files: {
          200: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf',
          300: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf',
          regular: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf',
          500: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf',
          600: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf',
          700: 'http://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Inter',
        variants: [
          '100',
          '200',
          '300',
          'regular',
          '500',
          '600',
          '700',
          '800',
          '900'
        ],
        subsets: [
          'cyrillic',
          'cyrillic-ext',
          'greek',
          'greek-ext',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v7',
        lastModified: '2021-11-10',
        files: {
          100: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
          200: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
          300: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
          regular: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
          500: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
          600: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
          700: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
          800: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
          900: 'http://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'DM Sans',
        variants: [
          'regular',
          'italic',
          '500',
          '500italic',
          '700',
          '700italic'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v6',
        lastModified: '2020-11-06',
        files: {
          regular: 'http://fonts.gstatic.com/s/dmsans/v6/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf',
          italic: 'http://fonts.gstatic.com/s/dmsans/v6/rP2Fp2ywxg089UriCZaIGDWCBl0O8Q.ttf',
          500: 'http://fonts.gstatic.com/s/dmsans/v6/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf',
          '500italic': 'http://fonts.gstatic.com/s/dmsans/v6/rP2Ap2ywxg089UriCZaw7BymDnYS-Cjk6Q.ttf',
          700: 'http://fonts.gstatic.com/s/dmsans/v6/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf',
          '700italic': 'http://fonts.gstatic.com/s/dmsans/v6/rP2Ap2ywxg089UriCZawpBqmDnYS-Cjk6Q.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Work Sans',
        variants: [
          '100',
          '200',
          '300',
          'regular',
          '500',
          '600',
          '700',
          '800',
          '900',
          '100italic',
          '200italic',
          '300italic',
          'italic',
          '500italic',
          '600italic',
          '700italic',
          '800italic',
          '900italic'
        ],
        subsets: [
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v13',
        lastModified: '2021-11-10',
        files: {
          100: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf',
          200: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf',
          300: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf',
          regular: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf',
          500: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf',
          600: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf',
          700: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf',
          800: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf',
          900: 'http://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf',
          '100italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf',
          '200italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf',
          '300italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf',
          italic: 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf',
          '500italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf',
          '600italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf',
          '700italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf',
          '800italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf',
          '900italic': 'http://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Eczar',
        variants: [
          'regular',
          '500',
          '600',
          '700',
          '800'
        ],
        subsets: [
          'devanagari',
          'latin',
          'latin-ext'
        ],
        version: 'v9',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/eczar/v9/BXRlvF3Pi-DLmw0iBu9y8Hf0.ttf',
          500: 'http://fonts.gstatic.com/s/eczar/v9/BXRovF3Pi-DLmzXWL8t622v9WNjW.ttf',
          600: 'http://fonts.gstatic.com/s/eczar/v9/BXRovF3Pi-DLmzX6KMt622v9WNjW.ttf',
          700: 'http://fonts.gstatic.com/s/eczar/v9/BXRovF3Pi-DLmzWeKct622v9WNjW.ttf',
          800: 'http://fonts.gstatic.com/s/eczar/v9/BXRovF3Pi-DLmzWCKst622v9WNjW.ttf'
        },
        category: 'serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Poppins',
        variants: [
          '100',
          '100italic',
          '200',
          '200italic',
          '300',
          '300italic',
          'regular',
          'italic',
          '500',
          '500italic',
          '600',
          '600italic',
          '700',
          '700italic',
          '800',
          '800italic',
          '900',
          '900italic'
        ],
        subsets: [
          'devanagari',
          'latin',
          'latin-ext'
        ],
        version: 'v15',
        lastModified: '2020-11-06',
        files: {
          100: 'http://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf',
          '100italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf',
          200: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf',
          '200italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf',
          300: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
          '300italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf',
          regular: 'http://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
          italic: 'http://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf',
          500: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf',
          '500italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf',
          600: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
          '600italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf',
          700: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf',
          '700italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf',
          800: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf',
          '800italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf',
          900: 'http://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf',
          '900italic': 'http://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Chivo',
        variants: [
          '300',
          '300italic',
          'regular',
          'italic',
          '700',
          '700italic',
          '900',
          '900italic'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v12',
        lastModified: '2020-09-02',
        files: {
          300: 'http://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjDY8Z_uqzGQC_-.ttf',
          '300italic': 'http://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteUp9sKjkRT_-bF0.ttf',
          regular: 'http://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFoBvS-J3kbDP.ttf',
          italic: 'http://fonts.gstatic.com/s/chivo/v12/va9G4kzIxd1KFrBtQeZVlKDPWTY.ttf',
          700: 'http://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjTZMZ_uqzGQC_-.ttf',
          '700italic': 'http://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteVp6sKjkRT_-bF0.ttf',
          900: 'http://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjrZsZ_uqzGQC_-.ttf',
          '900italic': 'http://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteWJ4sKjkRT_-bF0.ttf'
        },
        category: 'sans-serif',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Indie Flower',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v12',
        lastModified: '2020-09-10',
        files: {
          regular: 'http://fonts.gstatic.com/s/indieflower/v12/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Amatic SC',
        variants: [
          'regular',
          '700'
        ],
        subsets: [
          'cyrillic',
          'hebrew',
          'latin',
          'latin-ext',
          'vietnamese'
        ],
        version: 'v16',
        lastModified: '2021-03-24',
        files: {
          regular: 'http://fonts.gstatic.com/s/amaticsc/v16/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf',
          700: 'http://fonts.gstatic.com/s/amaticsc/v16/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Kalam',
        variants: [
          '300',
          'regular',
          '700'
        ],
        subsets: [
          'devanagari',
          'latin',
          'latin-ext'
        ],
        version: 'v11',
        lastModified: '2020-09-02',
        files: {
          300: 'http://fonts.gstatic.com/s/kalam/v11/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf',
          regular: 'http://fonts.gstatic.com/s/kalam/v11/YA9dr0Wd4kDdMuhWMibDszkB.ttf',
          700: 'http://fonts.gstatic.com/s/kalam/v11/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Kristi',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v12',
        lastModified: '2020-07-23',
        files: {
          regular: 'http://fonts.gstatic.com/s/kristi/v12/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Homemade Apple',
        variants: [
          'regular'
        ],
        subsets: [
          'latin'
        ],
        version: 'v11',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/homemadeapple/v11/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Seaweed Script',
        variants: [
          'regular'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v8',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/seaweedscript/v8/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Just Me Again Down Here',
        variants: [
          'regular'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v14',
        lastModified: '2021-03-19',
        files: {
          regular: 'http://fonts.gstatic.com/s/justmeagaindownhere/v14/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf'
        },
        category: 'handwriting',
        kind: 'webfonts#webfont'
      },
      {
        family: 'Abril Fatface',
        variants: [
          'regular'
        ],
        subsets: [
          'latin',
          'latin-ext'
        ],
        version: 'v12',
        lastModified: '2020-09-02',
        files: {
          regular: 'http://fonts.gstatic.com/s/abrilfatface/v12/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf'
        },
        category: 'display',
        kind: 'webfonts#webfont'
      }
    ]

    return list
  }
}

export default fontList
