import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// Get a Firestore instance
export const db = firebase
  .initializeApp({
    // apiKey: 'AIzaSyD-xkLEvMr2wbdcDwdmOsTMH87Ei_K2Ukk',
    // authDomain: 'instawall-vue-2021.firebaseapp.com',
    // projectId: 'instawall-vue-2021'
    apiKey: process.env.VUE_APP_FB_KEY,
    authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECT_ID
  })
  .firestore()
// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })
