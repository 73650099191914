<template>
  <div class="upload-ctn">
          <!-- <uploadCare :type="'photos'" :buttonName="'Upload Photos'" :system-dialog="true" :multipleMax="32" :multiple="true"></uploadCare> -->
            <!-- <button @click="openuploader">Upload Images</button> -->
        <div class="loader-uploads" :class="{ active: progress }">
          <div class="sk-chase" v-if="progress">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
        </div>

        <input type="hidden" role="uploadcare-uploader"
          :id="id"
          data-btn-text="Upload foto's"
          data-public-key="50768dea87907fecea30"
          data-images-only
          data-system-dialog
          data-multiple
        />
  </div>
</template>

<script>
import newStore from '@/store/newstore'
import uploadcare from 'uploadcare-widget'
import elementsLoader from '@/helpers/elements'

import { Timestamp } from '@/api/db'

export default {
  components: {
  },
  data () {
    return {
      progress: false,
      id: 'Roli' + Math.floor(Math.random() * 10),
      widget: null
    }
  },
  mounted () {
    var widget = uploadcare.MultipleWidget('#' + this.id)
    var self = this
    self.widget = widget
    widget.inputElement.nextSibling.querySelector('.uploadcare--widget__button_type_open').innerHTML = this.widget.inputElement.getAttribute('data-btn-text') + ' (' + (this.total - this.count) + '/' + this.total + ')'
    widget.onUploadComplete(function (info) {
      widget.value(null)
      self.progress = false
      // Handle uploaded file info.
    })

    widget.onChange(function (group) {
      if (group) {
        self.progress = true
        // group // group object
        group.files() // array of file objects
        // group.files().forEach(element => {
        for (const element of group.files()) {
          element.done(function (info) {
            info.thumbnail = info.originalUrl + info.name
            var payload = {
              date: Timestamp.fromDate(new Date()),
              cdnUrl: info.cdnUrl,
              isImage: info.isImage,
              isStored: info.isStored,
              mimeType: info.mimeType,
              originalUrl: info.originalUrl,
              thumbnail: info.thumbnail || null,
              originalImageInfo: info.originalImageInfo,
              uuid: info.uuid
            }
            newStore.dispatch('uploadImages', payload)
            elementsLoader.methods.addImage(payload)
          })
        }
        // newStore.commit('activeDesign', this.activeDesign)
        self.$postEvent('iframeClickEvent', 'Upload Fotos')
      }
    })
  },
  watch: {
    count (oldCount, newCount) {
      console.log(newCount)
      this.widget.inputElement.nextSibling.querySelector('.uploadcare--widget__button_type_open').innerHTML = this.widget.inputElement.getAttribute('data-btn-text') + ' (' + (this.total - this.count) + '/' + this.total + ')'
    }
  },
  methods: {
    checkUsed (id) {
      // const search = this.usedImages.indexOf(id)
      let search = []
      if (id) {
        search = this.checkUsedImages.filter(el => el === id.uuid)
      }
      return search.length
    }
  },
  computed: {
    checkUsedImages () {
      const usedImages = []
      if (newStore.state.activeDesign) {
        const keys = newStore.state.activeDesign.content[0].items
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].type === 'image' && keys[i].object.uuid) {
            const idArray = keys[i].object.uuid
            usedImages.push(idArray)
          }
        }
      }
      return usedImages
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    },
    count () {
      if (this.activeDesign) {
        const search = this.activeDesign.content[0].items.filter(item => item.type === 'empty').length
        // const used = this.total - search
        return search
      } else {
        return null
      }
    },
    total () {
      if (this.activeDesign) {
        return this.activeDesign.content[0].items.length
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.itemVisible {
  visibility: hidden;
}
.loader-uploads {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  font-size: 12px;
  &.active {
    display: flex;
    background:#f5f5f5;
  }
}

.floating .loader-uploads {
  &.active {
    background: none;
  }
}

.upload-ctn {
  overflow: hidden;
  .uploadcare--widget__progress, .uploadcare--widget__text, .uploadcare--widget__button_type_cancel , .uploadcare--widget__button_type_remove {
    display: none !important;
  }

.sk-chase {
  width: 20px;
  height: 20px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #EFB7C8;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}
}

.uploadcare--widget__button, .uploadcare--widget__button:hover {
  width: 80px !important;
  height: 80px !important;
    white-space: normal;
    padding: 0;
}

.floating {
  display: flex;
  justify-content: center;
  .uploadcare--widget {
    width: auto;
    max-width: 250px;
    justify-self: center;
    display: flex;
  }
  .uploadcare--widget__button, .uploadcare--widget__button:hover {
    width: 100% !important;
    height: auto !important;
    padding: 10px 20px;
    border-radius: 99px;
    font-size: 12px;
    font-weight: bold;
  }

}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: calc(100% + 10px);
    height: 100%;
    margin-left: -10px;
    margin-right:-10px;
    padding:10px;
    align-items: center;
    .media-grid-item {
      max-height: 80px;
      max-width: 80px;
      min-width: 80px;
      align-items: normal;
      img {
        max-width: 80px;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
