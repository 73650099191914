import newStore from '@/store/newstore'
import Konva from 'konva'

const previewCreator = {
  create (callback) {
    const design = newStore.state.activeDesign

    let scale = 0.25
    let scaleFactor = 4
    let imageFactor = 3

    if (callback === 'preview') {
      scale = 0.5
      scaleFactor = 2
      imageFactor = 2
    }

    const stageContainer = new Konva.Stage({
      container: 'previewContainerVue',
      width: design.content[0].sizes.w / scaleFactor,
      height: design.content[0].sizes.h / scaleFactor,
      scale: {
        x: scale,
        y: scale
      }
    })

    const backgroundColor = design.content[0].background
    // const gridEnabled = (design.content[0].type === 'grid') || false
    const gridSize = design.content[0].gridSize

    var layer = new Konva.Layer()
    var backgrounRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: design.content[0].sizes.w,
      height: design.content[0].sizes.h,
      fill: backgroundColor,
      stroke: 'none',
      strokeWidth: 0
    })
    // add the shape to the layer
    layer.add(backgrounRect)

    var length = design.content[0].items.length
    var items = design.content[0].items
    const totalImages = items.filter(item => item.type === 'image' || item.type === 'shape').length
    let imagesLoadCounter = 0
    // .log(totalImages)
    var groupies = []

    for (var i = 0; i < length; i++) {
      const item = items[i]
      // await doc.rect(item.x + bleed + (correction), item.y + (correction) + bleed, item.width - (correction * 2), item.height - (correction * 2))

      groupies[i] = new Konva.Group({
        x: item.x + gridSize,
        y: item.y + gridSize,
        draggable: false
      })

      groupies[i].zIndex(i)
      layer.add(groupies[i])
      if (item.type === 'image' || item.type === 'shape') {
        groupies[i].clip({
          x: 0,
          y: 0,
          width: item.width - (gridSize * 2),
          height: item.height - (gridSize * 2)
        })
        const group = groupies[i]
        // console.log(item.content.thumbnail + '-/resize/' + Math.round(design.content[0].sizes.w / 3) + 'x/')

        Konva.Image.fromURL(item.content.thumbnail + '-/resize/' + Math.round(design.content[0].sizes.w / imageFactor) + 'x/', function (darthNode) {
          // console.log(darthNode.attrs.image.height)
          const imageSize = {
            width: darthNode.attrs.image.width,
            height: darthNode.attrs.image.height
          }
          let cropper
          if (item.content.crop.px || item.content.crop.py || item.content.crop.px === 0 || item.content.crop.py === 0) {
            // cropper = getCrop(imageSize, item, 'left-top')

            let scale, dim, w, h, x, y
            const correction = gridSize
            const aspectRatio = item.width / item.height
            const imageRatio = imageSize.width / imageSize.height
            if (aspectRatio > 1) {
              scale = (item.height - (correction)) / imageSize.height
              dim = 'landscape'
              if (imageRatio < 1) {
                scale = (item.width - (correction)) / imageSize.width
                dim = 'portrait'
              } else if (imageRatio === 1) {
                scale = (item.width - (correction)) / imageSize.width
                dim = 'square'
              }
            } else if (aspectRatio < 1) {
              scale = (item.height - (correction)) / imageSize.height
              // dim = 'landscape'
              if (imageRatio < 1) {
                // scale = (item.width - (correction)) / item.content.object.originalImageInfo.width
                dim = 'portrait'
              }
            } else {
              scale = (item.height - (correction)) / imageSize.height
              dim = 'landscape'
              if (imageRatio <= 1) {
                scale = (item.width - (correction)) / imageSize.width
                dim = 'square'
              }
            }

            const imageWidth = (imageSize.width * scale)
            const imageHeight = (imageSize.height * scale)
            w = (imageWidth) * item.content.crop.scaleX
            h = (imageHeight) * item.content.crop.scaleY
            x = (((imageWidth) / 100) * item.content.crop.px)
            y = (((imageHeight) / 100) * item.content.crop.py)

            w = w - correction
            h = h - correction

            x = (x === 0 && dim === 'landscape') ? x : x + correction
            y = (y === 0 && dim === 'portrait') ? y : y + correction

            y = (y !== 0 && dim === 'landscape') ? y - correction : y
            x = (x !== 0 && dim === 'portrait') ? x - correction : x
            y = (y !== 0 && dim === 'square') ? y - correction : y
            // y = (y !== 0 && dim === 'square') ? y - (correction * 4) : y
            // console.log(1, 'pre')
            darthNode.setAttrs({
              x: x,
              y: y,
              width: w,
              height: h
            })
          } else {
            cropper = getCrop(imageSize, item, 'center-middle')
            // console.log(2, 'pre')
            darthNode.setAttrs({
              x: cropper.cropX,
              y: cropper.cropY,
              width: cropper.cropWidth - (gridSize * 2),
              height: cropper.cropHeight - (gridSize * 2)
            })
          }

          imagesLoadCounter++

          // var box = new Konva.Rect({
          //   x: 0,
          //   y: 0,
          //   width: item.width,
          //   height: item.height,
          //   fillEnabled: false,
          //   strokeEnabled: gridEnabled,
          //   strokeScaleEnabled: false,
          //   stroke: backgroundColor,
          //   // stroke: 'yellow',
          //   strokeWidth: gridSize * 1.2
          // })

          // console.log(offsetX, offsetY, item.width, item.height, item.x, item.y)

          group.add(darthNode)
          // group.add(box)
          layer.batchDraw()
          // mainGroup.add(groups[i])
        })
      }
      if (item.type === 'text') {
        var simpleText = new Konva.Text({
          x: 0,
          y: 0,
          text: item.content.txt,
          fontSize: item.content.fontSize,
          fontStyle: 'normal',
          lineHeight: 1.34,
          fontFamily: item.content.fontFamily,
          fill: item.content.color
        })
        groupies[i].add(simpleText)
        layer.add(groupies[i])
      }
    }

    stageContainer.add(layer)

    const interval = setInterval(imagesLoaded, 250)

    function imagesLoaded () {
      if (imagesLoadCounter === totalImages) {
        var data = {
          canvasBlob: stageContainer.toDataURL({ mimeType: 'image/png', quality: 2.0 }),
          callback: callback
        }
        clearInterval(interval)
        // console.log(dataURL)
        // previewImageContainer.src = dataURL
        newStore.commit('setPreview', data)
        // newStore.state.preview = dataURL
      }
    }
    // const previewImageContainer = document.getElementById('previewImage')
    // setTimeout(function () {
    //   var dataURL = stageContainer.toDataURL({
    //     mimeType: 'image/png',
    //     quality: 1.0
    //   })
    //   console.log(dataURL)
    //   // previewImageContainer.src = dataURL
    //   // newStore.commit('setPreview', dataURL)
    //   // newStore.state.preview = dataURL
    // }, 750)
  }
}

function getCrop (image, size, clipPosition = 'center-middle') {
  const width = size.width
  const height = size.height
  const aspectRatio = width / height

  let newWidth
  let newHeight

  const imageRatio = image.width / image.height

  if (aspectRatio >= imageRatio) {
    newWidth = size.width
    newHeight = size.width / imageRatio
    if (imageRatio > 1) {
      newWidth = size.height * imageRatio
      newHeight = size.height
    }
  } else {
    newWidth = size.height * imageRatio
    newHeight = size.height
  }

  let x = 0
  let y = 0
  if (clipPosition === 'left-top') {
    x = 0
    y = 0
  } else if (clipPosition === 'left-middle') {
    x = 0
    y = (size.height - newHeight) / 2
  } else if (clipPosition === 'left-bottom') {
    x = 0
    y = size.height - newHeight
  } else if (clipPosition === 'center-top') {
    x = (image.width - newWidth) / 2
    y = 0
  } else if (clipPosition === 'center-middle') {
    x = (size.width - newWidth) / 2
    y = (size.height - newHeight) / 2
  } else if (clipPosition === 'center-bottom') {
    x = (size.width - newWidth) / 2
    y = size.height - newHeight
  } else if (clipPosition === 'right-top') {
    x = size.width - newWidth
    y = 0
  } else if (clipPosition === 'right-middle') {
    x = size.width - newWidth
    y = (size.height - newHeight) / 2
  } else if (clipPosition === 'right-bottom') {
    x = size.width - newWidth
    y = size.height - newHeight
  } else if (clipPosition === 'scale') {
    x = 0
    y = 0
    newWidth = width
    newHeight = height
  } else if (clipPosition === 'free') {
    newWidth = width * size.content.crop.scaleX
    newHeight = height * size.content.crop.scaleY
    x = 0
    y = 0
  } else {
    console.error(
      new Error('Unknown clip position property - ' + clipPosition)
    )
  }

  return {
    imageRatio: imageRatio,
    aspectRatio: aspectRatio,
    cropX: x,
    cropY: y,
    cropWidth: newWidth,
    cropHeight: newHeight
  }
}

export default previewCreator
