const list = {
  get () {
    const array = [
      // All
      {
        id: 61,
        type: 'grid',
        tags: 'single',
        title: '1x1',
        ratio: 'all',
        cols: 1,
        rows: 1,
        big: [],
        skip: [],
        override: {
          grid: 0,
          border: 0
        }
      },
      // Squares
      {
        id: 63,
        type: 'grid',
        tags: 'default',
        title: '2x2',
        ratio: 'square',
        max: [25, 25],
        cols: 2,
        rows: 2,
        big: [],
        skip: []
      },
      {
        id: 1,
        type: 'grid',
        tags: 'default',
        title: '3x3',
        ratio: 'square',
        cols: 3,
        rows: 3,
        big: [],
        skip: []
      },
      {
        id: 2,
        type: 'grid',
        tags: 'classic',
        title: '4x4',
        ratio: 'square',
        cols: 4,
        rows: 4,
        big: [],
        skip: []
      },
      {
        id: 3,
        type: 'grid',
        tags: 'classic',
        title: '5x5',
        ratio: 'square',
        cols: 5,
        rows: 5,
        big: [],
        skip: []
      },
      {
        id: 4,
        type: 'grid',
        tags: 'classic',
        title: '6x6',
        ratio: 'square',
        cols: 6,
        rows: 6,
        big: [],
        skip: []
      },
      {
        id: 5,
        type: 'grid',
        tags: 'classic',
        title: '8x8',
        ratio: 'square',
        cols: 8,
        rows: 8,
        big: [],
        skip: []
      },
      {
        id: 6,
        type: 'grid',
        tags: 'clean',
        title: '3x3',
        ratio: 'square',
        cols: 3,
        rows: 3,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [5, 7, 8]
      },
      {
        id: 7,
        type: 'grid',
        tags: 'clean',
        title: '3x3',
        ratio: 'square',
        cols: 3,
        rows: 3,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 3, 4]
      },
      {
        id: 8,
        type: 'grid',
        tags: 'custom',
        title: '5x5',
        ratio: 'square',
        cols: 5,
        rows: 5,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [7, 11, 12]
      },
      {
        id: 9,
        type: 'grid',
        tags: 'custom',
        title: '5x5',
        ratio: 'square',
        cols: 5,
        rows: 5,
        big: [
          { row: 1, col: 1, scaleX: 3, scaleY: 3 }
        ],
        skip: [7, 8, 11, 12, 13, 16, 17, 18]
      },
      {
        id: 10,
        type: 'grid',
        tags: 'custom',
        title: '5x5',
        ratio: 'square',
        cols: 5,
        rows: 5,
        big: [
          { row: 1, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 }
        ],
        skip: [6, 10, 11, 23, 24, 19]
      },
      {
        id: 11,
        type: 'grid',
        tags: 'custom',
        title: '5x5',
        ratio: 'square',
        cols: 5,
        rows: 5,
        big: [
          { row: 0, col: 3, scaleX: 2, scaleY: 2 },
          { row: 1, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 }
        ],
        skip: [4, 6, 8, 9, 10, 11, 23, 24, 19]
      },
      {
        id: 12,
        type: 'grid',
        tags: 'custom',
        title: '6x6',
        ratio: 'square',
        cols: 6,
        rows: 6,
        big: [
          { row: 1, col: 1, scaleX: 3, scaleY: 3 }
        ],
        skip: [8, 9, 13, 14, 15, 19, 20, 21]
      },
      {
        id: 13,
        type: 'grid',
        tags: 'custom',
        title: '6x6',
        ratio: 'square',
        cols: 6,
        rows: 6,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 1, col: 3, scaleX: 2, scaleY: 2 },
          { row: 4, col: 1, scaleX: 2, scaleY: 2 },
          { row: 4, col: 4, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 6, 7, 10, 15, 16, 26, 29, 31, 32, 34, 35]
      },
      {
        id: 14,
        type: 'grid',
        tags: 'custom',
        title: '6x6',
        ratio: 'square',
        cols: 6,
        rows: 6,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 1, col: 4, scaleX: 2, scaleY: 2 },
          { row: 4, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 6, 7, 11, 16, 17, 27, 32, 33]
      },
      {
        id: 15,
        type: 'grid',
        tags: 'custom',
        title: '8x8',
        ratio: 'square',
        cols: 8,
        rows: 8,
        big: [
          { row: 0, col: 2, scaleX: 2, scaleY: 2 },
          { row: 2, col: 6, scaleX: 2, scaleY: 2 },
          { row: 4, col: 0, scaleX: 2, scaleY: 2 },
          { row: 6, col: 4, scaleX: 2, scaleY: 2 }
        ],
        skip: [3, 10, 11, 23, 30, 31, 33, 40, 41, 53, 60, 61]
      },
      {
        id: 16,
        type: 'grid',
        tags: 'custom',
        title: '8x8',
        ratio: 'square',
        cols: 8,
        rows: 8,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 1, col: 6, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 },
          { row: 5, col: 0, scaleX: 2, scaleY: 2 },
          { row: 6, col: 6, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 8, 9, 15, 22, 23, 28, 35, 36, 41, 48, 49, 55, 62, 63]
      },
      {
        id: 17,
        type: 'grid',
        tags: 'custom',
        title: '8x8',
        ratio: 'square',
        cols: 8,
        rows: 8,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 },
          { row: 2, col: 5, scaleX: 2, scaleY: 2 },
          { row: 5, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [10, 17, 18, 22, 29, 30, 43, 50, 51]
      },
      {
        id: 18,
        type: 'grid',
        tags: 'clean',
        title: '2x1',
        ratio: 'square',
        cols: 2,
        rows: 1,
        big: [],
        skip: []
      },
      {
        id: 19,
        type: 'grid',
        tags: 'clean',
        title: '1x2',
        ratio: 'square',
        cols: 1,
        rows: 2,
        big: [],
        skip: []
      },
      // Landscape
      {
        id: 20,
        type: 'grid',
        tags: 'default',
        title: '4x4',
        ratio: 'landscape',
        cols: 3,
        rows: 2,
        big: [],
        skip: []
      },
      {
        id: 21,
        type: 'grid',
        tags: 'classic',
        title: '4x4',
        ratio: 'landscape',
        cols: 4,
        rows: 3,
        big: [],
        skip: []
      },
      {
        id: 211,
        type: 'grid',
        tags: 'classic',
        title: '4x4',
        ratio: 'landscape',
        cols: 5,
        rows: 4,
        big: [],
        skip: []
      },
      {
        id: 22,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'landscape',
        cols: 7,
        rows: 5,
        big: [],
        skip: []
      },
      {
        id: 23,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'landscape',
        cols: 8,
        rows: 6,
        big: [],
        skip: []
      },
      {
        id: 24,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'landscape',
        cols: 7,
        rows: 5,
        big: [
          { row: 0, col: 0, scaleX: 3, scaleY: 3 },
          { row: 2, col: 5, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 2, 7, 8, 9, 14, 15, 16, 20, 26, 27]
      },
      {
        id: 25,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'landscape',
        cols: 7,
        rows: 5,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 },
          { row: 2, col: 5, scaleX: 2, scaleY: 2 }
        ],
        skip: [9, 15, 16, 20, 26, 27]
      },
      {
        id: 26,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'landscape',
        cols: 9,
        rows: 7,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 2, col: 6, scaleX: 2, scaleY: 2 },
          { row: 4, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 9, 10, 25, 33, 34, 39, 47, 48]
      },
      {
        id: 277,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'landscape',
        cols: 1,
        rows: 2,
        big: [],
        skip: []
      },
      {
        id: 2777,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'landscape',
        cols: 2,
        rows: 1,
        big: [],
        skip: []
      },
      {
        id: 27,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'landscape',
        cols: 3,
        rows: 1,
        big: [],
        skip: []
      },
      {
        id: 28,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'landscape',
        cols: 4,
        rows: 3,
        big: [
          { row: 1, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [7, 10, 11]
      },
      {
        id: 29,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'landscape',
        cols: 4,
        rows: 3,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 4, 5]
      },
      // Portrait
      {
        id: 30,
        type: 'grid',
        tags: 'default',
        title: '4x4',
        ratio: 'portrait',
        cols: 2,
        rows: 3,
        big: [],
        skip: []
      },
      {
        id: 31,
        type: 'grid',
        tags: 'classic',
        title: '4x4',
        ratio: 'portrait',
        cols: 3,
        rows: 4,
        big: [],
        skip: []
      },
      {
        id: 32,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'portrait',
        cols: 4,
        rows: 5,
        big: [],
        skip: []
      },
      {
        id: 322,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'portrait',
        cols: 5,
        rows: 7,
        big: [],
        skip: []
      },
      {
        id: 33,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'portrait',
        cols: 7,
        rows: 10,
        big: [],
        skip: []
      },
      {
        id: 34,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'portrait',
        cols: 5,
        rows: 7,
        big: [
          { row: 0, col: 0, scaleX: 3, scaleY: 3 },
          { row: 5, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 2, 5, 6, 7, 10, 11, 12, 28, 32, 33]
      },
      {
        id: 35,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'portrait',
        cols: 5,
        rows: 7,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 },
          { row: 5, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [7, 11, 12, 28, 32, 33]
      },
      {
        id: 36,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'portrait',
        cols: 1,
        rows: 3,
        big: [],
        skip: []
      },
      {
        id: 37,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'portrait',
        cols: 3,
        rows: 4,
        big: [
          { row: 2, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [8, 10, 11]
      },
      {
        id: 38,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'portrait',
        cols: 3,
        rows: 4,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 3, 4]
      },
      // Tall
      {
        id: 39,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'tall',
        cols: 2,
        rows: 4,
        big: [],
        skip: []
      },
      {
        id: 40,
        type: 'grid',
        tags: 'default',
        title: '7x5',
        ratio: 'tall',
        cols: 3,
        rows: 6,
        big: [],
        skip: []
      },
      {
        id: 41,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 8,
        big: [],
        skip: []
      },
      {
        id: 42,
        type: 'grid',
        tags: 'classic',
        title: '10x5',
        ratio: 'tall',
        cols: 5,
        rows: 10,
        big: [],
        skip: []
      },
      {
        id: 43,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 8,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 2, scaleX: 2, scaleY: 2 },
          { row: 6, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 4, 5, 15, 18, 19, 26, 29, 30]
      },
      {
        id: 44,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 8,
        big: [
          { row: 1, col: 0, scaleX: 2, scaleY: 2 },
          { row: 5, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [5, 8, 9, 23, 26, 27]
      },
      {
        id: 45,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 8,
        big: [
          { row: 0, col: 1, scaleX: 2, scaleY: 2 },
          { row: 2, col: 1, scaleX: 2, scaleY: 2 },
          { row: 4, col: 1, scaleX: 2, scaleY: 2 },
          { row: 6, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [2, 5, 6, 10, 13, 14, 18, 21, 22, 26, 29, 30]
      },
      {
        id: 46,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 8,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 1, scaleX: 3, scaleY: 3 }
        ],
        skip: [1, 4, 5, 14, 15, 17, 18, 19, 21, 22, 23]
      },
      {
        id: 47,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 5,
        rows: 10,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 },
          { row: 6, col: 0, scaleX: 2, scaleY: 2 },
          { row: 8, col: 3, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 5, 6, 19, 23, 24, 31, 35, 36, 44, 48, 49]
      },
      {
        id: 48,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 5,
        rows: 10,
        big: [
          { row: 1, col: 1, scaleX: 2, scaleY: 2 },
          { row: 7, col: 2, scaleX: 2, scaleY: 2 }
        ],
        skip: [7, 11, 12, 38, 42, 43]
      },
      {
        id: 49,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 5,
        rows: 10,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 },
          { row: 6, col: 0, scaleX: 2, scaleY: 2 },
          { row: 8, col: 3, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 5, 6, 19, 23, 24, 31, 35, 36, 44, 48, 49]
      },

      {
        id: 4999,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'tall',
        cols: 6,
        rows: 12,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 10, col: 4, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 6, 7, 65, 70, 71]
      },
      {
        id: 499,
        type: 'grid',
        tags: 'landscape',
        title: '7x5',
        ratio: 'tall',
        cols: 4,
        rows: 10,
        big: [],
        skip: []
      },
      // Wide
      {
        id: 50,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'wide',
        cols: 8,
        rows: 4,
        big: [],
        skip: []
      },
      {
        id: 51,
        type: 'grid',
        tags: 'classic',
        title: '10x5',
        ratio: 'wide',
        cols: 10,
        rows: 5,
        big: [],
        skip: []
      },
      {
        id: 52,
        type: 'grid',
        tags: 'default',
        title: '7x5',
        ratio: 'wide',
        cols: 6,
        rows: 3,
        big: [],
        skip: []
      },
      {
        id: 53,
        type: 'grid',
        tags: 'classic',
        title: '7x5',
        ratio: 'wide',
        cols: 4,
        rows: 2,
        big: [],
        skip: []
      },
      {
        id: 62,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'wide',
        cols: 10,
        rows: 5,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 3, col: 3, scaleX: 2, scaleY: 2 },
          { row: 1, col: 7, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 10, 11, 18, 27, 28, 34, 43, 44]
      },
      {
        id: 54,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'wide',
        cols: 8,
        rows: 4,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 2, col: 3, scaleX: 2, scaleY: 2 },
          { row: 1, col: 6, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 8, 9, 20, 27, 28, 23, 15, 22]
      },
      {
        id: 55,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'wide',
        cols: 8,
        rows: 4,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 0, col: 4, scaleX: 2, scaleY: 2 },
          { row: 2, col: 2, scaleX: 2, scaleY: 2 },
          { row: 2, col: 6, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 8, 9, 5, 12, 13, 19, 23, 26, 27, 30, 31]
      },
      {
        id: 56,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'wide',
        cols: 8,
        rows: 4,
        big: [
          { row: 0, col: 5, scaleX: 2, scaleY: 2 },
          { row: 2, col: 1, scaleX: 2, scaleY: 2 }
        ],
        skip: [6, 13, 14, 18, 25, 26]
      },
      {
        id: 57,
        type: 'grid',
        tags: 'custom',
        title: '7x5',
        ratio: 'wide',
        cols: 10,
        rows: 5,
        big: [
          { row: 0, col: 0, scaleX: 2, scaleY: 2 },
          { row: 0, col: 8, scaleX: 2, scaleY: 2 },
          { row: 1, col: 4, scaleX: 2, scaleY: 2 },
          { row: 3, col: 2, scaleX: 2, scaleY: 2 },
          { row: 3, col: 7, scaleX: 2, scaleY: 2 }
        ],
        skip: [1, 10, 11, 9, 15, 18, 19, 24, 25, 33, 38, 42, 43, 47, 48]
      },
      {
        id: 58,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'wide',
        cols: 2,
        rows: 1,
        big: [],
        skip: []
      },
      {
        id: 59,
        type: 'grid',
        tags: 'clean',
        title: '7x5',
        ratio: 'wide',
        cols: 4,
        rows: 1,
        big: [],
        skip: []
      },
      // PosterStrips
      {
        id: 60,
        type: 'grid',
        tags: 'default',
        title: '4x4',
        ratio: 'strip',
        cols: 1,
        rows: 4,
        big: [],
        skip: []
      }
      // {
      //   type: 'free',
      //   tags: 'free',
      //   title: '1x1',
      //   ratio: 'all',
      //   cols: 0,
      //   rows: 0,
      //   big: [],
      //   skip: []
      // }
      // <div class="layout-item" @click="changeLayout('grid', 5, 5, [{ row: 0, col: 3, scaleX: 2, scaleY: 2}, { row: 1, col: 0, scaleX: 2, scaleY: 2}, { row: 3, col: 3, scaleX: 2, scaleY: 2}], [4, 6, 8, 9, 10, 11, 23, 24, 19])">
    ]
    return array
  }
}

export default list
