<template>
  <div id="canvas">
      <div class="container">
        <div class="sidebar" v-bind:class="{ active: sidebar }">
              <!-- <SidebarBtn class="close"></SidebarBtn> -->
              <Gallery />
          </div>
          <div class="canvas no-padding" ref="canvas">
            <!-- console.log(rect.top, rect.right, rect.bottom, rect.left) -->
            <!-- <div class="borderactive" v-if="borderPosition" v-bind:style="{ bottom: borderPosition.bottom + 'px', top: borderPosition.top + 'px', width: borderPosition.width+'px', height: borderPosition.height+'px' }">
              {{ borderPosition.left }}<br>
              {{ borderPosition.right }}<br>
              {{ borderPosition.top }}<br>
              {{ borderPosition.bottom }}
            </div> -->

            <Menu position="top"></Menu>
            <Artboard class="artboard-container" />
            <MenuMobile v-if="!doneMessage" position="bottom" class="bottom"></MenuMobile>
            <Progress v-if="!doneMessage && !overlayActive" class="progress-bottom" />
            <div class="cta-group">
              <!-- TODO button to close -->
            </div>
            <!-- <Tray></Tray> -->
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Artboard from '@/views/Canvas/Artboard'
import Gallery from '@/views/Canvas/Gallery'
// import SidebarBtn from './Gallery/components/button'
import Progress from '@/views/Canvas/Artboard/Components/progress'
import newStore from '@/store/newstore'
// import store, { Sidebar } from '@/store/interface/'
// import Tray from './Tray/'
import Menu from './Artboard/menu.vue'
import MenuMobile from './Artboard/menu-mobile.vue'

export default {
  name: 'Canvas',
  data: function () {
    return {
      // showSidebar: true
    }
  },
  mounted: function () {
    this.setSize()
  },
  created () {
    const self = this
    window.addEventListener('resize', debounce(function (e) {
      self.setSize()
    }))
    document.onkeydown = this.handleArtBoardHistoryState
    if (this.user) {
      this.$store.dispatch('updateUserDetails')
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.setSize)
  },
  methods: {
    handleArtBoardHistoryState (e) {
      var evtobj = window.event ? event : e
      if (evtobj.keyCode === 90 && evtobj.ctrlKey) {
        if (this.canUndo) {
          this.undo()
        }
      } else if (evtobj.keyCode === 89 && evtobj.ctrlKey) {
        if (this.canRedo) {
          this.redo()
        }
      }
    },
    setSize () {
      // this.$refs.tray.style.width = this.$refs.canvas.clientWidth + 'px'
    },
    toggleSidebar: function () {
      this.sidebar = !this.sidebar
      // Sidebar.toggle()
    },
    logout () {
      localStorage.removeItem('vuex')
      this.$store.commit('setUser', null)
      this.$store.commit('setActivateElement', null)
      this.$store.commit('setUploads', [])
      this.$store.commit('setDesigns', [])
    },
    toggleLoginPopup () {
      this.$store.commit('toggleComponent', 'login')
    }
  },
  components: {
    Gallery, Artboard, Menu, MenuMobile, Progress // TRAY
  },
  computed: {
    overlayActive (state) {
      return newStore.state.overlay
    },
    doneMessage (state) {
      return newStore.state.done
    },
    sidebar () {
      return newStore.state.sidebar
    },
    borderPosition () {
      const element = document.getElementById(newStore.state.activeBorder)
      if (element) {
        var rect = element.getBoundingClientRect()
        console.log(rect)
        return rect
      } else {
        return { top: 0, right: 0, width: 0, height: 0 }
      }
    }
    // user () {
    //   return this.$store.state.userStore.user
    // },
    // activeBoard () {
    //   return this.$store.state.artBoardStore.artBoards
    // },
    // activeProduct () {
    //   return this.$store.state.productsStore.product
    // }
  }
}
function debounce (func) {
  var timer
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, 10, event)
  }
}
</script>

<style lang="scss">
.borderactive {
  border: 5px solid red;
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 1000000;
  pointer-events: none;
}
html {
}
.cta-group {
  position: sticky;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  display: none;
  justify-content: center;

  @media only screen and (max-width: 760px) {
    display: flex;
  }
  .cta {
    border-radius: 99px !important;
    padding: 16px !important;
    min-width: 180px !important;
    background: black;
  }

}
.canvas {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.artboard-container {
  height: auto;
  display: flex;
  min-height: 100px;
  flex: 1;
  position: relative;
}

body {
  margin: 0;
}

.navbar {
  height: 0px;
  display: flex;
  align-items: center;
  color: #bbbbbb;
}

.navbar {
  margin-left: 10px;
}

.container {
  display: flex;
  height: calc(100% - 0px);
  /* calculate the height. Header is 30px */
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-close {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes slide-close {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.sidebar {
  width: auto;
  margin-left: 0px;
  position: relative;
  transition: margin 600ms;
  z-index:1;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  background:  #F3F5F6;
  // border-right: 1px solid #EDEFF1;
  .gallery {
    display: none;
    -webkit-animation: slide-close 2.3s ease-out;
    -moz-animation: slide-close 3.3s ease-out;
  }
  &.active {
    border-right: none;
  }
  &.active .gallery {
    display: flex;
    -webkit-animation: slide-down 2.3s ease-out;
    -moz-animation: slide-down 3.3s ease-out;
  }
  &.active .gallery-container {
      width: 520px;
  }
  .sidebar-header {
    height: 60px;
    padding: 3px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

#canvas {
  height: 100%;
}
.canvas {
  background: #F3F5F6;
  /* enable grow, disable shrink */
}

@media only screen and (max-width: 760px) {
  .artboard-container {
    flex: 1;
  }
  .container {
    width: 100%;
    display: block;
    height: 100%;
  }
  .canvas {
    width: 100%;
    flex: 1;
    height: 100%;
    background: none;
  }
  .sidebar {
    width:100%;
    margin-left: 0;
    margin-top: 100vh;
    position: fixed;
    z-index: 88;
    &.active {
      margin-top: 20px;
    }
    &.active .gallery-container {
      width: 100%;
    }
  }
}
.no-padding {
   padding: 0 !important;
 }
.app {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
}
</style>
