import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Common from '../views/common/'
import Support from '../pages/Support.vue'
import Settings from '../views/account/settings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/support',
    name: 'Support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: Support,
      common: Common
    },
    props: {
      component: Support
    }
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Products.vue')
  },
  {
    path: '/products/:id',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Products.vue')
  },
  {
    path: '/settings',
    components: {
      settings: Settings
    },
    props: { loginPopup: false }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
