<template>
  <div class="support">
    support
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Support',
  components: {
  }
}
</script>
