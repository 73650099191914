<template>
  <div class="color-ctn">
    <h4 v-if="inline">{{ $t('system.grid') }}</h4>
    <div class="scrollable-row">
      <div class="color-item">
        <div class="postCounter"><i class="far fa-check"></i></div>
        <div :style="{background: activeDesign.content[0].background}" class="item">
          <input type="color" style="border: none; opacity: 0;" id="head" @change="changeColor()" v-model="activeDesign.content[0].background">
        </div>
        <label>custom</label>
      </div>
      <div class="color-item" v-for="(color, index) in swatches" :key="index" @click="changeColor(color)" :class="{selected: activeDesign.content[0].background === color}">
                <div class="postCounter"><i class="far fa-check"></i></div>
              <div :style="{background: color}" class="item"></div>
              <label>{{ color }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import newStore from '@/store/newstore'
// import FontPicker from 'font-picker-vue'

export default {
  components: {
  },
  props: {
    type: String,
    inline: Boolean
  },
  data () {
    return {
      swatches: [
        '#FFFFFF',
        '#000000',
        '#F493A7',
        '#9196f8',
        '#FFCCD5',
        '#262A53',
        '#FFA0A0',
        '#FFE3E3',
        '#053742',
        '#39A2DB',
        '#A2DBFA',
        '#E8F0F2',
        '#0A1931',
        '#185ADB',
        '#FFC947',
        '#EFEFEF',
        '#628395'
      ]
    }
  },
  mounted () {
  },
  methods: {
    changeColor (color) {
      if (color) {
        this.activeDesign.content[0].background = color
      }
      newStore.commit('changeActiveDesign', this.activeDesign)
    }
  },
  computed: {
    activeDesign () {
      return newStore.state.activeDesign
    },
    activeElement () {
      return newStore.state.activeElement
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    }
  }
}
</script>

<style lang='scss' scoped>

.postCounter {
  display: none;
}
.selected {
  .postCounter {
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    background: #0080ff;
    border-radius: 99px;
    border: 2px solid white;
    padding: 5px;
    z-index:1;
    i {
      color: white;
    }
  }
}
.color-item {
  position: relative;
    width: 80px;
    height: 80px;
  label {
      position: absolute;
      bottom: -15px;
      font-size: 10px;
      color: black;
      width: 100%;
      left: 0;
      text-align: center;
    }
  .item {
    width: 80px;
    height: 80px;
    display: block;
    background: yellow;
  }
}
.color-ctn {
  height: auto;
  box-sizing: border-box;
  padding: 0px;
  padding-top: 10px;
    width: 100%;
    height: 100%;
    .scrollable-row {
      overflow-x: scroll;
      overflow-y: hidden;
      display: flex;
      grid-gap: 0.5rem;
      width: 100%;
      height: 100%;
      justify-content: stretch;
      align-items: flex-start;
      .color-item {
        width: 80px;
        height: 80px;
        max-width: 80px;
        max-height: 80px;
        .item {
          border-radius: 10px;
          border: 1px solid #E4E4E4;
        }
      }
  }
}
.fontEdit {
  font-size: 16px !important;
  width: 100%;
  box-sizing: border-box;
}
</style>
