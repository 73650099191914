<template>
  <div class="font-ctn">
          <!-- FontPicker -->
          <div class="scrollable-list" v-if="type === 'font'">
                  <!-- <FontPicker class="custom-font-picker" style="display: none;"
                              :api-key="'AIzaSyD-xkLEvMr2wbdcDwdmOsTMH87Ei_K2Ukk'" :options="options"
                              :active-font="activeElement.obj.fontFamily ? activeElement.obj.fontFamily : 'Roboto'"
                              @change="fontChanged"></FontPicker> -->
                <div v-for="(font, index) in fontList" :key="index" class="list-item">
                  <div @click="fontChange(font)" class="item"><span :style="{ fontFamily: font.family, fontSize: '28px' }">{{ activeElement.content.txt || $t("system.startTyping") }}</span><br><span>{{ font.family }}</span></div>
                </div>
          </div>
          <div v-if="type === 'size'">
          <input type="range" id="cowbell" name="cowbell" class="fontEdit"
                min="20" @change="fontSizeChanged" max="750" v-model="activeElement.content.fontSize" step="10">
                 {{ activeElement.content.fontSize }}
          </div>

          <input v-if="type === 'edit'" type="text" id="name" class="fontEdit" name="name" v-model="activeElement.content.txt"
       minlength="0" maxlength="30" size="10" @change="textChange()" :placeholder="$t('system.startTyping')">
  </div>
</template>

<script>
import newStore from '@/store/newstore'
// import FontPicker from 'font-picker-vue'
import fontLoader from '@/helpers/FontLoader'

import fontlist from './font-list.js'

export default {
  components: {
  },
  props: {
    type: String
  },
  data () {
    return {
      options: {
        name: 'Artboard'
      }
    }
  },
  mounted () {
    if (this.googleFonts === null) {
      // this.retrieveFonts()
    }
    for (var i = 0; i < this.fontList.length; i++) {
      const name = this.fontList[i].family
      if (!this.fontsLoaded.includes(name)) {
        fontLoader.methods.load(name)
        newStore.commit('addFontLoaded', name)
      }
    }
  },
  methods: {
    fontSizeChanged () {
      newStore.commit('activeDesign', this.activeDesign)
    },
    textChange () {
      newStore.commit('activeDesign', this.activeDesign)
    },
    fontChange (to) {
      this.addFont(to.family)
      this.activeElement.content.fontFamily = to.family
      this.activeElement.content.src = to
      newStore.commit('activeDesign', this.activeDesign)
    },
    addFont (name) {
      if (!this.fontsLoaded.includes(name)) {
        fontLoader.methods.load(name)
        newStore.commit('addFontLoaded', name)
      }
    },
    fontChanged (to) {
      this.fontFamily = to.family
      if (this.activeElement && this.activeElement.type === 'text') {
        this.activeElement.content.fontFamily = to.family
        this.activeElement.content.src = to
      }
      newStore.commit('activeDesign', this.activeDesign)
    },
    retrieveFonts () {
      if (this.googleFonts === null) {
        fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyAahAS49rBp87aNk8uWY94QBSZ92TIm2jA',
          {
            method: 'GET'
          })
          .then(response => {
            // response.json()
            response.json().then(function (list) {
              newStore.commit('googleFonts', list.items)
            })
          })
      }
    }
  },
  computed: {
    activeDesign () {
      return newStore.state.activeDesign
    },
    fontList () {
      function compare (a, b) {
        if (a.category < b.category) { return -1 }
        if (a.category > b.category) { return 1 }
        return 0
      }

      return fontlist.list().sort(compare)
      // return fontlist.list()
    },
    googleFonts () {
      return newStore.state.googleFonts
    },
    fontsLoaded () {
      return newStore.state.fontList
    },
    activeElement () {
      return newStore.state.activeElement
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    }
  }
}
</script>

<style lang='scss' scoped>
.list-item {
  margin-bottom: 8px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 8px;
}
.font-ctn {
  width: 100%;
  padding: 10px;
  height: auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 760px) {

.font-ctn {
    height: 120px;
    overflow-y: scroll;
}

}
.fontEdit {
  font-size: 16px !important;
  width: 100%;
  box-sizing: border-box;
}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: calc(100%);
    height: 100%;
    margin-left: -10px;
    margin-right:-10px;
    padding:10px;
    align-items: center;
    .media-grid-item {
      max-height: 80px;
      max-width: 80px;
      min-width: 80px;
      align-items: normal;
      img {
        max-width: 80px;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
