<template>
  <div class="crop-overlay">
    <div v-if="large" class="crop-ctn">
        <cropper
          :src="activeElement.content.thumbnail"
          :auto-zoom = false
          :stencil-props="{
            aspectRatio: activeElement.width/activeElement.height,
          }"
          :default-size="defaultSize"
          @change="change"
          :minWidth = activeElement.width
          :minHeight= activeElement.height
        />
        <div class="btn-group">
          <button class="cleanLight" @click="cancel">{{ $t("system.labels.cancel")}}</button>
          <button class="cta" @click="submit">{{ $t("system.labels.crop")}}</button>
        </div>
        <!-- <div v-if="activeElement.type === 'image'" :style="{ boxSizing: 'border-box', width: activeElement.width +'px', height: activeElement.height + 'px', border: '1px solid pink', overflow: 'hidden'}">
            <img v-if="activeElement.content.thumbnail != 'undefined' && activeElement.content.thumbnail != null" v-bind:class="{ portrait: activeElement.width < activeElement.height }" :src="activeElement.content.thumbnail | filterImg" />
          </div> -->
    </div>
    <div v-else>
        <button @click="filter('smart')" class="clean">Smart Crop</button>
        <button disabled class="clean">Manual Crop (binnenkort)</button>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.compact.css'
import newStore from '@/store/newstore'

export default {
  components: {
    Cropper
  },
  data () {
    return {
      large: true,
      slice: 27,
      posts: [],
      errors: [],
      isEdit: false,
      initialScale: 1,
      usedImages: [],
      crop: {
        pX: null,
        pY: null,
        scaleX: null,
        scaleY: null
      }
    }
  },
  methods: {
    defaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    percentsRestriction ({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
      return {
        minWidth: minWidth,
        minHeight: minHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      }
    },
    change ({ coordinates, canvas, image, visibleArea }) {
      let scale = 1
      if (image.width > image.height) {
        scale = image.height / coordinates.height
      } else if (image.width < image.height) {
        if (coordinates.width < coordinates.height) {
          scale = image.height / coordinates.height
        } else {
          scale = image.width / coordinates.width
        }
      } else {
        if (coordinates.width < coordinates.height) {
          scale = image.height / coordinates.height
        } else {
          scale = image.width / coordinates.width
        }
      }

      this.crop.pX = ((100 / (image.width)) * (coordinates.left)) * (scale)
      this.crop.pY = ((100 / (image.height)) * (coordinates.top)) * (scale)
      this.crop.scaleX = scale
      this.crop.scaleY = scale
      this.crop.properties = coordinates
    },
    submit () {
      const values = this.crop
      this.activeElement.content.crop = {
        px: -values.pX,
        py: -values.pY,
        scaleX: values.scaleX,
        scaleY: values.scaleY,
        properties: values.properties
      }
      this.$emit('update:activeSub', null)
      newStore.commit('activeDesign', this.activeDesign)
      this.overlayDeactivate()
    },
    cancel () {
      this.$emit('update:activeSub', null)
      this.overlayDeactivate()
    },
    overlayDeactivate () {
      newStore.commit('overlayActive', false)
    }
  },
  computed: {
    image () {
      return newStore.state.activeElement.content.url
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    },
    activeElement () {
      return newStore.state.activeElement
    },
    activeDesign () {
      return newStore.state.activeDesign
    }
  }
}
</script>

<style lang="scss">
.crop-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
  .crop-ctn {
    width: 80%;
    height: 80%;
  }
  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 100%;
  }
}

.vue-preview__wrapper {
  min-width: 100px;
  min-height: 100px;
}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: 100%;
    height: 100%;
    margin-left: 0;
    box-sizing: border-box;
    margin-right: 0;
    padding:10px;
    align-items: center;
    .clean {
      padding: 0;
      img {
        border-radius: 10px;
      }
    }
    .media-grid-item {
      max-height: 80px;
      max-width: 80px;
      min-width: 80px;
      align-items: normal;
      img {
        max-width: 80px;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
