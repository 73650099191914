<template>
  <div>Image</div>
</template>

<script>
export default {
  props: {
    src: null
  }
}
</script>
