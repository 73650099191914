import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './userStore'
import menuStore from './menuStore'
import loaderStore from './loaders'
import toggleStore from './toggleStore'
import artBoardStore from './artBoardStore'
import productsStore from './productsStore'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    menuStore,
    userStore,
    toggleStore,
    productsStore,
    artBoardStore,
    loaderStore
  },
  plugins: [createPersistedState({
    paths: [
      'userStore.user',
      'menuStore.menu',
      'userStore.uploads',
      'userStore.designs',
      'menuStore.activeMenu',
      'productsStore.product',
      'menuStore.collections',
      'artBoardStore.artBoards',
      'productsStore.productList',
      'artBoardStore.activeElement'
    ]
  })]
})
