<template>
  <div class="flex-row-centered">
    <div v-if="user && user !== 'guest_user'" class="timestamp"><timeago :datetime="modified" :auto-update="60"></timeago> opgeslagen</div>
    <button v-if="user && user !== 'guest_user'" @click="saveDesign()"><span v-if="saved === false"><i class="fal fa-cloud-upload"></i></span><span v-else><i class="far fa-check-circle" style="color: #22C30F"></i></span></button>
    <div>
      <div class="form-overlay-background" v-if="doneMessage">
        <div class="sendForm preview-overlay order">
            <!-- <div class="imageWrap" style="display: flex; align-items: center; justify-content: center;">
              <span v-if="!previewBlob" style="position: absolute; z-index: 99;">Preview aan het laden...</span>
              <span v-if="!coordinates" style="position: absolute; z-index: 99; pointer-events: none; background: rgba(0,0,0,0.6); border-radius: 40px; padding: 20px; color: white;">{{ scrollText }}</span>
            <cropper :src="previewBlob" style="background: white !important;"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false
            }"
            :auto-zoom="false"
            :default-size="defaultSize"
            @change="onChange"
            @ready="onReady"
              ></cropper>
            </div> -->
            <div class="infoWrap">
            <h3>{{ $t("control.title") }}</h3>
            <p>{{ $t("control.body") }}</p>
              <button
                class="cta button-buy" style="margin-left:0; float: right;" v-bind:class="{ success: cartSuccess }" @click="addToCart(), $postEvent('iframeClickEvent', 'Bestellen')">
                <span v-if="!cartClicked && !cartSuccess">{{ $t("control.order") }}</span>
                <div class="sk-chase" v-if="cartClicked">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                </div>
                <div v-if="cartSuccess"><i class="far fa-check"></i></div>
              </button>
              <button class="clean" style="float: right;" @click="showMessage(false)">
                {{$t("control.cancel")}}
              </button>
            </div>
        </div>
      </div>
    </div>
    <button
      class="cta button-buy button-done" v-bind:class="{ success: cartSuccess }" @click="showMessage(true), userDone()">
      <span v-if="!cartClicked && !cartSuccess">{{ $t("cta.buy") }}</span>
      <div class="sk-chase" v-if="cartClicked">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
      <div v-if="cartSuccess"><i class="far fa-check"></i></div>
    </button>
  </div>
</template>

<script>
import previewCreator from '@/helpers/previewCreator'
import newStore from '@/store/newstore'
// import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css'
// import 'vue-advanced-cropper/dist/theme.compact.css'
// import uploadCare from 'uploadcare-vue'
import uploadcare from 'uploadcare-widget'
import { uuid } from 'vue-uuid'

export default {
  components: {
    // Cropper
  },
  data () {
    return {
      errors: [],
      session: null,
      mail: null,
      success: null,
      saved: false,
      show: false,
      imageSize: null,
      showControl: false,
      cartClicked: false,
      cartSuccess: false,
      previewBlob: null,
      coordinates: false,
      production: process.env.VUE_APP_PRODUCTION
    }
  },
  mounted () {
    // cart_response
    const self = this
    function setupListener () {
      window.addEventListener('message', (e) => {
        if (e.origin === 'https://instawallprints.com' || e.origin === 'https://instawall.de' || e.origin === 'https://instawall.nl' || e.origin === 'https://instawall.be' || e.origin === 'https://instawall.fr') {
          if (e.data.event_id === 'cart_response') {
            if (e.data.data === 'success') {
              self.cartSuccess = true
              self.cartClicked = false
              newStore.commit('activeDesign', null)
              newStore.commit('setPreview', null)
            } else {
              self.cartSuccess = false
              self.cartClicked = false
            }
          }
        }
      })
    }
    setupListener()
  },
  methods: {
    onReady () {
      this.coordinates = false
      console.log('te')
    },
    showMessage (value) {
      newStore.commit('showMessage', value)
    },
    onChange ({ coordinates }) {
      console.log(this.imageSize, coordinates)
      if (coordinates.width !== this.imageSize.width || coordinates.height !== this.imageSize.height) {
        this.coordinates = true
      } else {
        this.coordinates = false
      }
    },
    defaultSize ({ imageSize, visibleArea }) {
      this.imageSize = imageSize
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    userDone () {
      this.createPreview('preview')
    },
    showPreview () {
      this.previewBlob = this.preview.canvasBlob
    },
    setTempUser () {
      newStore.state.user = null
      newStore.dispatch('loadUser', { key: process.env.VUE_APP_TEST_USER })
    },
    createPreview (callback) {
      previewCreator.create(callback)
    },
    send (type) {
      this.errors = []

      if (!this.mail) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(this.mail)) {
        this.errors.push('Valid email required.')
      }

      if (!this.errors.length) {
        const id = uuid.v1()
        this.activeBoard.dID = id
        this.activeBoard.email = this.mail

        this.createPreview('mail')
        return true
      }
    },
    async upload () {
      function dataURLtoFile (dataurl, filename) {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      }
      // convert a base64 image to a File object
      const fileToUpload = dataURLtoFile(this.preview.canvasBlob, 'image')

      // upload the file to Uploadcare
      const upload = uploadcare.fileFrom('object', fileToUpload)
      upload.done(fileInfo => {
        this.activeBoard.thumbnail = fileInfo.cdnUrl
        if (this.preview.callback === 'addtocart') {
          // Add to cart with the preview added to it...
          window.parent.postMessage(
            {
              event_id: 'adding_to_cart',
              data: {
                pdf: this.activeBoard.previewID + '.pdf',
                thumbnail: fileInfo.cdnUrl,
                session: this.session.uid,
                type: this.session.type,
                user: this.session.usr || '',
                variant: this.activeBoard.variantID,
                editUrl: this.activeBoard.productUrl
              }
            },
            '*'
          )
        } else if (this.preview.callback === 'mail') {
          // Send an email with the preview added to it...
          newStore.dispatch('send').then((e) => {
            this.success = this.$t('magic.success')
            this.show = false
          })
        }
        // this.$store.commit('setOverlayLoader', false, { root: true })
      })
    },
    addToCart () {
      // this.$store.commit('setOverlayLoader', true, { root: true })
      const self = this
      this.cartClicked = true
      self.createPreview('addtocart')
      const id = uuid.v1()
      if (this.user !== 'guest_user') {
        this.activeBoard.dID = id
      }
      this.activeBoard.previewID = this.activeBoard.dID + '_' + this.activeBoard.sku + '_PDF_' + Date.now()
      this.activeBoard.pdfGenerated = false

      newStore.dispatch('sendToCart').then((e) => {
        this.session = e
        // self.generatePdfTemp()
      })
    },
    async saveDesign () {
      this.createPreview('save')
    },
    async saveDesignCallBack () {
      // Send an email with the preview added to it...
      this.activeBoard.previewBlob = this.preview.canvasBlob
      await newStore.dispatch('saveDesign', this.activeBoard).then(e => {
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 1000)
      })
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  watch: {
    preview: function (val, oldVal) {
      if (val !== null) {
        if (val.callback === 'save') {
          this.saveDesignCallBack()
        } else if (val.callback === 'addtocart' || val.callback === 'mail') {
          this.upload()
        } else if (val.callback === 'preview') {
          this.showPreview()
        }
      }
    }
  },
  computed: {
    user (state) {
      return newStore.state.user
    },
    doneMessage (state) {
      return newStore.state.done
    },
    preview (state) {
      return newStore.state.preview
    },
    activeBoard () {
      return newStore.state.activeDesign
    },
    scrollText () {
      const scrollTextVal = newStore.state.mobile ? 'Pinch om te zoomen' : 'Scroll om te zoomen'
      return scrollTextVal
    },
    modified () {
      if (this.activeBoard) {
        const modified = this.activeBoard.modified
        return modified
      } else {
        return null
      }
    },
    count () {
      if (this.activeBoard) {
        const search = this.activeBoard.content[0].items.filter(item => item.type === 'empty').length
        // const used = this.total - search
        return search
      } else {
        return null
      }
    },
    total () {
      if (this.activeBoard) {
        return this.activeBoard.content[0].items.length
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timestamp {
  font-size: 10px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  bottom: 40px;
  width: 100%;
  right: 20px;
  pointer-events: none;
  text-align: right;
  opacity: 0.6;

  @media only screen and (max-width: 760px){
    bottom: 10px;
    right: 20px;
  }
}

.flex-row-centered {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.button-buy {
  margin-left: 5px;
  border-radius: 99px;
  padding-left: 15px;
  padding-right: 15px;
  &.success {
    background: #03C739;
  }
}
.button-done {
  background: black !important;
}
.form-overlay {
  position: fixed;
  left: 50%;
  top:50%;
  height: auto;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 10px;
  transform: translateX(-50%) translateY(-50%);
  background: white;
  border-radius: 10px;
  z-index: 999;
  &.order {
    padding: 10px;
  }

  @media only screen and (max-width: 760px){
    max-width: 90%;
    width: 85%;
  }
  p {
    white-space: pre-wrap;
  }
}

.preview-overlay {
  position: fixed;
  left: 50%;
  top:50%;
  width: 300px;
  height: auto;
  min-width: 250px;
  padding: 10px;
  transform: translateX(-50%) translateY(-50%);
  background: white;
  border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

  .imageWrap {
    padding:10px 0;
    max-height: 50%;
    height: 50%;
    display: flex;
  }

.vue-advanced-cropper__foreground {
  background: white;
  opacity: 0;
}
.vue-advanced-cropper__background {
  background: white;
}
.vue-rectangle-stencil {
  opacity: 0;
}
  .infoWrap {
    height: 30%;
    align-self: center;
  }

  &.order {
    padding: 10px;
  }

  p {
    white-space: pre-wrap;
  }
}

.form-overlay-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  left: 0;
  top: 0;
  z-index: 400000;
}
.flex-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

}
.group-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.sk-chase {
  width: 20px;
  height: 20px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}
</style>
