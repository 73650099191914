import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    progressBar: false,
    overLayLoader: false
  },
  mutations: {
    setProgressBar (state, data) {
      Vue.set(state, 'progressBar', data)
    },
    setOverlayLoader (state, data) {
      Vue.set(state, 'overLayLoader', data)
    }
  }
}
