<template>
  <div style="position: absolute; pointer-events: none; overflow:hidden; " class="gridcontainer" v-bind:style="{ pointerEvents: 'auto'}">
    <!-- {{ overlappingId }} -->
    <vue-swappable id="container" :options="options" class="container artboard-shadow no-select" @mirror:created="mirror" @drag:stop="dragDone" @drag:start="dragStart" @swappable:swap="swapped" v-bind:style="{backgroundColor: activeDesign.content[0].background, width: w + 'px', height: h + 'px',transform:  'scale('+ scale +')',  pointerEvents: 'auto'}">
    <vue-draggable-container tag="div">
      <div class="item no-select"
        v-for="(element) in activeDesign.content[0].items"
        :key="element.id"
        :id="element.id"
        :width="element.width"
        v-bind:class="{ 'my-active-class': element.active }"
        v-on:click="clicked(element)"
        :style="{ width: element.width + 'px', height: element.height + 'px', transform: 'translate('+ element.x +'px, '+ element.y +'px)', zIndex: ( element.active === true ) ? element.z : element.z }"
        >
        <div v-if="element.type === 'image'" class="image-ctn no-select" :style="{ border: activeDesign.content[0].gridSize + 'px solid ' + activeDesign.content[0].background, boxSizing: 'border-box', justifyContent: `${element.content.crop ? 'flex-start' : 'center' }`, alignItems: `${element.content.crop ? 'flex-start' : 'center' }`, width: '100%', height: '100%'}" >
            <div class="resolution" :style="{ transform: `scale(${(1 / scale)})`}" v-bind:class="{ lowres: ((checkResolution(element.content) === 'low'))}"><i class="fas fa-exclamation-circle" color="orange"></i></div>
            <img
              v-if="element.content.thumbnail != 'undefined' && element.content.thumbnail != null"
              class="no-select"
              :src="element.content.thumbnail | filterImg"
              :width="element | imageProps('width')"
              :height="element | imageProps('height')"
              :style="{transform: `translate(${element.content.crop ? element.content.crop.px : 0 }%, ${element.content.crop ? element.content.crop.py : 0 }%) scale(${element.content.crop ? element.content.crop.scaleX : 1 })`, transformOrigin: 'left top', minHeight: `${imageProps(element, 'height')}`, minWidth: `${imageProps(element, 'width')}` }"
            />
          </div>
          <div v-if="element.type === 'shape'" v-html="element.content.shape" class="shape-ctn" style="overflow: hidden; " :style="{ border: activeDesign.content[0].gridSize + 'px solid ' + activeDesign.content[0].background, boxSizing: 'border-box' }"></div>
          <div v-if="element.type === 'empty'" class="empty-ctn" style="width: 100%; height: 100%;">
            <div style="padding: 10px; width: 100%; height: 100%; box-sizing: border-box; " :style="{background: activeDesign.content[0].background}">
              <div class="element-container" style="width: 100%; height: 100%; display: flex; justify-content: center;  outline: 2px solid grey; opacity: 0.3;" :style="{ outlineColor: invertedBackground }"><i class="fal fa-image" style="align-self: center; font-size: 250%; " :style="{ color: invertedBackground }"></i></div>
            </div>
          </div>
          <div v-if="element.type === 'text'" class="text-ctn" :style="{fontFamily: element.content.fontFamily, fontSize: element.content.fontSize + 'px', fontWeight: element.content.fontWeight, lineHeight: 'normal', color: element.content.color, textAlign: element.content.textAlign }">
            <VueContenteditableInput v-if="element.editable" tag="div" :id="element.id" style="border: none;" placeholder="Text" :contenteditable="true" v-model="element.content.txt" :disable-newline="true" :autofocus="true" />
            <div v-if="!element.editable">{{ element.content.txt !== '' ? element.content.txt : 'Text' }} </div>
          </div>
          <div v-if="element.type === 'background'" class="bg" v-bind:style="{ backgroundColor: element.color }">
              <div class="bg-item"></div>
          </div>
        </div>
    </vue-draggable-container>
  </vue-swappable>
      <div @click="deactivation()" style="width: 100%;
    height: 70%;
    z-index: -1;
    position: absolute;
    top: 0; left:0;"></div>
    </div>
</template>

<script>
// import VueDraggableResizable from 'vue-draggable-resizable-gorkys'
// import VueContenteditableInput from 'vue-contenteditable-input'

import 'vue-context/dist/css/vue-context.css'

import imageVue from '../../../../components/elements/image.vue'
import Text from '../../../../components/elements/text.vue'
import fontLoader from '@/helpers/FontLoader'
import newStore from '@/store/newstore'

function invertColor (hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  var r = parseInt(hex.slice(0, 2), 16)
  var g = parseInt(hex.slice(2, 4), 16)
  var b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b)
}
function padZero (str, len) {
  len = len || 2
  var zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export default {
  components: {
  },
  props: {
    scale: Number,
    w: Number,
    h: Number,
    parentData: Array,
    pageNumber: null,
    artboardWidth: Number,
    event: Event
  },
  data () {
    return {
      over: null,
      prev: null,
      options: {
        draggable: '.item',
        delay: {
          mouse: 0,
          drag: 10,
          touch: 80
        },
        mirror: {
          cursorOffsetX: 40,
          cursorOffsetY: 40,
          appendTo: 'body',
          constrainDimensions: true
        }
      },
      artboardWidthD: this.artboardWidth,
      parentPageNumber: null,
      example: {
        shape: imageVue,
        image: Text
      },
      grid: [],
      gridOpacity: 0,
      sync: false,
      draggingId: null,
      startPosX: null,
      startPosY: null,
      startPosZ: null,
      activeStartPosZ: 999,
      prevOffsetX: 0,
      prevOffsetY: 0,
      overlappingId: '',
      startPosition: null
    }
  },
  filters: {
    filterImg: function (value) {
      return value
    },
    imageProps: function (element, type) {
      // (element.content.object.originalImageInfo.width >= element.content.object.originalImageInfo.height) ? 'auto' : '100%'
      const cropActive = element.content.crop ? 1 : 0
      const ratioContainer = element.width / element.height
      const imageRatio = element.content.object.originalImageInfo.width / element.content.object.originalImageInfo.height

      // const containerType = ratioContainer <= 1 ? 'porSquare' : 'landscape'
      // const imageType = imageRatio <= 1 ? 'porSquare' : 'landscape'
      // console.log(ratioContainer, imageRatio, containerType, imageType, cropActive)
      let width = '100%'
      let height = 'auto'
      if (!cropActive) {
        if (ratioContainer > 1) { // Landscape
          width = 'auto'
          height = '100%'
          if (imageRatio <= 1) { // Portrait image fill landscape container
            width = '100%'
            height = 'auto'
          }
        } else if (ratioContainer < 1) { // Portraiz
          width = '100%'
          height = 'auto'
          if (imageRatio > 1 || ratioContainer < imageRatio) { // Landscape fill it up in height. otherewise squeezed box
            width = 'auto'
            height = '100%'
          }
        } else { // square
          width = '100%'
          height = 'auto'
          if (imageRatio > 1) {
            width = 'auto'
            height = '100%'
          }
        }
      } else {
        if (ratioContainer > 1) { // Landscape
          width = 'auto'
          height = '100%'
          if (imageRatio <= 1) {
            width = '100%'
            height = 'auto'
          }
        } else if (ratioContainer < 1) { // Portrait
          width = 'auto'
          height = '100%'
        } else { // square
          width = 'auto'
          height = '100%'
          if (imageRatio < 1) {
            width = '100%'
            height = 'auto'
          }
        }
      }

      if (type === 'width') {
        return width
      } else {
        return height
      }
    }
  },
  created () {
    const html5 = document.querySelector('.text-item')
    if (html5) {
      html5.setAttribute('placeholder', 'Your placeholder here')
    }
    this.activeDesign.content[0].items.forEach(item => {
      item.active = false
      if (item.type === 'text') {
        if (!this.fontsLoaded.includes(item.content.fontFamily)) {
          fontLoader.methods.load(item.content.fontFamily)
        }
        this.addFont(item.content.fontFamily)
      }
    })
  },
  methods: {
    mirror: function (e) {
      // console.log(e)
      e.mirror.style.opacity = 0.5
      e.mirror.style.zIndex = 100
      e.mirror.style.transform = 'scale(0.2)'
    },
    dragStart: function (e) {
      this.activeDesign.content[0].items.forEach(item => {
        item.deactivation = false
        item.editable = false
        item.active = false
      })
      // newStore.dispatch('activeElement', null)
      const array = this.activeDesign.content[0].items
      const element = array.filter(item => item.id === e.source.id)[0]
      element.active = true
      newStore.dispatch('activeElement', element)
      const el = {
        id: element.id,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height
      }
      this.prev = el
    },
    dragDone: function (e) {
      const array = this.activeDesign.content[0].items
      const prev = this.prev
      const over = this.over
      if (over !== null && prev !== null) {
        const previousElement = array.filter(item => item.id === prev.id)[0]
        const overElement = array.filter(item => item.id === over.id)[0]
        previousElement.x = over.x
        previousElement.y = over.y
        previousElement.height = over.height
        previousElement.width = over.width
        overElement.x = prev.x
        overElement.y = prev.y
        overElement.width = prev.width
        overElement.height = prev.height
        this.prev = null
        this.over = null
        newStore.commit('changeActiveDesign', this.activeDesign)
      }
    },
    swapped: function (e) {
      const array = this.activeDesign.content[0].items
      const prevSwappedElement = e.data.dragEvent.over
      // console.log(e)
      if (prevSwappedElement) {
        const element = array.filter(item => item.id === prevSwappedElement.id)
        this.over = element[0]
      } else {
        this.over = null
      }
    },
    clicked: function (element) {
      this.activeDesign.content[0].items.forEach(item => {
        item.deactivation = false
        item.editable = false
        item.active = false
      })
      element.active = true
      newStore.dispatch('activeElement', element)
    },
    imageProps (element, type) {
      const cropActive = element.content.crop ? 1 : 0
      const ratioContainer = element.width / element.height
      const imageRatio = element.content.object.originalImageInfo.width / element.content.object.originalImageInfo.height

      let width = '100%'
      let height = 'auto'
      if (!cropActive) {
        if (ratioContainer > 1) { // Landscape
          width = 'auto'
          height = '100%'
          if (imageRatio <= 1) { // Portrait image fill landscape container
            width = '100%'
            height = 'auto'
          }
        } else if (ratioContainer < 1) { // Portraiz
          width = '100%'
          height = 'auto'
          if (imageRatio > 1 || ratioContainer < imageRatio) { // Landscape fill it up in height. otherewise squeezed box
            width = 'auto'
            height = '100%'
          }
        } else { // square
          width = '100%'
          height = 'auto'
          if (imageRatio > 1) {
            width = 'auto'
            height = '100%'
          }
        }
      } else {
        if (ratioContainer > 1) { // Landscape
          width = 'auto'
          height = '100%'
          if (imageRatio <= 1) {
            width = '100%'
            height = 'auto'
          }
        } else if (ratioContainer < 1) { // Portrait
          width = 'auto'
          height = '100%'
        } else { // square
          width = 'auto'
          height = '100%'
          if (imageRatio < 1) {
            width = '100%'
            height = 'auto'
          }
        }
      }

      if (type === 'width') {
        return width
      } else {
        return height
      }
    },
    checkResolution (image) {
      if (image.object.originalImageInfo.width < 720 || image.object.originalImageInfo.height < 720) {
        return 'low'
      } else {
        return 'high'
      }
    },
    deactivation () {
      this.activeDesign.content[0].items.forEach(item => {
        item.deactivation = false
        item.editable = false
        item.active = false
      })
      if (this.activeElement !== null) {
        newStore.dispatch('activeElement', null)
      }
    },
    addFont (name) {
      if (!this.fontsLoaded.includes(name)) {
        fontLoader.methods.load(name)
        newStore.commit('addFontLoaded', name)
      }
    },
    onElementRemove (element) {
      this.$store.commit('removeElementToArtBoard', element)
    },
    save () {
      this.startPosition = null
      newStore.commit('changeActiveDesign', this.activeDesign)
    }
  },
  computed: {
    mobile () {
      return newStore.state.mobile
    },
    fontsLoaded () {
      return newStore.state.fontList
    },
    invertedBackground () {
      const colorToInvert = newStore.state.activeDesign.content[0].background
      const inverted = invertColor(colorToInvert, true)
      return inverted
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    activeElement () {
      return newStore.state.activeElement
    }
  }
}
</script>

<style lang="scss" scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}
.gridcontainer {
  .item {
    position: absolute;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
    &.draggable--over {
      opacity: .25;
      .element-container {
        outline-color:grey !important;
        outline-width: 10px !important;
        background: grey !important;
      }
    }
  }
.resolution {
  position: absolute;
  z-index: 2;
  left: 10%;
  top: 10%;
  display: none;
  background: white;
  border-radius: 99px;
  padding: 2px;
  flex-direction: row;
  align-items: center;
  // color: #fa9f47;
  i {
    margin-right: 0px;
  }
 &.lowres {
   display: flex;
 }
}
}
.draggable {
  border: none;
}
.dragging {
  pointer-events: none; /* ignore for document.elementFromPoint() */
}

.image-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   -ms-user-select: none;
   user-select: none;
  &:empty {
    background: url('/img/placeholder.png');
  }
}
.text-item[contenteditable=true]:empty:before {
  content: attr(placeholder);
  width: inherit;
  height: inherit;
  color: grey;
  display: block;
}

.text-item {
}

.artboard-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.artboard-shadow {
  overflow:visible;
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.image-ctn img {
}

.shape-ctn {
  width: 100%;
  height: auto;
}

.empty-ctn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.shape-ctn svg{
  width: 100%;
  height: auto;
  &.portrait {
    width: auto;
    height: 100%;
    min-height: 100%;
  }
}

.text-ctn {
  width: 100%;
  height:  100%;
  white-space:nowrap;
  .text-item {
    width: 100%;
    height:  100%;
    white-space:nowrap;
    p {
      margin: 0;
      font-variant: normal;
      white-space:nowrap;
    }
  }
}

.vdr {
  padding: 0!important;
  border: none;
  display: flex;
  box-sizing: border-box;
  /* border: inset 5px transparent; */
}

.vdr.landscape img{
  border: none;
  width: 100%;
  height: auto;
}
.vdr.portrait img{
  border: none;
  height: 100%;
  width: auto;
}
.btn-fixed {
  position: absolute;
  top: 0;
  left: 0;
}

.my-active-class:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 8px solid #0091FF;
}

// .my-active-class.text:before {
//   border: none;
// }

.draggingActiveElement{
  // width: 120px !important;
  // height: 120px !important;
  // overflow: hidden;
}

.my-handle-class {
  background-color: #0091FF;
  z-index:  999999;
  position: fixed;
}

.my-active-class .handle {
  position: fixed;
  z-index: 1000;
  border-radius: 99px;
  background: royalblue !important;
}

.Instawall.gridSmall {
  .vdr.image {
    border: 2px solid transparent;
  }
}

.Instawall.gridLarge {
  .vdr.image {
    border: 5px solid transparent;
  }
}

</style>
