const fontLoader = {
  methods: {
    load (name) {
      const css = '@import url("https://fonts.googleapis.com/css2?family=' + name + '")'
      var styleTag = document.createElement('style')
      styleTag.appendChild(document.createTextNode(css))
      document.head.appendChild(styleTag)
    }
  }
}

export default fontLoader
