import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    modalComponent: ''
  },
  getters: {
    modalComponent: state => {
      return state.modalComponent
    }
  },
  mutations: {
    toggleComponent (state, compName) {
      state.modalComponent = compName
    },
    closeComponent (state) {
      state.modalComponent = ''
    }
  }
}
