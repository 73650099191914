import Vue from 'vue'
import Vuex from 'vuex'
import Toasted from 'vue-toasted'
import apiService from '@/api/endpoints'

Vue.use(Toasted)
Vue.use(Vuex)

export default {
  state: {
    user: null,
    uploads: [],
    designs: []
  },
  getters: {
    getUser: state => {
      return state.user
    }
  },
  mutations: {
    setUser (state, data) {
      state.user = data
    },
    setUploads (state, data) {
      state.uploads = data
    },
    addUploadsToLocal (state, data) {
      state.uploads = state.uploads ? state.uploads : []
      state.uploads.push(data)
    },
    deleteUploadsToLocal (state, data) {
      const arr = state.uploads.filter(item => !data.includes(item.id))
      Vue.set(state, 'uploads', arr)
    },
    addDesigns (state, data) {
      state.designs.push(data)
    },
    setDesigns (state, data) {
      state.designs = data || []
    },
    deleteDesign (state, data) {
      const i = state.designs.indexOf(data)
      state.designs.splice(i, 1)
    },
    updateDesign (state, data) {
      state.designs = state.designs.map((i) => {
        if (i.designID === data.designID) {
          i = data
        }
        return i
      })
    },
    updateUserTokens (state, data) {
      state.user.refresh_token = data.refresh_token
      state.user.token = data.token
    }
  },
  actions: {
    uploadUserImageToServer ({ commit }, req) {
      commit('addUploadsToLocal', req)
      apiService.uploadUserImage(req).then((res) => {
      })
    },
    refreshUser ({ commit }, token) {
      apiService.RefreshToken({ refreshToken: token }).then((res) => {
        commit('updateUserTokens', res.data.data)
      })
    },
    updateUserDetails ({ commit }, req) {
      apiService.getUserDetails(req).then((res) => {
        commit('setUploads', res.data.data.UploadCareImage)
        commit('setDesigns', res.data.data.MyDesign)
      })
    },
    uploadUserDesignToServer ({ commit }, req) {
      return apiService.uploadUserDesign(req)
    },
    uploadUserDesignToCollection ({ commit }, req) {
      return apiService.uploadUserDesignToCollection(req)
    },
    uploadGuestImageToServer ({ commit }, req) {
      apiService.uploadGuestImage({ uuid: req.uuid }).then((res) => {
        commit('addUploadsToLocal', req)
      })
    },
    deleteUploadImageToServer ({ commit }, req) {
      commit('setOverlayLoader', true, { root: true })
      apiService.deleteUploadImage(req).then((res) => {
        commit('deleteUploadsToLocal', req.uploadCareID)
        commit('setOverlayLoader', false, { root: true })
      })
    },
    deleteDesignsToServer ({ commit }, req) {
      commit('setOverlayLoader', true, { root: true })
      apiService.deleteDesigns(req.reqParams).then((res) => {
        commit('deleteDesign', req.obj)
        commit('setOverlayLoader', false, { root: true })
      })
    },
    deleteGuestUploadImageToServer ({ commit }, req) {
      apiService.deleteGuestUploadImage(req).then((res) => {})
    }
  }
}
