<template>
  <div class="scrollable-column" :class="{fullscreen: Fullscreen}">
    <div class="margin-first">
          <h4>Uploads</h4>
          <div class="media-grid-content" :class="{empty: (uploads.length === 0)}">
          <div class="media-grid-item" v-for="(post) in uploads.slice(0, slice).reverse()" :key="post.id">
            <div class="resolution" v-bind:class="{ lowres: ((checkResolution(post) === 'low'))}"><i class="fas fa-exclamation-circle" color="orange"></i></div>

            <div class="postCounter" v-bind:class="{ checked: (checkUsed(post) === 1) }"><i class="far fa-check"></i></div>

            <div v-on:click="addImage(post, 'image'), $postEvent('iframeClickEvent', 'Fotos Kiezen')" style="display: flex;" v-bind:class="{ used: (checkUsed(post) === 1) }">
              <label :for="post.id">
                <gImage :src="post.cdnUrl+'-/scale_crop/180x180/smart/'"></gImage>
              </label>
            </div>
          </div>
          <div class="empty-message" v-if="uploads.length === 0">
            {{$t('system.uploadEmpty')}}
          </div>
          </div>
          </div>
  </div>
</template>

<script>
import gImage from '@/components/elements/image.vue'
import newStore from '@/store/newstore'
import elementsLoader from '@/helpers/elements'
// import uploadDialog from './upload-dialog.vue'

export default {
  components: {
    gImage
  },
  props: {
    Menu: String,
    Fullscreen: Boolean
  },
  data () {
    return {
      slice: 27,
      posts: [],
      errors: [],
      isEdit: false,
      usedImages: []
    }
  },
  methods: {
    addImage (image) {
      elementsLoader.methods.addImage(image)
    },
    checkUsed (id) {
      // const search = this.usedImages.indexOf(id)
      let search = []
      if (id) {
        search = this.checkUsedImages.filter(el => el === id.uuid)
      }
      return search.length
    },
    toggleSidebar (title) {
      newStore.commit('sidebar', true)
      newStore.commit('overrideMenu', title)
    },
    checkResolution (image) {
      if (image.originalImageInfo.width < 720 || image.originalImageInfo.height < 720) {
        return 'low'
      } else {
        return 'high'
      }
    }
  },
  computed: {
    checkUsedImages () {
      const usedImages = []
      if (newStore.state.activeDesign) {
        const keys = newStore.state.activeDesign.content[0].items
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].type === 'image' && keys[i].content.object.uuid) {
            const idArray = keys[i].content.object.uuid
            usedImages.push(idArray)
          }
        }
      }
      return usedImages
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-message {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  text-align: center;
}
.media-grid-content {
  &.empty {
    grid-template-columns: repeat(1, 90%);
    align-items: center;
  }
}
button {
  &.inline {
    top: 0 !important;
  }
}
.itemVisible {
  visibility: hidden;
}

.scrollable-column {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  .margin-first {
    margin-top: -20px;
  }
  &.fullscreen {
    height: calc(100vh - 180px);
  }
}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: calc(100% + 10px);
    height: 100%;
    margin-left: -10px;
    margin-right:-10px;
    padding:10px;
    align-items: center;
    justify-content: flex-start;
    .media-grid-item {
      max-height: 80px;
      max-width: 80px;
      min-width: 80px;
      align-items: normal;
      &:first-child {
        padding-left: 20px;
      }
      img {
        max-width: 80px;
      }
      &.listed {
        display: none;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }

.resolution {
  position: absolute;
  z-index: 9;
  left: 5px;
  top: 5px;
  display: none;
  background: white;
  border-radius: 99px;
  padding: 3px;
  flex-direction: row;
  padding-right: 3px;
  align-items: flex-start;
  font-size: 8px;
  white-space: nowrap;
  i {
  }
 &.lowres {
   display: flex;
 }
}
  }
</style>
