
export default {
  // called by Vue.use(FirstPlugin)
  install (Vue, options) {
    Vue.prototype.$postEvent = function (event, click) {
      // console.log(event, click)
      try {
        var postObject = JSON.stringify({
          event: event,
          click: click
        })
        // console.log(postObject)
        window.parent.postMessage(postObject, '*')
      } catch (e) {
        window.console && window.console.log(e)
      }
    }
    Vue.mixin({
      created () {
        // console.log('jeej')
        // try {
        //   var postObject = JSON.stringify({
        //     event: event,
        //     click: click
        //   })
        //   window.parent.postMessage(postObject, '*')
        // } catch (e) {
        //   window.console && window.console.log(e)
        // }
      }
    })
  }
}
