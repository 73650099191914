<template>
    <div id="canvas" ref="canvas" class="content-ctn">
      <div class="artboard-ctn" v-if="activeBoard && size[0]" v-bind:id="'artboard_' + activeBoard.id">
        <div v-bind:class="activeBoard.type + ' ctn shadow'" v-bind:style="{ width: size[0].width + 'px', height: size[0].height + 'px'  }" id="content" ref="content">
            <Free v-if="activeBoard.content[0].type === 'free'" v-bind:scale="size[0].scale" class="freestyle" v-bind:w="activeBoard.content[0].sizes.w" v-bind:h="activeBoard.content[0].sizes.h" style="z-index: 1;"></Free>
            <!-- <Grid v-if="activeBoard.content[0].type === 'grid'" v-bind:scale="size[0].scale" class="c" v-bind:w="activeBoard.content[0].sizes.w" v-bind:h="activeBoard.content[0].sizes.h" style="z-index: 1;"></Grid> -->
            <GridDraggable v-if="activeBoard.content[0].type === 'grid'" v-bind:scale="size[0].scale" class="c" v-bind:w="activeBoard.content[0].sizes.w" v-bind:h="activeBoard.content[0].sizes.h" style="z-index: 1;"></GridDraggable>
            <div class="overlay-clipped" @click="deactivation()"></div>
        </div>
      </div>

    </div>
</template>

<script>
import Free from './Components/free.vue'
// import Grid from './Components/grid.vue'
import GridDraggable from './Components/grid-draggable.vue'
import newStore from '@/store/newstore'
export default {
  components: {
    Free, GridDraggable
  },
  data () {
    return {
      size: []
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.sizeAdjust()
    })
    const self = this
    const transition = document.querySelector('.container')
    transition.addEventListener('transitionend', () => {
      // window.dispatchEvent(new Event('resize'))
      self.sizeAdjust()
    })
  },
  methods: {
    deactivation () {
      this.activeBoard.content[0].items.forEach(item => {
        item.deactivation = false
        item.editable = false
        item.active = false
      })
      if (this.activeElement !== null) {
        newStore.dispatch('activeElement', null)
      }
    },
    chooseProduct () {
      this.$store.commit('toggleComponent', 'productsOverlay')
    },
    sizeAdjust () {
      // Reset the sizes before altering and create new ones.
      if (!this.activeBoard) {
        return false
      }
      this.size = []
      const artboard = this.activeBoard
      let padding
      const containerHeight = this.$refs.canvas.clientHeight
      const containerWidth = this.$refs.canvas.clientWidth
      let mobile = false

      // Setup breakpoints for the padding around the artboard
      if (this.$screen.width > 1280) {
        mobile = false
        padding = 250
      } else if (this.$screen.width > 1008) {
        mobile = false
        padding = 125
      } else if (this.$screen.width > 641) {
        mobile = false
        padding = 75
      } else {
        mobile = true
        padding = 65
      }

      // Business rules in order to get the correct Artboard sizes
      const itemSizes = {}
      let type = this.activeBoard.type
      const sizeW = artboard.content[0].sizes.w
      const sizeH = artboard.content[0].sizes.h

      if (sizeW === sizeH) {
        type = 'square'
      } else if (sizeW < sizeH) {
        type = 'portrait'
      } else {
        type = 'landscape'
      }
      let artScale
      if (type === 'landscape') {
        let checkMobile = false
        if (this.$screen.width > this.$screen.height) {
          padding = padding * 1.5
        } else {
          checkMobile = true
          padding = padding * 1.25
        }
        const scale = sizeH / sizeW
        let width = Math.round((containerWidth - padding) / 10) * 10

        if (checkMobile) {
          width = width - 4
        }

        artScale = width / sizeW
        artScale = artScale.toFixed(2)
        width = sizeW * artScale

        itemSizes.width = width
        itemSizes.height = width * scale
        itemSizes.scale = Number(artScale)
      } else if (type === 'portrait') {
        if (mobile) {
          padding = padding * 4
        }
        const scale = sizeW / sizeH
        const height = containerHeight - padding
        artScale = height / sizeH
        artScale = artScale.toFixed(2)

        itemSizes.height = height
        itemSizes.width = height * scale
        itemSizes.scale = Number(artScale)
      } else if (type === 'square') {
        if (this.$screen.width > this.$screen.height) {
          const scale = sizeW / sizeH
          let height = Math.round((containerHeight - padding) / 10) * 10
          artScale = height / sizeH
          artScale = artScale.toFixed(1)
          height = sizeH * artScale
          itemSizes.height = height
          itemSizes.width = height * scale
          itemSizes.scale = Number(artScale)
        } else {
          const scale = sizeH / sizeW
          const width = containerWidth - (padding * 2)
          itemSizes.width = width
          itemSizes.height = width * scale
          itemSizes.scale = width / sizeW
        }
      }
      this.size.push(itemSizes)
    },
    removeArtBoard () {
      this.$store.commit('activeArtBoard', null)
    }
  },
  created () {
    const self = this
    let width = window.innerWidth
    let height = window.innerHeight
    window.addEventListener('resize', debounce(() => {
      if (Math.abs(window.innerWidth - width) > 60 || Math.abs(window.innerHeight - height) > 60) {
        self.sizeAdjust()
        height = window.innerHeight
        width = window.innerWidth
      }
    }))
  },
  destroyed () {
    window.removeEventListener('resize', this.sizeAdjust)
    const transition = document.querySelector('.container')
    transition.removeEventListener('transitionend', '')
  },
  computed: {
    activeBoard () {
      return newStore.state.activeDesign
    },
    activeElement () {
      return newStore.state.activeElement
    }
  },
  watch: {
    activeBoard () {
      this.sizeAdjust()
    }
  }
}
function debounce (func) {
  let timer
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, 100, event)
  }
}
</script>

<style lang="scss">
.progress-bottom {
  z-index:1;
}
.output {
  position: fixed;
  right: 800px;
  top:0;
  font-size: 10px;
  width: 500px;
  height: 150px;
  display: none;
  z-index: 100000;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.overlay-clipped {
  // clip-path: polygon(10% 10%,90% 10%,90% 90%,10% 90%);
  left: 0;
  right: 0;
  top: -200px;
  bottom: -200px;
  position: absolute;
  z-index: 0;
}
.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */
.shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.artboard-ctn {
  display: flex;
  flex-direction: column;
  position: relative;
    width: 100%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
}
.image-artboard {
  position: relative;
}
.content-ctn {
  height: auto;
  display: flex;
  flex-direction: column;

   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}

#app svg {
    width: 100%;
    height: auto;
}
.ctn {
  transition: all 600ms, height 500ms;

    justify-content: center;
    align-items: center;
    display: flex;
}
.container {
  transition: all 600ms, height 500ms;
}
.fullheight {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    margin: 0;
    height: auto;
    padding: 0;

  }

  /* Portrait */

.my-handle-class {
    position: absolute;
    background-color: #0091FF;
    border: 4px solid white;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    box-model: border-box;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.my-handle-class-tl {
  top: -14px;
  left: -14px;
  cursor: nw-resize;
}

.my-handle-class-tm {
  top: -14px;
  left: 50%;
  margin-left: -7px;
  cursor: n-resize;
}

.my-handle-class-tr {
  top: -14px;
  right: -14px;
  cursor: ne-resize;
}

.my-handle-class-ml {
  top: 50%;
  margin-top: -7px;
  left: -14px;
  cursor: w-resize;
}

.my-handle-class-mr {
  top: 50%;
  margin-top: -7px;
  right: -14px;
  cursor: e-resize;
}

.my-handle-class-bl {
  bottom: -14px;
  left: -14px;
  cursor: sw-resize;
}

.my-handle-class-bm {
  bottom: -14px;
  left: 50%;
  margin-left: -37px;
  cursor: s-resize;
}

.my-handle-class-br {
  bottom: -14px;
  right: -14px;
  cursor: se-resize;
}

.my-handle-class-tl:hover,
.my-handle-class-tr:hover,
.my-handle-class-bl:hover,
.my-handle-class-br:hover {
    background-color: royalblue;
    transform: scale(1.4);
}
@media only screen and (max-width: 760px) {
  .output {
    display: none;
  }
  .fullheight {
    height: calc(100% - 100px);
  }
  .artboard-ctn {
    height: calc(100%);

  }
}

@media only screen and (max-width: 760px){
.artboard-ctn {
    height: calc(100%);
    margin-top:0;
    background: #F3F5F6;
  }
}

.splitpanes {
  background-color: #f2f2f2;

  &__pane {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow:initial;
  }

  &__splitter {background-color: #ccc; position: relative; pointer-events: initial; }

  &--vertical > &__splitter {min-width:1px !important;}
  &--horizontal > &__splitter {min-height:1px !important;}

  &__splitter:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.4s;
  }
  &--vertical > &__splitter:before {left: -30px;right: -30px;}
  &--horizontal > &__splitter:before {top: -30px;bottom: -30px;}
}
</style>
