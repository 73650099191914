<template>
    <section class="is-fullheight no-padding gallery-container">
        <div class="gallery-menu" v-on:update:overrideMenu="overrideMenu = $event">
          <div v-if="!mobile" class="menu-item"></div>
          <div class="menu-item" v-for="item in menuList" v-bind:class="{active:activeMenu.title === item.title}" :key="item.title">
            <span v-on:click="changeGallery(item)" class="item-content">
              <div v-if="userType && userType === 'admin' && item.menuID" class="delete" v-on:click="deleteMenus(item)">
                <i class="fal fa-times"></i>
              </div>
              <i :class="item.icon" :style="{ color: (item.type === 'promotion' ? 'blue' : '' )}"/>
              <small class="gallery-label">{{ item.title }}
            </small>
            </span>
          </div>
          <button v-if="settings" @click="openMenu" class="menu-item" ><span class="item-content"><i class="fal fa-cog"></i></span>
          </button>
        </div>
        <Gallery class="gallery" v-bind:activeMenu="activeMenu" display="sidebar" />
    </section>
</template>

<script>
import Gallery from './Gallery.vue'
import apiService from '@/api/endpoints'
// import store, { Sidebar } from '@/store/interface/'
import Vue from 'vue'
import newStore from '@/store/newstore'

export default {
  components: {
    Gallery
  },
  data () {
    return {
      menuId: null,
      galleryType: 'designs',
      expanded: false,
      overrideMenu: null,
      atRight: false,
      size: 'is-medium',
      type: 'is-toggle',
      animated: false,
      wallhalla: false
    }
  },
  watch: {
    overrideMenu (state, old) {
      console.log(state, old)
      // newStore.commit('activeDesign', this.activeDesign)
      // this.$store.commit('activeArtBoard', this.activeDesign)
    }
  },
  methods: {
    changeGallery (menu) {
      newStore.commit('setActiveMenu', menu)
      newStore.commit('sidebar', true)
      if (menu.type !== 'user') {
        newStore.dispatch('loadCollection', menu)
      }
      // this.toggleSidebar()
    },
    changeGalleryFrom (menu) {
      console.log(menu)
    },
    toggleSidebar: function () {
      // Sidebar.activate()
    },
    deleteMenus (menu) {
      this.$confirm(
        {
          message: `Are you sure you want to delete ${menu.title}?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.commit('setOverlayLoader', true, { root: true })
              apiService.deleteMenu(menu.menuID).then((res) => {
                if (res.data.code === '200') {
                  this.$store.dispatch('getMenuList')
                  Vue.toasted.success(`${menu.title} Deleted Successfully`, {
                    position: 'top-center',
                    duration: 3000
                  })
                } else {
                  this.$store.commit('setOverlayLoader', false, { root: true })
                  Vue.toasted.error(res.data.data[0], {
                    position: 'top-center',
                    duration: 3000
                  })
                }
              })
            }
          }
        }
      )
    },
    openMenu () {
      newStore.commit('showPanel', true)
    }
  },
  computed: {
    mobile () {
      return newStore.state.mobile
    },
    menuList () {
      // console.log(this.userType)
      // console.log(newStore.state.menu)
      return newStore.state.menu
    },
    activeMenu () {
      return newStore.state.activeMenu
    },
    settings () {
      return newStore.state.settings
    },
    user () {
      return newStore.state.user
    },
    userType () {
      return newStore.state.userType
    },
    sidebarState () {
      return newStore.state.sidebar
    }
  }
}

</script>

<style lang="scss" scoped>

.gallery-container {
  width: 100%;
  display: flex;
  overflow-y: auto;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
}

.gallery-menu {
  overflow-y: auto;
  align-self: stretch;
  position: sticky;
  top: 0px;
  padding: 5px;
  padding-top: 0;
  padding: 10px;

  .menu-item {
    position: relative;
    height: 50px;
    width: auto;
    min-width: 50px;
    margin-bottom: 8px;
    background: #F3F5F6;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index:0;

    .delete {
      z-index:200;
      position: absolute;
      right: -8px;
      top: -8px;
      .unicon {
        border-radius: 50%;
        background-color: #A1A7AF;
        width: 20px;
        height: 20px;
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      i {
        font-size: 22px;
        color: #797A7A;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &.user {
      background: pink;
      color: royalblue;
    }

    .gallery-label {
      padding-bottom: 5px;
      align-self: center;
    }

    .unicon {
      margin-top: auto;
      margin-bottom: auto;
      width: 24px;
      height: 24px;
    }

    &.active {
      background-color: #F3F5F6;
      i {
        font-size: 22px;
        color: rgb(255, 0, 221) !important;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    span {
      font-size: 0.8em;
      text-align: center;
    }
  }
}

.gallery {
  padding: 10px;
  width: 100%;
  flex: 1;
}

.gallery-menu .menu-item:hover {
  filter: brightness(1.1);
}

.gallery-menu .menu-item:active {
  filter: brightness(0.9);
}

.gallery-label {
  font-size: 0.6em;
}

.no-padding ul {
  padding: 0 !important;
  margin-left: 10px;
}

@media only screen and (max-width: 760px) {
  .gallery {
    padding: 10px;
    padding-top:0;
    box-sizing: border-box;
  }
  .gallery-container {
    width: 100vw;
    flex-direction: column;
    border-right: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
    background: white;
    margin: 0;
    border-radius: 16px;
  }
  .gallery-menu {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 3;
     overflow-y:hidden;
     overflow-x:scroll;
     background: white;
    margin-left:0;
    min-height: 70px;
    align-items: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .menu-item {
      min-width: 60px;
    }
  }
}
</style>
