<template>
  <div>
        <h3>{{ getActiveMenu.title }}</h3>
        <div class="media-grid-content">
          <div class="media-grid-item" v-on:click="addElements(2, 'vertical')">
            2 grid
          </div>
          <div class="media-grid-item" v-on:click="addElements(4, 'vertical')">
            4 vertical grid
          </div>
          <div class="media-grid-item" v-on:click="addElements(4, 'vertical', 5)">
            4 vertical grid
          </div>
          <div class="media-grid-item" v-on:click="add({}, 'shape')">
            add Shape
          </div>
          <div class="media-grid-item" v-on:click="addDesign">
            add Design
          </div>
          <div class="media-grid-item" v-on:click="add(post, 'image')">
            add image
          </div>
          <div class="media-grid-item" v-on:click="addText()">
            add Text object
          </div>
        </div>
  </div>
</template>

<script>

import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

export default {
  methods: {
    addText () {
      const Txt = {
        id: Date.now() + '_txt',
        name: 'Text',
        type: 'text',
        width: 'auto',
        height: 'auto',
        x: 0.00,
        y: 0.00,
        z: 2,
        ratio: true,
        parent: false,
        resizable: false,
        editable: true,
        draggable: true,
        active: false,
        deactivation: false,
        obj: {
          fontFamily: 'Roboto',
          fontSize: 100,
          fontWeight: 'normal',
          txt: 'Start typing..',
          color: '#000000',
          textAlign: 'left',
          src: {
            family: 'Roboto',
            variants: [
              'regular'
            ],
            subsets: [
              'latin',
              'latin-ext'
            ],
            version: 'v27',
            lastModified: '2020-09-02',
            files: {
              regular: 'http://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf'
            },
            category: 'display',
            kind: 'webfonts#webfont'
          }
        }
      }
      this.$store.commit('addElementToArtBoard', Txt)
    },
    addDesign () {
      this.$store.commit('activeArtBoard', {
        title: 'New Design',
        productID: '',
        tags: 'life, happiness, blue',
        category: 'reference',
        id: 'RHFK201235',
        reference_id: '123234324544',
        type: 'Design',
        thumbnail: 'url',
        created: 'date_time',
        modified: 'date_time',
        selected: 1002,
        finished: 254,
        order: 15,
        content: [{
          sizes: { w: 1080, h: 1080, pw: 4320, ph: 4320 },
          grid: null,
          type: 'free',
          background: 'white',
          locked: true,
          percentage: 0,
          count: 3,
          items: []
        }]
      })
    },
    add (obj, type) {
      var url, thumbnail, shape
      if (type === 'shape' || type === 'text' || obj === undefined) {
        obj = {}
        obj.paths = {
          path: null
        }
        url = null
        thumbnail = null
        const image = {
          id: Date.now() + '_img',
          name: 'Image',
          type: type,
          shape: shape,
          width: 280,
          height: 280,
          x: 0.00,
          y: 0.00,
          z: 2,
          url: url,
          thumbnail: thumbnail,
          ratio: false,
          resizable: true,
          draggable: true,
          parent: true,
          active: false,
          deactivation: false,
          object: obj
        }
        if (this.getActiveElement && this.getActiveElement.type === 'image') {
          this.$store.commit('changeElementContent', JSON.parse(JSON.stringify(image)))
        } else {
          this.$store.commit('addElementToArtBoard', image)
        }
      } else {
        thumbnail = obj.cdnUrl + '-/scale_crop/270x270/smart/'
        url = obj.cdnUrl + '-/scale_crop/1920x1920/smart/'
        if (obj.mimeType === 'image/svg+xml') {
          fetch(obj.thumbnail).then(res => res.text()).then(data => {
            // const parser = new DOMParser()
            // const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg')
            // const s = new XMLSerializer()
            // const str = s.serializeToString(svg)
            type = 'shape'
            shape = data
            url = null
            thumbnail = null
            const image = {
              id: Date.now() + '_img',
              name: 'Image',
              type: type,
              shape: shape,
              width: obj.originalImageInfo.width,
              height: obj.originalImageInfo.height,
              x: 0.00,
              y: 0.00,
              z: 2,
              url: url,
              thumbnail: thumbnail,
              ratio: true,
              resizable: true,
              draggable: true,
              parent: false,
              active: false,
              deactivation: false,
              object: obj
            }
            this.$store.commit('addElementToArtBoard', image)
          })
        } else {
          const image = {
            id: Date.now() + '_img',
            name: 'Image',
            type: type,
            shape: shape,
            width: 280,
            height: 280,
            x: 0.00,
            y: 0.00,
            z: 2,
            url: url,
            thumbnail: thumbnail,
            ratio: false,
            resizable: true,
            draggable: true,
            parent: true,
            active: false,
            deactivation: false,
            object: obj
          }
          if (this.getActiveElement && this.getActiveElement.type === 'image') {
            this.$store.commit('changeElementContent', JSON.parse(JSON.stringify(image)))
          } else {
            this.$store.commit('addElementToArtBoard', image)
          }
        }
      }
    },
    addImage (post) {
      this.$root.$emit('addImage', post)
    },
    addElements (count, type) {
      const width = this.getArtBoardActive.content[0].sizes.w
      const height = this.getArtBoardActive.content[0].sizes.h
      for (let i = 0; i < count; i++) {
        // const index = i + 1
        const x = (width / count) * i
        // if (index === count) {
        //   x = width / count
        // }
        const image = {
          id: Date.now() + i + '_img',
          name: 'Image',
          type: 'image',
          width: width / count,
          height: height,
          x: x,
          y: 0.00,
          z: 2,
          url: null,
          parent: true,
          thumbnail: null,
          resizable: true,
          draggable: true,
          active: true,
          deactivation: false,
          object: []
        }
        this.$store.commit('addElementToArtBoard', image)
      }
    }
  },
  computed: {
    getActiveMenu () {
      return this.activeMenu ? this.activeMenu : this.$store.state.menuStore.activeMenu
    }
  }
}
</script>
