<template>
  <div v-lazy-container="{ selector: 'img', error: 'img/giphy.gif', loading: 'img/giphy.gif' }">
    <img :data-src="src ? src : 'img/giphy.gif'">
  </div>
</template>

<script>
export default {
  props: {
    src: null
  }
}
</script>
