import previewCreator from '@/helpers/previewCreator'
import newStore from '@/store/newstore'
import { uuid } from 'vue-uuid'
import elementLoader from './elements.js'

const ArtboardCreator = {
  init () {
    var self = this

    function setupListener () {
      const allowedOrigins = [
        'https://instawallprints.com',
        'https://instawallprints.se',
        'https://instawall.de',
        'https://instawall.nl',
        'https://instawall.be',
        'https://instawall.fr',
        'https://instawall.es',
        'https://instawall.lu',
        'https://instawall.dk',
        'https://instawall.ch'
      ]

      window.addEventListener('message', (e) => {
        if (allowedOrigins.includes(e.origin)) {
          if (e.data.event_id === 'Customise Button clicked') {
            const data = e.data.data
            if (newStore.state.activeDesign) {
              self.methods.updateDesign(data.title, data.productId, data.variantId, data.sku, data.type, data.size, data.artboards, data.productUrl)
            } else {
              self.methods.createDesign(data.title, data.productId, data.variantId, data.sku, data.type, data.size, data.artboards, data.productUrl)
            }
          }
          if (e.data.event_id === 'start_session') {
            const data = e.data.data
            newStore.dispatch('loadUser', data.key)
            // console.log(data.key)
          }
          if (e.data.event_id === 'GuestSession Open' || e.data.event_id === 'UserSession Open') {
            const data = e.data.data
            self.methods.loadDesign(data.id, data.type, data.sender)
          }
          if (e.data.event_id === 'continue_session') {
            const data = e.data.data
            self.methods.loadDesign(data.id, data.type, data.sender)
          }
        }
      })
    }

    setupListener()
    // this.methods.loadDesign('445dc280-83a2-11ee-b471-9f39334f1e50', 'guest_user', 'mail')
    // this.methods.loadDesign('b6cb8d70-0743-11ed-84fe-a57087d9e3eb', 'guest_user', 'mail') // Landscape
    // this.methods.loadDesign('cf71a5e0-0760-11ed-8c73-3bdf64436fca', 'guest_user', 'mail') // Tall
    // this.methods.loadDesign('89361720-feb2-11ec-bf52-21b8b6759698', 'guest_user', 'mail') // Wide
    // this.methods.loadDesign('fe67a720-d036-11ec-8648-994a9af0b980', 'guest_user', 'mail') // Squares
    // this.methods.loadDesign('f22e4c90-cc60-11ec-a810-f7058e23f28b', 'guest_user', 'mail') // Portrait

    let interval
    if (newStore.state.activeDesign) {
      previewCreator.create('session_preview')
      interval = setInterval(previewLoaded, 250)
    }
    window.parent.postMessage(
      {
        event_id: 'running',
        preview: ''
      },
      '*'
    )

    function previewLoaded () {
      if (newStore.state.preview !== null) {
        window.parent.postMessage(
          {
            event_id: 'running',
            title: newStore.state.activeDesign.title,
            preview: newStore.state.preview.canvasBlob ? newStore.state.preview.canvasBlob : ''
          },
          '*'
        )
        clearInterval(interval)
      }
    }
  },
  changeBorder (size) {
    const activeDesign = newStore.state.activeDesign
    const dimensions = newStore.state.activeDesign.content[0].sizes
    const items = newStore.state.activeDesign.content[0].items
    let currentBorder
    if (newStore.state.activeDesign.content[0].borderSize) {
      currentBorder = newStore.state.activeDesign.content[0].borderSize
    } else {
      currentBorder = 0
    }
    const newBorder = size

    newStore.state.activeDesign.content[0].items = []

    // Calculate present width / height of the canvas render..
    const cWidth = dimensions.w - (currentBorder * 2)
    const cHeight = dimensions.h - (currentBorder * 2)

    const nWidth = dimensions.w - (newBorder * 2)
    const nHeight = dimensions.h - (newBorder * 2)

    for (let i = 0; i < items.length; i++) {
      const position = items[i]
      const pWidth = position.width / cWidth
      const pHeight = position.height / cHeight
      const pX = ((position.x - currentBorder) / cWidth)
      const pY = ((position.y - currentBorder) / cHeight)
      const newWidth = nWidth * pWidth
      const newHeight = nHeight * pHeight
      const newX = (nWidth * pX) + newBorder
      const newY = (nHeight * pY) + newBorder
      items[i].width = newWidth
      items[i].height = newHeight
      items[i].x = newX
      items[i].y = newY
    }
    newStore.state.activeDesign.content[0].borderSize = newBorder
    newStore.state.activeDesign.content[0].items = items

    newStore.commit('changeActiveDesign', activeDesign)
  },
  changeMode (id, type, vCols, vRows, big, skipList) {
    const activeDesign = newStore.state.activeDesign
    const dimensions = newStore.state.activeDesign.content[0].sizes
    const items = newStore.state.activeDesign.content[0].items

    newStore.state.activeDesign.content[0].items = []

    const marginDesign = newStore.state.activeDesign.content[0].borderSize

    const aWidth = dimensions.w - (marginDesign * 2)
    const aHeight = dimensions.h - (marginDesign * 2)
    const cols = vCols // this.activeBoard.content[0].grid
    const dimension = aWidth / aHeight
    const rows = (vRows !== undefined) ? vRows : Math.round(cols / dimension)
    const gWidth = aWidth / cols
    const gHeight = aHeight / rows

    const skip = big || []
    skipList = skipList || []

    activeDesign.content[0].type = type
    activeDesign.content[0].gridId = id
    let counter = 0
    switch (type) {
      case 'free':
        if (items.length > 0) {
          const itemsArray = items.filter(item => item.type !== 'empty')
          newStore.state.activeDesign.content[0].items = itemsArray
        }
        break
      case 'grid':
        for (let r = 0; r < rows; r++) {
          for (let c = 0; c < cols; c++) {
            const skipItem = skipList.includes(counter)
            if (skipItem) {
            } else {
              const hitIt = skip.filter(hit => hit.col === c && hit.row === r)
              if (hitIt.length > 0) {
                const details = {
                  x: (gWidth * c) + marginDesign,
                  y: (gHeight * r) + marginDesign,
                  width: gWidth * hitIt[0].scaleX,
                  height: gHeight * hitIt[0].scaleY
                }
                elementLoader.methods.addEmpty(details)
              } else {
                const details = {
                  x: (gWidth * c) + marginDesign,
                  y: (gHeight * r) + marginDesign,
                  width: gWidth,
                  height: gHeight
                }
                elementLoader.methods.addEmpty(details)
              }
            }
            counter++
          }
        }

        for (let i = 0; i < items.length; i++) {
          if (items[i].type === 'image') {
            const emptyArray = newStore.state.activeDesign.content[0].items.filter(empty => empty.type === 'empty')
            if (emptyArray.length > 0) {
              emptyArray[0].content = items[i].content
              emptyArray[0].type = items[i].type
            }
          }
        }
        break
      case '1':
        // console.log(items, val)
        break
      default:
        console.log('nothing here..')
    }
    newStore.commit('changeActiveDesign', activeDesign)
  },
  methods: {
    loadDesign (id, type, sender) {
      const payload = {
        id: id,
        type: type,
        sender: sender
      }
      newStore.commit('loadedDesign', true)
      newStore.dispatch('loadDesign', payload)
    },
    updateDimensions () {
      const activeDesign = newStore.state.activeDesign
      console.log(activeDesign.content[0].sizes)
      // let items = null
      // items = this.changeDimension(oldDimension, size, oldItems)
      // activeDesign.content[i].items = items
      // newStore.commit('activeDesign', activeDesign)
    },
    updateDesign (title, productId, variantId, sku, designType, size, artboards, productUrl) {
      const activeDesign = newStore.state.activeDesign
      activeDesign.title = title
      activeDesign.productID = productId
      activeDesign.variantID = variantId
      activeDesign.productUrl = productUrl
      activeDesign.sku = sku

      const printMirror = size.print_mirror || false
      const bleedLock = size.bleed_lock || false

      newStore.dispatch('activeElement', null)
      let items = null
      for (let i = 0; i < activeDesign.content.length; i++) {
        // if (activeDesign.content[i].items.length) {
        // }
        const oldDimension = newStore.state.activeDesign.content[i].sizes
        const oldItems = newStore.state.activeDesign.content[i].items
        activeDesign.content[i].sizes = {
          w: size.w,
          h: size.h,
          pw: size.pw,
          ph: size.ph,
          bx: size.bx,
          by: size.by
        }

        activeDesign.content[i].prinMirror = printMirror
        activeDesign.content[i].bleedLock = bleedLock

        if (size.w !== oldDimension.w || size.h !== oldDimension.h) {
          if (oldItems.length > 0) {
            items = this.changeDimension(oldDimension, size, oldItems)
          }
        }
        if (items) {
          activeDesign.content[i].items = items
        } else {
          for (let a = 0; a < activeDesign.content[i].items.length; a++) {
            activeDesign.content[i].items[a].active = false
          }
        }
      }
      newStore.commit('activeDesign', activeDesign)
    },
    changeDimension (oldDimension, dimension, items, padding) {
      // const scaleX = oldDimension.w / dimension.w
      // console.log(scaleX)
      // TODO Later, scale FontSize accordingly to scale
      let artboardPadding = 0
      if (padding) {
        artboardPadding = padding
        console.log(artboardPadding)
        // dimension.w = dimension.w - artboardPadding
        // dimension.h = dimension.h - artboardPadding
      }

      for (let i = 0; i < items.length; i++) {
        const position = items[i]
        const pWidth = position.width / oldDimension.w
        const pHeight = position.height / oldDimension.h
        const pX = position.x / oldDimension.w
        const pY = position.y / oldDimension.h
        const newWidth = dimension.w * pWidth
        const newHeight = dimension.h * pHeight
        const newX = dimension.w * pX
        const newY = dimension.h * pY
        items[i].parent = false
        items[i].width = newWidth
        items[i].height = newHeight
        items[i].x = newX
        items[i].y = newY
        items[i].active = false
      }
      return items
      // TODO Dispatch design
      // newStore.dispatch('activeDesign', array)
    },
    changeArtboardPadding (oldDimension, dimension, items, padding) {
      const newItems = this.changeDimension(oldDimension, dimension, items, padding)
      newStore.state.activeDesign.content[0].items = newItems
    },
    createDesign (title, productId, variantId, sku, designType, size, artboards, productUrl) {
      const printMirror = size.print_mirror || false
      const bleedLock = size.bleed_lock || false

      const content = []
      for (let i = 0; i < artboards; i++) {
        content[i] = {
          sizes: {
            w: size.w,
            h: size.h,
            pw: size.pw,
            ph: size.ph,
            bx: size.bx,
            by: size.by
          },
          prinMirror: printMirror,
          bleedLock: bleedLock,
          grid: 0,
          gridId: 9999,
          gridSize: 3,
          borderSize: 10,
          type: designType,
          background: '#FFFFFF',
          locked: true,
          percentage: 0,
          count: 3,
          items: []
        }
      }
      newStore.dispatch('activeElement', null)
      const id = uuid.v1()
      newStore.dispatch('selectDesign', {
        title: title,
        productID: productId,
        productUrl: productUrl,
        sku: sku,
        dID: id,
        variantID: variantId,
        type: designType,
        thumbnail: 'url',
        created: Date.now(),
        modified: Date.now(),
        content: content
      })
      newStore.commit('newDesign', true)
    }
  }
}

export default ArtboardCreator
