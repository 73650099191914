import Vue from 'vue'
import Vuex from 'vuex'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import createPersistedState from 'vuex-persistedstate'
// import previewCreator from '@/helpers/previewCreator'
// import mobile from 'is-mobile'
import { db } from '@/api/db'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

Vue.use(Vuex)

const dataState = createPersistedState({
  key: 'appData',
  paths: ['user', 'userType', 'activeDesign', 'activeDesignHistory', 'images', 'menu']
})

const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches
export default new Vuex.Store({
  state: {
    user: 'guest_user',
    userType: null,
    sidebar: !isMobile,
    todos: [],
    activeDesign: null,
    activeDesignHistory: [],
    done: false,
    preview: null,
    activeGrid: null,
    overlay: false,
    activeElement: null,
    activeBorder: null,
    designs: [],
    newDesign: false,
    autoFill: false,
    loadedDesign: false,
    images: [],
    menu: null,
    staticMenu: [
      {
        icon: 'fal fa-cloud-upload',
        status: 'active',
        thumbnail: '',
        title: 'My Uploads',
        type: 'user'
      },
      {
        icon: 'fal fa-cloud-upload',
        status: 'active',
        thumbnail: '',
        title: 'My Designs',
        type: 'user'
      }
    ],
    desktopMenu: [
      {
        icon: 'fal fa-border-all',
        status: 'active',
        thumbnail: '',
        title: 'Layout',
        type: 'user'
      }
    ],
    activeMenu: {
      icon: 'fal fa-cloud-upload',
      status: 'active',
      thumbnail: '',
      title: 'My Uploads',
      type: 'user'
    },
    activeMenuDesktop: {
      icon: 'fal fa-border-all',
      status: 'active',
      thumbnail: '',
      title: 'Layout',
      type: 'user'
    },
    progressBar: false,
    overLayLoader: false,
    fontList: ['Roboto'],
    googleFonts: null,
    mobile: isMobile,
    collections: [],
    settings: false,
    panel: false,
    save: {
      status: null,
      date: null
    }
  },
  plugins: [dataState],

  mutations: {
    showMessage (state, payload) {
      state.done = payload
    },
    overlayActive (state, payload) {
      state.overlay = payload
    },
    activeDesign (state, payload) {
      state.activeDesign = payload
    },
    newDesign (state, payload) {
      state.newDesign = payload
    },
    changeActiveDesign (state, payload) {
      // const load = JSON.stringify(payload)
      // state.activeDesignHistory.push(JSON.parse(load))
      state.activeDesign = payload
    },
    activeDesignHistory (state, payload) {
      state.activeDesignHistory = payload
    },
    undoDesign (state, payload) {
      const pop = state.activeDesignHistory.pop()
      if (pop) {
        state.activeDesign = pop
      }
    },
    loadedDesign (state, payload) {
      state.loadedDesign = payload
    },
    redoDesign (state, payload) {

    },
    checkDesign (state) {
      if (state.activeDesign === null && state.designs) {
        const Designs = state.designs
        console.log(Designs)
        console.log(Array.from(Designs))
        Designs.forEach(design => {
          console.log(design)
          // state.activeDesign = design
        })
      }
    },
    setPreview (state, payload) {
      state.preview = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    showSettings (state, payload) {
      state.settings = payload
    },
    showPanel (state, payload) {
      state.panel = payload
    },
    sidebar (state, payload) {
      state.sidebar = payload
    },
    overrideMenu (state, payload) {
      const menu = state.menu.filter(item => item.title === payload)
      if (menu.length > 0) {
        state.activeMenu = menu[0]
        this.dispatch('loadCollection', menu[0])
      }
      // console.log(menu)
    },
    setMenu (state, payload) {
      state.menu = state.staticMenu
      if (!state.mobile) {
        state.menu = state.menu.concat(state.desktopMenu)
      }
      state.menu = state.menu.concat(payload)
      // state.menu = payload
    },
    setCollection (state, payload) {
      state.collections.push(payload)
    },
    setActiveMenu (state, payload) {
      state.activeMenu = payload
    },
    setImages (state, payload) {
      state.images.push(payload)
    },
    resetImages (state, payload) {
      state.images = payload
    },
    addFontLoaded (state, name) {
      state.fontList.push(name)
    },
    googleFonts (state, data) {
      state.googleFonts = data
    },
    setProgressBar (state, data) {
    },
    setOverlayLoader (state, data) {
    },
    emptyState () {
      // this.replaceState({ activeDesign: null })
    },
    // other mutations
    ...vuexfireMutations
  },
  actions: {
    undoDesign (context) {
      // context.state.activeDesignHistory.pop()
      if (context.state.activeDesignHistory.length > 0) {
        context.state.activeDesignHistory.pop()
        const historyList = context.state.activeDesignHistory.length
        if (historyList > 0) {
          context.commit('activeDesign', context.state.activeDesignHistory[historyList - 1])
        }
      }
    },
    logout (context) {
      context.state.user = null
      context.state.designs = []
      window.localStorage.removeItem('appData')
    },
    // Add elements to artboard
    insertImage (context, payload) {
      context.state.activeDesign.content[0].items.push(payload)
      context.commit('activeDesign', context.state.activeDesign)
    },
    // Activate Element on artboard
    activeElement (context, payload) {
      context.state.activeElement = payload
    },
    // Change Active Element content
    changeElementContent (context, payload) {
      context.state.activeElement.content = payload.content
      context.state.activeElement.type = payload.type
      // context.state.activeElement.url = payload.url
      // context.state.activeElement.thumbnail = payload.thumbnail
      // context.state.activeElement.object = payload.object
      context.commit('activeDesign', context.state.activeDesign)
    },
    // Add collection
    addCollection: firestoreAction((context, payload) => {
      db.collection('Collection').doc(payload.collectionID).set(payload)
    }),
    // Add images to collections
    uploadCollectionImages: firestoreAction((context, payload) => {
      db.collection('Collection').doc(payload.collectionID).collection('CollectionImages').doc(payload.uuid).set(payload)
    }),
    // COLLECTIONS
    loadCollection: firestoreAction(async (state, payload) => {
      // myCollection.where(firestore.FieldPath.documentId(), 'in', ["123","456","789"])
      let collection = false
      if (state.state.collections.length > 0) {
        collection = state.state.collections.find(collection => collection.title === payload.title)
      }
      if (!collection && payload.collectionIDs.length > 0) {
        const content = await getContentById(payload.collectionIDs, 'Collection')
        // console.log(content)
        loadCollection(content)
      }
      async function loadCollection (collectionData) {
        var array = []
        collectionData.forEach(collection => {
          const images = []
          db.collection('Collection').doc(collection.collectionID).collection('CollectionImages').get().then(snap => {
            if (snap.empy) {

            } else {
              snap.forEach(doc => {
                images.push(doc.data())
              })
              const object = {
                info: collection,
                images: images
              }
              array.push(object)
            }
          })
        })
        const output = {
          title: payload.title,
          content: array
        }
        state.commit('setCollection', output)
      }
    }),
    // DESIGNS
    selectDesign (context, payload) {
      const designSelected = context.state.designs.find(design => design.dID === payload.dID)
      if (designSelected) {
        // context.commit('activeDesign', designSelected)
        context.dispatch('bindDesign', payload)
      } else {
        context.commit('activeDesign', payload)
      }
    },
    bindDesign: firestoreAction(({ bindFirestoreRef, state }, payload) => {
      return bindFirestoreRef('activeDesign', db.collection('User/' + state.user + '/MyDesigns').doc(payload.dID))
    }),
    bindDesigns: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.user && state.user !== 'guest_user') {
        return bindFirestoreRef('designs', db.collection('User/' + state.user + '/MyDesigns'))
      }
    }),
    menu: firestoreAction((state) => {
      db.collection('Menu').get().then(querySnapshot => {
      // console.log(snapshot)
        if (querySnapshot.empty) {
          // this.$router.push('/HelloWorld')
        } else {
          var categories = []
          querySnapshot.forEach(doc => {
            if (doc.data().status === 'active') {
              categories.push(doc.data())
            }
          })
          state.commit('setMenu', categories)
        }
      })
    }),
    // Store it
    send: firestoreAction((state) => {
      const payload = state.state.activeDesign
      return db.collection('GuestSessions').doc(payload.dID).set(payload).then(() => {
        return {
          type: 'guest',
          uid: payload.dID
        }
      })
    }),
    sendToCart: firestoreAction((state) => {
      const payload = state.state.activeDesign
      if (state.state.user !== 'guest_user') {
        return db.collection('User').doc(state.state.user).collection('Designs').doc(state.state.activeDesign.dID).set(payload).then((doc) => {
          return {
            type: 'user',
            usr: state.state.user,
            uid: payload.dID
          }
        })
      } else {
        return db.collection('GuestCartSessions').doc(payload.dID).set(payload).then(() => {
          return {
            type: 'guest',
            uid: payload.dID
          }
        })
      }
    }),
    loadDesign: firestoreAction((context, payload) => {
      let collectionDesign = null
      let docRef
      if (context.state.userType === 'firebase') {
        if (payload.type === 'user' && payload.sender === 'cart') {
          collectionDesign = 'User'
          docRef = db.collection(collectionDesign).doc(payload.user).collection('Designs').doc(payload.id)
        } else {
          collectionDesign = 'GuestCartSessions'
          docRef = db.collection(collectionDesign).doc(payload.id)
        }
      } else {
        if (payload.type === 'guest_user' && payload.sender === 'mail') {
          collectionDesign = 'GuestSessions'
          docRef = db.collection(collectionDesign).doc(payload.id)
        }
        if (payload.type === 'guest_user' && payload.sender === 'cart') {
          collectionDesign = 'GuestCartSessions'
          docRef = db.collection(collectionDesign).doc(payload.id)
        }
        if (payload.type === 'user' && payload.sender === 'cart') {
          collectionDesign = 'User'
          docRef = db.collection(collectionDesign).doc(context.state.user).collection('Designs').doc(payload.id)
        }
        if (payload.type === 'user' && payload.sender === 'continue') {
          collectionDesign = 'User'
          docRef = db.collection(collectionDesign).doc(context.state.user).collection('MyDesigns').doc(payload.id)
        }
      }

      if (collectionDesign) {
        docRef.get().then((doc) => {
          if (doc.exists) {
            context.commit('loadedDesign', true)
            context.commit('activeDesign', doc.data())
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        }).catch((error) => {
          console.log('Error getting document:', error)
        })
      }
    }),
    loadUser: firestoreAction((state, payload) => {
      if (state.state.user !== payload) {
        state.commit('resetImages', [])
        state.commit('activeDesign', null)
        state.commit('setUser', payload)
        state.dispatch('bindImages')
        state.dispatch('bindDesigns')
        state.commit('checkDesign')
      } else {
        if (payload === 'guest_user') {
          // state.dispatch('logout')
          state.commit('setUser', payload)
          state.dispatch('bindImages')
          state.dispatch('bindDesigns')
          state.commit('checkDesign')
        } else if (payload !== 'guest_user') {
          state.commit('setUser', payload)
          state.dispatch('getUserDetails', payload)
          state.dispatch('bindImages')
          state.dispatch('bindDesigns')
          state.commit('checkDesign')
        }
      }
    }),
    getUserDetails: firestoreAction((context, payload) => {
      var docRef = db.collection('User').doc(payload)

      docRef.get().then((doc) => {
        if (doc.exists) {
          context.state.userType = doc.data()
        } else {
          db.collection('User').doc(context.state.user).set({ website: 'active', type: 'user' })
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }),
    saveDesign: firestoreAction((context, payload) => {
      if (context.state.user !== 'guest_user') {
        payload.modified = Date.now()
        db.collection('User').doc(context.state.user).collection('MyDesigns').doc(context.state.activeDesign.dID).set(payload)
          .then(() => {
            console.log('Document successfully written!')
          })
          .catch(() => {
            return 'Error'
          })
      }
    }),
    saveDesignIw: firestoreAction((context, payload) => {
      if (context.state.userType === 'firebase') {
        payload.modified = Date.now()
        if (payload.user !== null) {
          db.collection('User').doc(payload.user).collection('Designs').doc(payload.id).set(payload.session)
            .then((data) => {
              console.log('Document successfully written!')
            })
            .catch(() => {
              return 'Error'
            })
        } else {
          db.collection('GuestCartSessions').doc(payload.id).set(payload.session)
            .then((data) => {
              console.log('Document successfully written!')
            })
            .catch(() => {
              return 'Error'
            })
        }
      }
    }),
    updateDesign: firestoreAction((context, payload) => {
      // if (context.state.activeDesign.dID == null) {
      //   const newTitle = Date.now().toString()
      //   context.state.activeDesign.title = newTitle
      //   context.state.activeDesign.dID = newTitle
      // }
      context.commit('activeDesign', payload)
    }),
    // IMAGES & UPLOADS
    bindImages: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.user && state.user !== 'guest_user') {
        return bindFirestoreRef('images', db.collection('User/' + state.user + '/MyImages'))
      }
    }),
    uploadImages: firestoreAction((context, payload) => {
      if (context.state.user && context.state.user !== 'guest_user') {
        db.collection('User').doc(context.state.user).collection('MyImages').doc(payload.uuid).set(payload)
      } else {
        context.commit('setImages', payload)
      }
    }),
    removeImages: firestoreAction((context, payload) => {
      if (payload) {
        for (let i = 0; i < payload.uploadCareID.length; i++) {
          db.collection('User').doc(context.state.user.uid).collection('MyImages').doc(payload.uploadCareID[i]).delete()
        }
      }
    }),
    bindTodos: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('todos', db.collection('todos'))
    })
  }
})

export async function getContentById (ids, path) {
  // don't run if there aren't any ids or a path for the collection
  if (!ids || !ids.length || !path) return []

  const collectionPath = db.collection(path)
  const batches = []

  while (ids.length) {
    // firestore limits batches to 10
    const batch = ids.splice(0, 10)

    // add the batch request to to a queue
    batches.push(
      collectionPath
        .where(
          firebase.firestore.FieldPath.documentId(),
          'in',
          [...batch]
        )
        .get()
        .then(results => results.docs.map(result => ({ /* id: result.id, */ ...result.data() })))
    )
  }

  // after all of the data is fetched, return it
  return Promise.all(batches)
    .then(content => content.flat())
}
