import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueGtm from '@gtm-support/vue2-gtm'
// import store from './store'
import VueLazyload from 'vue-lazyload'
import VueLoading from 'vuejs-loading-plugin'

import VueScreen from 'vue-screen'
import VueI18n from 'vue-i18n'

import VueConfirmDialog from 'vue-confirm-dialog'
import VueTippy, { TippyComponent } from 'vue-tippy'

import 'tippy.js/themes/light.css'
import { messages } from './locales/index.js'

import { firestorePlugin } from 'vuefire'
import eventManager from './helpers/eventManager.js'
import VueTimeago from 'vue-timeago'

import VueShopifyDraggable from 'vue-shopify-draggable'

// On Application Entry remove old data if it is older than 10 days
const data = localStorage.getItem('appData')

if (data) {
  const parsedData = JSON.parse(data)
  const timestamp = parsedData?.activeDesign?.modified

  if (timestamp) {
    const now = Date.now()
    const lifetime = 1000 * 86400 * 10 // 10 days

    if (now - lifetime > timestamp) {
      localStorage.removeItem('appData')
    }
  }
}

Vue.use(VueShopifyDraggable)

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'nl', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja'),
    nl: require('date-fns/locale/nl')
  }
})

Vue.use(eventManager)

Vue.use(firestorePlugin)

// Vue.use(VueTippy)
// or
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
})

Vue.use(VueI18n)

Vue.prototype.$watchAll = function (props, callback) {
  props.forEach(prop => {
    this.$watch(prop, callback)
  })
}

Vue.component('tippy', TippyComponent)

// Vue.use(VueGtm, {
//   id: 'GTM-MKWXB3W', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
//   defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: true, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   ignoredViews: ['Wondering'], // Don't trigger events for specified router names (case insensitive) (optional)
//   trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
// })

Vue.use(VueScreen, 'bootstrap')

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.config.productionTip = true

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
})

Vue.use(VueLoading, {
  dark: true,
  text: '',
  loading: false,
  background: 'rgba(255,255,255,0.62)',
  classes: ['spinner']
})

Vue.use(VueLazyload)

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth
  Vue.prototype.$screen.height = window.innerHeight
})

const version = process.env.VUE_APP_BUILD
if (!localStorage.getItem('app_build')) {
  localStorage.setItem('app_build', version)
}

// Determine locale ------------------------------------------------------------
let maybeLocale
const availableLocales = Object.keys(messages)

// Attempt to resolve locale by url 'lang' query
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

if (urlParams.has('lang')) {
  maybeLocale = availableLocales.find(locale => locale === urlParams.get('lang'))
}

// Fallback to using the browser language
if (!maybeLocale) {
  maybeLocale = availableLocales.find(locale => navigator.language.startsWith(locale))
}

// Mount i18n with determined locale
const i18n = new VueI18n({
  locale: maybeLocale || 'en',
  fallbackLocale: 'en',
  messages // set locale messages
})

// Set global variable for uploadcare locale
global.UPLOADCARE_LOCALE = maybeLocale

// Set translation variable for uploadcare locale
if (messages[maybeLocale]) {
  global.UPLOADCARE_LOCALE_TRANSLATIONS = messages[maybeLocale]
}

new Vue({
  router,
  i18n,
  // store,
  render: h => h(App)
}).$mount('#app')
