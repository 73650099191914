<template>
  <div class="layouts-ctn" :class="{ inline: inline, hidden: hidden, fullscreen: Fullscreen, wide: wide, square: square }">
    <h4 v-if="inline">{{ $t('system.layout') }}</h4>
    <template v-if="classicLayouts.length">
      <div class="label-category">Instawall Basic</div>
      <div class="scrollable-row">
        <div v-for="(layout, index) in classicLayouts" :key="index" @click="changeLayout(layout)" class="layout-item" :class="{selected: (activeDesign.content[0].gridId === layout.id)}">
          <div class="postCounter"><i class="far fa-check"></i></div>
          <svg :width="width + 2 + 'px'" :height="height + 2 + 'px'" :viewBox="'0 0 '+ (width + 2) + ' ' + (height + 2)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="single-copy-76" stroke="#E4E4E4" fill="#FFFFFF" x="1" y="1" :width="width" :height="height"></rect>
                <!-- Layouts icons -->
                <rect v-for="(n, index) in layout.cols * layout.rows" :key="n" stroke="#e4e4e4" fill="none" :x="(width / layout.cols) * (index % layout.cols) + 1" :y="(height / layout.rows) * (Math.floor(index / layout.cols)) + 1" :opacity="(hide(index, layout.skip)) ? 0 : 1" :width="(width / layout.cols) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'x')" :height="(height / layout.rows) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'y')"></rect>
                <g v-if="layout.type === 'free'">
                <circle id="Oval" stroke="#D8D8D8" stroke-width="1" :cx="width / 2" :cy="height / 2" r="10"></circle>
                <line :x1="(width / 2) - 10" :y1="(width / 2)" :x2="(width / 2) + 10" :y2="(width / 2) + 20" id="Line" translate="30px 30px" stroke="#D8D8D8" stroke-width="1" stroke-linecap="square"></line>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </template>
    <template v-if="customLayouts.length">
      <div class="label-category">Instawall Mosaic</div>
      <div class="scrollable-row">
        <div v-for="(layout, index) in customLayouts" :key="index" @click="changeLayout(layout)" class="layout-item" :class="{selected: (activeDesign.content[0].gridId === layout.id)}">
          <div class="postCounter"><i class="far fa-check"></i></div>
          <svg :width="width + 2 + 'px'" :height="height + 2 + 'px'" :viewBox="'0 0 '+ (width + 2) + ' ' + (height + 2)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="single-copy-76" stroke="#E4E4E4" fill="#FFFFFF" x="1" y="1" :width="width" :height="height"></rect>
                <!-- Layouts icons -->
                <rect v-for="(n, index) in layout.cols * layout.rows" :key="n" stroke="#e4e4e4" fill="none" :x="(width / layout.cols) * (index % layout.cols) + 1" :y="(height / layout.rows) * (Math.floor(index / layout.cols)) + 1" :opacity="(hide(index, layout.skip)) ? 0 : 1" :width="(width / layout.cols) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'x')" :height="(height / layout.rows) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'y')"></rect>
                <g v-if="layout.type === 'free'">
                <circle id="Oval" stroke="#D8D8D8" stroke-width="1" :cx="width / 2" :cy="height / 2" r="10"></circle>
                <line :x1="(width / 2) - 10" :y1="(width / 2)" :x2="(width / 2) + 10" :y2="(width / 2) + 20" id="Line" translate="30px 30px" stroke="#D8D8D8" stroke-width="1" stroke-linecap="square"></line>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </template>
    <template v-if="cleanLayouts.length">
      <div class="label-category">Minimalistic</div>
      <div class="scrollable-row">
        <div v-for="(layout, index) in cleanLayouts" :key="index" @click="changeLayout(layout)" class="layout-item" :class="{selected: (activeDesign.content[0].gridId === layout.id)}">
          <div class="postCounter"><i class="far fa-check"></i></div>
          <svg :width="width + 2 + 'px'" :height="height + 2 + 'px'" :viewBox="'0 0 '+ (width + 2) + ' ' + (height + 2)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="single-copy-76" stroke="#E4E4E4" fill="#FFFFFF" x="1" y="1" :width="width" :height="height"></rect>
                <!-- Layouts icons -->
                <rect v-for="(n, index) in layout.cols * layout.rows" :key="n" stroke="#e4e4e4" fill="none" :x="(width / layout.cols) * (index % layout.cols) + 1" :y="(height / layout.rows) * (Math.floor(index / layout.cols)) + 1" :opacity="(hide(index, layout.skip)) ? 0 : 1" :width="(width / layout.cols) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'x')" :height="(height / layout.rows) * scale(index % layout.cols, Math.floor(index / layout.cols), layout.big, 'y')"></rect>
                <g v-if="layout.type === 'free'">
                <circle id="Oval" stroke="#D8D8D8" stroke-width="1" :cx="width / 2" :cy="height / 2" r="10"></circle>
                <line :x1="(width / 2) - 10" :y1="(width / 2)" :x2="(width / 2) + 10" :y2="(width / 2) + 20" id="Line" translate="30px 30px" stroke="#D8D8D8" stroke-width="1" stroke-linecap="square"></line>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ArtboardCreator from '@/helpers/artboard-creator.js'
import newStore from '@/store/newstore'
// import FontPicker from 'font-picker-vue'
import list from './layout-list.js'

export default {
  components: {
  },
  props: {
    type: String,
    inline: Boolean,
    hidden: Boolean,
    Menu: String,
    Fullscreen: Boolean
  },
  data () {
    return {
      width: 0,
      height: 0,
      selected: null,
      wide: false,
      square: false
    }
  },
  mounted () {
    this.ratioCheck()
    this.selectDefault()
  },
  methods: {
    parseSkuToDimensions () {
      const segments = this.activeDesign?.sku?.split('-') || []
      if (segments.length > 3) {
        if (!isNaN(segments[1]) && !isNaN(segments[2])) {
          this.width = parseInt(segments[1])
          this.height = parseInt(segments[2])
        }
      }
    },
    ratioCheck () {
      // Assign value based on ratio
      switch (this.ratio) {
        case 'square':
          // code block
          this.width = 80
          this.height = 80
          this.wide = false
          this.square = true
          break
        case 'landscape':
          this.width = 100
          this.height = 80
          this.wide = false
          this.square = false
          break
        case 'portrait':
          this.width = 60
          this.height = 80
          this.wide = false
          this.square = true
          break
        case 'wide':
          this.width = 160
          this.height = 80
          this.wide = true
          this.square = false
          break
        case 'tall':
          this.width = 40
          this.height = 80
          this.wide = false
          this.square = true
          break
        case 'strip':
          this.width = 20
          this.height = 80
          this.wide = false
          this.square = true
          break
        default:
          this.width = 80
          this.height = 80
          this.wide = false
          this.square = true
        // code block
      }
      this.parseSkuToDimensions()
    },
    changeLayout (layout) {
      if (!this.activeDesign.content[0].gridId || this.activeDesign.content[0].gridId !== layout.id) {
        if (layout.override) {
          this.activeDesign.content[0].borderSize = layout.override.border
          this.activeDesign.content[0].gridSize = layout.override.grid
        }
        ArtboardCreator.changeMode(layout.id, layout.type, layout.cols, layout.rows, layout.big, layout.skip)
        newStore.commit('changeActiveDesign', this.activeDesign)
      }
    },
    hide (index, list) {
      return list.filter(i => i === index).length
    },
    scale (col, row, list, axis) {
      const obj = list.filter(i => i.col === col && i.row === row)
      if (obj.length > 0 && axis === 'x') {
        return obj[0].scaleX
      } else if (obj.length > 0 && axis === 'y') {
        return obj[0].scaleY
      } else {
        return 1
      }
    },
    selectDefault (override) {
      if (this.newDesign || override) {
        console.log('test')
        this.activeDesign.content[0].borderSize = 10
        this.activeDesign.content[0].gridSize = 3
        this.changeLayout(this.defaultLayout[0])
        newStore.commit('newDesign', false)
      }
    },
    filterLayoutByMinMax (item) {
      const minGridSize = 7
      if (!this.width || !this.height) {
        return false
      }
      if (item.max) {
        if (item.max[0] < this.width || item.max[1] < this.height) {
          return false
        }
      }
      if (this.width <= item.cols * minGridSize || this.height <= item.rows * minGridSize) {
        return false
      }
      return true
    }
  },
  watch: {
    // whenever question changes, this function will run
    newDesign: function (newA, old) {
      if (newA === true && newA !== old) {
        this.selectDefault()
      }
    },
    ratio: function (newRatio, oldRatio) {
      if (newRatio !== oldRatio) {
        this.ratioCheck()
        if (!this.loadedDesign) {
          this.selectDefault(true)
        } else {
          newStore.commit('loadedDesign', false)
        }
      }
    },
    sku: function (newDesign, oldDesign) {
      this.parseSkuToDimensions()
    },
    Menu (oldMenu, newMenu) {
      document.querySelector('.layouts-ctn').scrollTop = 0
    }
  },
  computed: {
    activeDesign () {
      return newStore.state.activeDesign
    },
    activeGridId () {
      return newStore.state.activeDesign.content[0].gridId || 999
    },
    loadedDesign () {
      return newStore.state.loadedDesign
    },
    newDesign () {
      return newStore.state.newDesign
    },
    sku () {
      return this.activeDesign.sku
    },
    ratio () {
      let ratio = 'ratio'
      if (this.activeDesign) {
        const sizeW = this.activeDesign.content[0].sizes.w
        const sizeH = this.activeDesign.content[0].sizes.h
        if (sizeW === sizeH) {
          ratio = 'square'
        } else if (sizeW < sizeH) {
          if (sizeH > (sizeW * 3)) {
            ratio = 'strip'
          } else if (sizeH > (sizeW * 1.45)) {
            ratio = 'tall'
          } else {
            ratio = 'portrait'
          }
        } else {
          if (sizeW > (sizeH * 1.45)) {
            ratio = 'wide'
          } else {
            ratio = 'landscape'
          }
        }
      }
      return ratio
    },
    activeElement () {
      return newStore.state.activeElement
    },
    list () {
      // search = this.checkUsedImages.filter(el => el === id.uuid)
      return list.get().filter(item => item.ratio === this.ratio || item.ratio === 'all')
    },
    defaultLayout () {
      if (!this.width || !this.height) {
        return []
      }
      return this.list.filter(item => item.tags === 'default')
    },
    classicLayouts () {
      return this.list
        .filter(item => item.tags === 'classic' || item.tags === 'default')
        .filter(this.filterLayoutByMinMax) || []
    },
    cleanLayouts () {
      return this.list
        .filter(item => item.tags === 'clean' || item.tags === 'single')
        .filter(this.filterLayoutByMinMax) || []
    },
    customLayouts () {
      return this.list
        .filter(item => item.tags === 'custom')
        .filter(this.filterLayoutByMinMax) || []
    },
    landscapeLayouts () {
      return this.list
        .filter(item => item.tags === 'landscape')
        .filter(this.filterLayoutByMinMax) || []
    },
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    }
  }
}
</script>

<style lang='scss' scoped>

.postCounter {
  display: none;
}
.selected {
  .postCounter {
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    background: #0080ff;
    border-radius: 99px;
    border: 2px solid white;
    padding: 5px;
    z-index:1;
    i {
      color: white;
    }
  }
}
.layout-item {
  position: relative;
}
.layouts-ctn {
  width: 100%;
  padding: 20px;
  height: auto;
  box-sizing: border-box;

@media only screen and (max-width: 760px) {
  padding: 0;
  padding-top: 20px;
  height: 270px;
  overflow-y: scroll;

}
  &.fullscreen {
    height: calc(100vh - 180px);
    top: 0;
  }

  .label {
    width: 100%;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    color: #333;
    text-transform: uppercase;
  }
  .break {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    height: 100%;
  }
  &.hidden {
    display: none;
  }
  &.inline {
    padding: 0px;
    width: 100%;
    .scrollable-row {
      padding: 10px;
      overflow-x: visible;
      overflow-y: visible;
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: repeat(3,32%);
      justify-content: stretch;
      align-items: flex-start;
      .layout-item {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }
      .break {
      }
    }
    &.wide {
      .scrollable-row {
        grid-template-columns: repeat(2,49%);
      }
    }
    &.square {
      .scrollable-row {
        grid-template-columns: repeat(4,24%);
      }
    }
  }
}
.fontEdit {
  font-size: 16px !important;
  width: 100%;
  box-sizing: border-box;
}
.label-category {
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 0px;
  margin-bottom: 8px;
@media only screen and (max-width: 760px) {
  margin-left: 20px;
}
}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: calc(100%);
    height: 100%;
    padding:0px;
    align-items: flex-start;

@media only screen and (max-width: 760px) {
  height: 120px;
}
    .layout-item {
      align-items: normal;
      cursor: pointer;
      width: auto;

@media only screen and (max-width: 760px) {
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
}
      img {
        max-width: 80px;
        max-height: 80px;
      }
      svg {
        min-height: 80px;
        width: auto !important;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
