<template>
  <div class="scrollable-row" v-if="activeElement.type === 'image'">
        <button @click="filter('')" class="clean" :class="{selected: activeElement.content.filters.effect === '' }"><img src="https://ucarecdn.com/2f93a9ac-414b-4cbe-aef1-0462e3bc8cd8/-/quality/lighter/-/preview/-/smart_resize/80x80/" />
          <div class="postCounter"><i class="far fa-check"></i></div>
          <label>{{$t('filter.none')}}</label>
        </button>
        <button @click="filter('-/filter/carris/')" class="clean" :class="{selected: activeElement.content.filters.effect === '-/filter/carris/' }"><img src="https://ucarecdn.com/2f93a9ac-414b-4cbe-aef1-0462e3bc8cd8/-/quality/lighter/-/preview/-/smart_resize/80x80/-/filter/carris/200" />
          <div class="postCounter"><i class="far fa-check"></i></div>
          <label>{{$t('filter.silver')}}</label>
        </button>
        <button @click="filter('-/grayscale/')" class="clean" :class="{selected: activeElement.content.filters.effect === '-/grayscale/' }"><img src="https://ucarecdn.com/2f93a9ac-414b-4cbe-aef1-0462e3bc8cd8/-/quality/lighter/-/preview/-/smart_resize/80x80/-/grayscale/" />
          <div class="postCounter"><i class="far fa-check"></i></div>
          <label>{{$t('filter.gray')}}</label>
        </button>
        <button @click="filter('-/filter/adaris/')" class="clean" :class="{selected: activeElement.content.filters.effect === '-/filter/adaris/' }"><img src="https://ucarecdn.com/2f93a9ac-414b-4cbe-aef1-0462e3bc8cd8/-/quality/lighter/-/preview/-/smart_resize/80x80/-/filter/adaris/" />
          <div class="postCounter"><i class="far fa-check"></i></div>
          <label>{{$t('filter.adaris')}}</label>
        </button>
        <button @click="filter('-/filter/zatvel/')" class="clean" :class="{selected: activeElement.content.filters.effect === '-/filter/zatvel/' }"><img src="https://ucarecdn.com/2f93a9ac-414b-4cbe-aef1-0462e3bc8cd8/-/quality/lighter/-/preview/-/smart_resize/80x80/-/filter/zatvel/" />
          <div class="postCounter"><i class="far fa-check"></i></div>
          <label>{{$t('filter.zatvel')}}</label>
        </button>
  </div>
</template>

<script>
import newStore from '@/store/newstore'

export default {
  components: {
  },
  data () {
    return {
      slice: 27,
      posts: [],
      errors: [],
      isEdit: false,
      usedImages: []
    }
  },
  methods: {
    filter (filter) {
      const i = this.activeElement
      if (i.type === 'image') {
        const urlBuilder = this.activeElement.content.filters
        // i.filters.grayscale = true
        // urlModifier += filter //  '-/grayscale/'
        urlBuilder.effect = filter
        i.content.url = urlBuilder.base + urlBuilder.crop + urlBuilder.effect
        i.content.thumbnail = urlBuilder.base + urlBuilder.cropThumb + urlBuilder.effect

        newStore.commit('activeDesign', this.activeDesign)
      }
    }
  },
  computed: {
    user () {
      return newStore.state.user || []
    },
    uploads () {
      return newStore.state.images || []
    },
    activeElement () {
      return newStore.state.activeElement
    },
    activeDesign () {
      return newStore.state.activeDesign
    }
  }
}
</script>

<style lang="scss" scoped>

.postCounter {
  display: none;
}
.selected {
  .postCounter {
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    background: #0080ff;
    border-radius: 99px;
    border: 2px solid white;
    padding: 5px;
    z-index:1;
    i {
      color: white;
    }
  }
}
  .scrollable-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 8px;
    width: 100%;
    height: 100%;
    margin-left: 0;
    box-sizing: border-box;
    margin-right: 0;
    padding:10px;
    align-items: center;
    position: relative;
    .clean {
      padding: 0;
      img {
        border-radius: 10px;
      }
      label {
        position: absolute;
        bottom: -10px;
        font-size: 10px;
        color: black;
        width: 100%;
        left: 0;
        text-align: center;
      }
    }
    .media-grid-item {
      max-height: 80px;
      max-width: 80px;
      min-width: 80px;
      align-items: normal;
      img {
        max-width: 80px;
      }
    }
    .large-ctn {
      margin-top:0;
      margin-bottom:0;
      button {
        content: '+';
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
