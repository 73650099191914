<template>
  <div class="home">
    home
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style>
  .home {
    width: 100%;
    height: 100%;
  }
</style>
