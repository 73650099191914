import Api from './http-common'
import store from '../store/index'

export default {
  login (req) {
    this.checkAuthorization()
    return Api.post('login', req)
  },
  allMenus () {
    Api.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    return Api.get('getMenuList')
  },
  addMenu (req) {
    this.checkAuthorization()
    return Api.post('createMenu', req)
  },
  register (req) {
    this.checkAuthorization()
    return Api.post('register', req)
  },
  deleteMenu (id) {
    this.checkAuthorization()
    return Api.delete('deleteMenu?menuID=' + id)
  },
  generatePdf (req) {
    this.checkAuthorization()
    return Api.post('generatePdf', req)
  },
  generatePdfAdmin (req) {
    this.checkAuthorization()
    return Api.post('generatePdfAdmin', req)
  },
  verifyLogin (req) {
    this.checkAuthorization()
    return Api.post('loginShopify', req)
  },
  RefreshToken (req) {
    this.removeAuthorization()
    return Api.post('getTokenFromRefreshToken', req)
  },
  deleteDesigns (req) {
    this.checkAuthorization()
    return Api.post('deleteMyDesignData', req)
  },
  getUserDetails (req) {
    this.checkAuthorization()
    return Api.get('userDetail', req)
  },
  deleteCollection (c) {
    this.checkAuthorization()
    return Api.delete('deleteCollection?menuID=' + c.menuID + '&collectionID=' + c.collectionID)
  },
  checkAuthorization () {
    Api.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    if (
      !Api.defaults.headers.common.Authorization &&
      store?.state?.userStore?.user?.token
    ) {
      this.setAuthorization(store?.state?.userStore?.user?.token)
    } else if (!store?.state?.userStore?.user?.token) {
      this.removeAuthorization()
    }
  },
  uploadUserImage (req) {
    this.checkAuthorization()
    return Api.post('storeUploadCareData', req)
  },
  removeAuthorization () {
    delete Api.defaults.headers.common.Authorization
  },
  uploadUserDesign (req) {
    this.checkAuthorization()
    return Api.post('storeMyDesignData', req)
  },
  createCollection (req) {
    this.checkAuthorization()
    return Api.post('createCollection', req)
  },
  uploadGuestImage (req) {
    this.checkAuthorization()
    return Api.post('storeGuestUserImages', req)
  },
  addEditCollection (req) {
    this.checkAuthorization()
    return Api.post(req.collectionID ? 'updateCollection' : 'createCollection', req)
  },
  deleteUploadImage (req) {
    this.checkAuthorization()
    return Api.post('deleteUploadCareData', req)
  },
  deleteCollectionImages (req) {
    this.checkAuthorization()
    return Api.post('deleteCollectionImages', req)
  },
  setAuthorization (token) {
    if (token) {
      Api.defaults.headers.common.Authorization = `Bearer ${token}`
    }
    return this
  },
  setOrderCollection (req) {
    this.checkAuthorization()
    return Api.post('setOrderCollection', { collectionID: req })
  },
  deleteCollectionDetail (c) {
    this.checkAuthorization()
    return Api.post('deleteCollectionDetail', c)
  },
  deleteGuestUploadImage (req) {
    this.checkAuthorization()
    return Api.post('removeGuestUserImages', req)
  },
  uploadCollectionImages (req) {
    this.checkAuthorization()
    return Api.post('storeCollectionImages', req)
  },
  uploadUserDesignToCollection (req) {
    this.checkAuthorization()
    return Api.post(req.collectionDetailID ? 'updateCollectionDetail' : 'storeCollectionDetail', req)
  }
}
