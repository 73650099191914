<template>
  <div class="progress">
    <uploadDialog v-if="mobile && !onlyProgress" class="floating" />
    <!-- <progress-ring :radius="10" :progress="(100 / Number(total)) * Number(count)" :stroke="1"></progress-ring> -->
  </div>
</template>

<script>
import newStore from '@/store/newstore'
import uploadDialog from './upload-dialog.vue'

export default {
  components: {
    uploadDialog
  },
  props: {
    onlyProgress: null
  },
  computed: {
    activeBoard () {
      return newStore.state.activeDesign
    },
    count () {
      if (this.activeBoard) {
        const search = this.activeBoard.content[0].items.filter(item => item.type === 'empty').length
        // const used = this.total - search
        return search
      } else {
        return null
      }
    },
    total () {
      if (this.activeBoard) {
        return this.activeBoard.content[0].items.length
      } else {
        return null
      }
    },
    mobile () {
      return newStore.state.mobile
    }
  }

}
</script>

<style lang="scss">
  .progress {

    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.progress-bottom {
  bottom: 40px;
  position:fixed;
  width: 100%;
  text-align: center;
  z-index:2;
  .progress-count {
    opacity: 0.6;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 760px){
  .progress-bottom {
    bottom: 14px;
  }
}
</style>>
