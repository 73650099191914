<template>
  <div style="width: 100%;" :class="'toolbar-ctn ' + position" v-if="activeDesign">
    <div class="main-artboard-menu">
          <tippy
                interactive
                :animate-fill="false"
                placement="bottom"
                distant="7"
                theme="light"
                animation="fade"
                trigger="click"
                v-if="!mobile"
                arrow
                @click="activeSub = 'color'"
                >
            <template v-slot:trigger>
                <button :class="{ active: (activeSub === 'color')}" v-if="!activeElement" @click="activeSub = 'color'" class="clean">
                    <i class="fal fa-palette"></i>
                    <label class="labelsmall">{{ $t('icons.color') }}</label>
                  </button>
            </template>
            <Color></Color>
      </tippy>
      <button class="clean" v-if="activeElement !== null" @click="onElementRemove"><i
          class="far fa-trash-alt"></i><label class="labelsmall">{{ $t('icons.delete') }}</label></button>
      <button class="clean" :class="{ active: (activeSub === 'uploads')}" v-if="mobile" @click="activeSub = 'uploads', slideMenu = true"><i class="fal fa-images"></i>
          <label class="labelsmall">{{ $t('icons.photos') }}</label></button>
      <button class="clean" @click="activeSub = 'quotes', slideMenu = true" v-if="!sidebar && !activeElement"><i class="fal fa-quote-right"></i>
          <label class="labelsmall">{{ $t('icons.quotes') }}</label></button>
      <button class="clean"  :class="{ active: (activeSub === 'layout')}" v-if="!activeElement && mobile" @click="activeSub = 'layout', slideMenu = true"><i class="fal fa-border-all"></i>
          <label class="labelsmall">{{ $t('icons.layout') }}</label></button>
      <button :class="{ active: (activeSub === 'crop')}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'crop', overlayActive(true)" class="clean">
            <i class="fal fa-crop"></i>
          <label class="labelsmall">{{ $t('icons.crop') }}</label>
      </button>
      <tippy
                interactive
                :animate-fill="false"
                placement="bottom"
                distant="7"
                theme="light"
                animation="fade"
                trigger="click"
                v-if="!mobile && activeElement && activeElement.type === 'image'"
                arrow
                @click="activeSub = 'filter'"
                >
            <template v-slot:trigger>
                <button :class="{ active: (activeSub === 'filter')}" @click="activeSub = 'filter'" class="clean">
                    <i class="fal fa-magic"></i>
                    <label class="labelsmall">{{ $t('icons.filter') }}</label>
                  </button>
            </template>
            <imageFilters></imageFilters>
      </tippy>
             <button :class="{ active: (activeSub === 'filters')}" v-if="activeElement && activeElement.type === 'image' && mobile" @click="activeSub = 'filters', slideMenu = true" class="clean">
              <i class="fal fa-magic"></i>
              <label class="labelsmall">{{ $t('icons.filter') }}</label>
            </button>
            <imageCrop style="z-index: 999;" v-on:update:activeSub="activeSub = $event" v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>
    </div>
    <div class="slide-menu" v-if="position === 'bottom'" :class="{ active: (slideMenu && activeSub !== null), layout: (activeSub === 'layout'), full: (full)}">
      <div class="layout slide-content" v-if="activeSub === 'layout' || activeSub === 'gridLines' || activeSub === 'borderSize' || activeSub === 'color'">
        <div class="btn-row">
          <button class="clean"  :class="{ active: (activeSub === 'layout')}" v-if="!activeElement && mobile" @click="activeSub = 'layout'"><i class="fal fa-border-all"></i>
                <label class="labelsmall">{{ $t('icons.layout') }}</label></button>
            <button class="clean"  :class="{ active: (activeSub === 'gridLines')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'gridLines', full = false"><i class="fal fa-border-inner"></i>
                <label class="labelsmall">{{ $t('icons.grid') }}</label></button>
            <button class="clean"  :class="{ active: (activeSub === 'borderSize')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'borderSize', full = false"><i class="fal fa-border-outer"></i>
                <label class="labelsmall">{{ $t('icons.border') }}</label></button>
            <button class="clean"  :class="{ active: (activeSub === 'color')}"  @click="activeSub = 'color', full = false"><i class="fal fa-palette"></i>
                <label class="labelsmall">{{ $t('icons.color') }}</label></button>

          <button class="clean autoright" @click="full = true" v-if="full === false"><i class="fal fa-chevron-square-up"></i>
          <label class="labelsmall">{{ $t('icons.expand') }}</label></button>
          <button class="clean autoright" @click="full = false" v-if="full === true"><i class="fal fa-chevron-square-down"></i>
          <label class="labelsmall">{{ $t('icons.collapse') }}</label></button>
            <button class="clean nomargin" @click="slideMenu = false, full = false"><i class="far fa-times-circle"></i>
            <label class="labelsmall">
              {{ $t('system.labels.close') }}
            </label></button>
        </div>
        <layouts v-if="!activeElement && activeSub === 'layout'" :Fullscreen="full"></layouts>
        <gridSize v-if="!activeElement && activeSub === 'gridLines'"></gridSize>
        <borderSize v-if="!activeElement && activeSub === 'borderSize'"></borderSize>
        <Color v-if="!activeElement && activeSub === 'color'"></Color>
      </div>
      <div class="filters" v-if="activeSub === 'filters'">
        <div class="btn-row">
            <button :class="{ active: (activeSub === 'filters')}" @click="activeSub = 'filters', slideMenu = true" class="clean">
            <i class="fal fa-magic"></i>
            <label class="labelsmall">{{ $t('icons.filter') }}</label>
          </button>
          <button class="clean autoright nomargin" @click="slideMenu = false, full = false"><i class="far fa-times-circle"></i>
          <label class="labelsmall">
            {{ $t('system.labels.close') }}
          </label></button>
        </div>
        <imageFilters v-if="activeElement && activeElement.type === 'image' && activeSub === 'filters'"></imageFilters>
      </div>
      <div class="uploads" v-if="activeSub === 'uploads' || activeSub === 'quotes' || activeSub === 'patterns' || activeSub === 'photos'">
        <div class="btn-row">
          <button class="clean" :class="{ active: (activeSub === 'uploads')}" @click="activeSub = 'uploads'"><i class="fal fa-images"></i>
              <label class="labelsmall">{{ $t('icons.uploads') }}</label></button>
          <button class="clean" :class="{ active: (activeSub === 'quotes')}" @click="activeSub = 'quotes'"><i class="fal fa-quote-right"></i>
          <label class="labelsmall">{{ $t('icons.quotes') }}</label></button>
          <button class="clean" :class="{ active: (activeSub === 'patterns')}" @click="activeSub = 'patterns'"><i class="fal fa-stroopwafel"></i>
          <label class="labelsmall">{{ $t('icons.patterns') }}</label></button>
          <button class="clean" :class="{ active: (activeSub === 'photos')}" @click="activeSub = 'photos'"><i class="fal fa-book-spells"></i>
          <label class="labelsmall">{{ $t('icons.creative') }}</label></button>

          <button class="clean autoright" @click="full = true" v-if="full === false"><i class="fal fa-chevron-square-up"></i>
          <label class="labelsmall">{{ $t('icons.expand') }}</label></button>
          <button class="clean autoright" @click="full = false" v-if="full === true"><i class="fal fa-chevron-square-down"></i>
          <label class="labelsmall">{{ $t('icons.collapse') }}</label></button>

            <button class="clean nomargin" @click="slideMenu = false, full = false"><i class="far fa-times-circle"></i>
            <label class="labelsmall">
              {{ $t('system.labels.close') }}</label></button>
        </div>
        <uploadQuick v-if="activeSub === 'uploads'" :Fullscreen="full"></uploadQuick>
        <Quotes v-if="activeSub === 'quotes'" Menu="Quotes" :Fullscreen="full"></Quotes>
        <Quotes v-if="activeSub === 'patterns'" Menu="Patterns" :Fullscreen="full"></Quotes>
        <Quotes v-if="activeSub === 'photos'" Menu="Creative" :Fullscreen="full"></Quotes>
      </div>
    </div>
    <!-- Bottom menu -->
    <div class="BottomToolbar activeMenu" v-if="position === 'bottom'">
      <div class="row column-mobile" v-if="activeDesign">
        <div class="row menumobile" v-if="activeElement === null">
          <button class="clean" @click="toggleSidebar('Photos')" v-if="!sidebar && !activeElement"><i class="fal fa-images"></i>
              <label class="labelsmall">{{ $t('icons.photos') }}</label></button>
          <button class="clean" @click="toggleSidebar('Quotes')" v-if="!sidebar && !activeElement"><i class="fal fa-quote-right"></i>
              <label class="labelsmall">{{ $t('icons.quotes') }}</label></button>
          <button class="clean" :class="{ active: (activeSub === 'uploads')}" v-if="mobile" @click="activeSub = 'uploads'"><i class="fal fa-cloud"></i>
              <label class="labelsmall">{{ $t('icons.uploads') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'layout')}" v-if="!activeElement && mobile" @click="activeSub = 'layout'"><i class="fal fa-border-all"></i>
              <label class="labelsmall">{{ $t('icons.layout') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'gridLines')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'gridLines'"><i class="fal fa-border-inner"></i>
              <label class="labelsmall">{{ $t('icons.grid') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'borderSize')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'borderSize'"><i class="fal fa-border-outer"></i>
              <label class="labelsmall">{{ $t('icons.border') }}</label></button>
          <button class="clean" v-if="!activeElement && activeDesign.content[0].type !== 'grid'" @click="addText"><i class="fal fa-text"></i></button>
          <v-swatches v-if="!activeElement" v-model="activeDesign.content[0].background" :swatches="swatches" show-border popover-y="top" show-fallback fallback-input-type="color" :style="{ background: activeDesign.content[0].background, borderRadius: '8px' }">
            <button class="clean" slot="trigger" readonly><i class="fal fa-palette" :style="{ color: invertedBackground }"></i>
                <label class="labelsmall" :style="{ color: invertedBackground }">{{ $t('icons.color') }}</label></button>
          </v-swatches>
        </div>
          <div class="row" v-if="activeElement !== null && activeElement.type !== 'background'" style="min-height: 44px; padding-left: 20px;">
            <v-swatches v-if="activeElement.type === 'text'" v-model="activeElement.content.color" show-border :swatches="swatches" show-fallback fallback-input-type="color"></v-swatches>
            <!-- Remove -->
            <button class="clean" v-if="activeElement !== null" @click="onElementRemove"><i
                class="far fa-trash-alt"></i><label class="labelsmall">{{ $t('icons.delete') }}</label></button>
                <button class="clean" @click="toggleSidebar" v-if="activeElement && activeElement.type !== 'text' && mobile"><i
              class="fal fa-images"></i><label class="labelsmall">{{ $t('icons.photos') }}</label></button>
            <button class="clean" :content="$t('system.tooltips.upload')" v-tippy="{ animateFill: false, animation : 'shift-toward'}" :class="{ active: (activeSub === 'uploads')}" v-if="mobile && activeElement.type !== 'text' " @click="activeSub = 'uploads'"><i class="fal fa-cloud-upload"></i><label class="labelsmall">{{ $t('icons.uploads') }}</label></button>
            <!-- Position -->
            <button class="clean" title="Position" v-if="menu !== 'position' && activeDesign.content[0].type !== 'grid'" @click="menu = 'position'">
              <i
                class="fal fa-bring-front"></i><label>{{ menu }}</label></button>
            <button class="clean" v-if="menu == 'position' && activeDesign.content[0].type !== 'grid'" @click="shiftPosition('down')"><i
                class="fal fa-send-backward"></i><label></label>
            </button>
            <button class="clean" v-if="menu == 'position' && activeDesign.content[0].type !== 'grid'" @click="shiftPosition('up')"><i
                class="fal fa-bring-forward"></i><label></label></button>

            <button :class="{ active: (activeSub === 'filters')}" v-if="activeElement && activeElement.type === 'image' && mobile" @click="activeSub = 'filters'" class="clean">
              <i class="fal fa-magic"></i>
              <label class="labelsmall">{{ $t('icons.filter') }}</label>
            </button>
            <tippy
                interactive
                :animate-fill="false"
                placement="bottom"
                distant="7"
                theme="light"
                animation="fade"
                trigger="click"
                v-if="!mobile"
                arrow
                @click="activeSub = 'filters'"
                >
            <template v-slot:trigger>
            <button :class="{ active: (activeSub === 'filters')}" :content="$t('system.tooltips.filters')" v-tippy="{ animateFill: false, animation : 'shift-toward'}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'filters'" class="clean">
            <i class="fal fa-magic"></i>
              <label class="labelsmall">{{ $t('icons.filter') }}</label>
            </button>
            </template>
                <imageFilters></imageFilters>
            </tippy>
            <button :class="{ active: (activeSub === 'crop')}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'crop'" class="clean">
                <i class="fal fa-crop"></i>

              <label class="labelsmall">{{ $t('icons.crop') }}</label>
            </button>

            <imageCrop v-on:update:activeSub="activeSub = $event" v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>

            <div v-if="activeElement && activeElement.type === 'text'"
            style="display: flex; flex-direction: row; align-items: center; gap: 1px;">
            <button :class="{ active: (fontOption === 'edit')}" class="clean mobile-only" @click="fontOption = 'edit'"><i class="fal fa-keyboard"></i></button>
            <button :class="{ active: (fontOption === 'font')}" class="clean" @click="fontOption = 'font', optionsActive = true"><i class="fal fa-font"></i></button>
            <button :class="{ active: (fontOption === 'size')}" class="clean" v-if="mobile" @click="fontOption = 'size', optionsActive = true"><i class="fal fa-text-size"></i></button>
          </div>
        </div>
        <div class="row mobile-visible" v-if="mobile">
          <uploadQuick v-if="!activeElement && activeSub === 'uploads'"></uploadQuick>
          <uploadQuick v-if="!activeElement && activeSub === null"></uploadQuick>
          <layouts v-if="!activeElement && activeSub === 'layout'"></layouts>
          <uploadQuick v-if="activeElement && activeElement.type === 'image' && activeSub === 'uploads'"></uploadQuick>
          <uploadQuick v-if="activeElement && activeElement.type === 'image' && activeSub === null"></uploadQuick>
          <uploadQuick v-if="activeElement && activeElement.type === 'empty' && activeSub === null"></uploadQuick>
          <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="fontOption"></fontsQuick>
          <imageFilters v-if="activeElement && activeElement.type === 'image' && activeSub === 'filters'"></imageFilters>
          <gridSize v-if="!activeElement && activeSub === 'gridLines'"></gridSize>
          <borderSize v-if="!activeElement && activeSub === 'borderSize'"></borderSize>
        </div>
      </div>
    </div>
    <div class="options-menu" v-if="activeElement && activeElement.type != 'text' && position === 'bottom' && !mobile && layout">
      <button @click="activeSub = null">{{ $t('system.labels.done') }}</button>
      <imageFilters v-if="activeElement && activeElement.type === 'image' && activeSub === 'filters'"></imageFilters>
      <imageCrop v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>
    </div>
    <div class="options-menu" v-else-if="activeElement && activeElement.type === 'text' && position === 'bottom' && !mobile">
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'edit'"></fontsQuick>
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'size'"></fontsQuick>
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'font'"></fontsQuick>
    </div>
    <div class="options-menu" v-else-if="!activeElement && position === 'bottom' && activeSub === 'layout' && !mobile">
      <button @click="activeSub = null">Done</button>
      <layouts></layouts>
      <gridSize></gridSize>
      <borderSize></borderSize>
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
// import { mapState } from 'vuex'
import newStore from '@/store/newstore'
import elementsLoader from '@/helpers/elements'
import artboardCreator from '@/helpers/artboard-creator'
import previewCreator from '@/helpers/previewCreator'

import uploadQuick from './Components/uploads.vue'
import Quotes from './Components/quotes.vue'
import fontsQuick from './Components/fonts.vue'
import imageFilters from './Components/filters.vue'
import imageCrop from './Components/crop.vue'
import layouts from './Components/layouts.vue'
import gridSize from './Components/gridsize.vue'
import borderSize from './Components/bordersize.vue'
import Color from './Components/color.vue'

// import gridCreator from '@/helpers/GridCreator'

function invertColor (hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  var r = parseInt(hex.slice(0, 2), 16)
  var g = parseInt(hex.slice(2, 4), 16)
  var b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b)
}
function padZero (str, len) {
  len = len || 2
  var zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export default {
  components: {
    VSwatches, uploadQuick, fontsQuick, imageFilters, layouts, gridSize, borderSize, imageCrop, Quotes, Color
  },
  props: {
    position: String
  },
  data: function () {
    return {
      iframe: false,
      options: {
        name: 'Artboard'
      },
      slideMenu: false,
      full: false,
      layout: false,
      fontOption: 'edit',
      activeSub: 'layout',
      optionsActive: false,
      gridlines: false,
      filters: false,
      fontFamily: 'Roboto',
      fontSize: '24px',
      swatches: [
        ['#FFFFFF', '#000000'],
        ['#F493A7',
          '#9196f8',
          '#FFCCD5',
          '#262A53',
          '#FFA0A0',
          '#FFE3E3',
          '#053742',
          '#39A2DB',
          '#A2DBFA',
          '#E8F0F2',
          '#0A1931',
          '#185ADB',
          '#FFC947',
          '#EFEFEF',
          '#628395']
      ],
      shapeColor: '#000000',
      menu: null,
      loaded: false
    }
  },
  methods: {
    overlayActive (value) {
      newStore.commit('overlayActive', value)
    },
    clearDesign () {
      this.$confirm(
        {
          message: 'Are you sure? This will remove your progress',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              // ... do something
              if (this.activeDesign.content[0].type !== 'grid') {
                this.activeDesign.content[0].items = []
                newStore.commit('activeDesign', this.activeDesign)
              } else {
                // Remember grid and make new with empty...
              }
            }
          }
        }
      )
    },
    updateContentDimensions () {
      artboardCreator.methods.updateDimensions()
    },
    closeFrame () {
      previewCreator.create('session_preview')
      const interval = setInterval(function () {
        if (newStore.state.preview !== null) {
          window.parent.postMessage(
            {
              event_id: 'preview',
              title: newStore.state.activeDesign.title,
              preview: newStore.state.preview.canvasBlob ? newStore.state.preview.canvasBlob : ''
            },
            '*'
          )
          window.parent.postMessage('closing the frame', '*')
          clearInterval(interval)
        }
      }, 250)
    },
    addText () {
      elementsLoader.methods.addText()
    },
    editText () {
      this.activeElement.editable = true
    },
    toggleSidebar (title) {
      newStore.commit('sidebar', true)
      newStore.commit('overrideMenu', title)
      //  this.$bus.$emit('new-user-question', this.newUserQuestion)
      // this.$bus.$emit('override-menu', 'Quotes')
      // this.$emit('overrideMenu', 'Quotes')
      // this.sidebar = !this.sidebar
    },
    changeMode () {
      this.activeDesign.content[0].type = (this.activeDesign.content[0].type === 'Grid') ? 'Free' : 'Grid'
      this.activeGrid.opacity = 1
    },
    changeGrid (value) {
      this.activeDesign.content[0].gridSize = value
    },
    convertFile () {
      console.log('test')
    },
    callUndo () {
      newStore.dispatch('undoDesign')
    },
    callRedo () {
      newStore.dispatch('redoDesign')
    },
    changeSize () {
      this.activeElement.width = 400
    },
    font (i) {
      const increase = Number(this.activeElement.content.fontSize) + i
      this.activeElement.obj.fontSize = increase
    },
    textEdit () {
      this.activeElement.editable = true
    },
    photoEffect () {
      this.$store.commit('checkFilters')
    },
    editable () {
      this.activeElement.editable = !this.activeElement.editable
    },
    swapArrayElements (arr, indexA, indexB) {
      const temp = arr[indexA]
      arr[indexA] = arr[indexB]
      arr[indexB] = temp
      return arr
    },
    shiftPosition (direction) {
      if (this.activeDesign.content[0].items.length > 1) {
        const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
        this.changePosition(currPos, direction)
      }
      // if (this.activeDesign.content[0].items.indexOf(this.activeElement) > 1 && !i) {
      //   const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
      //   this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos - 1))
      //   // this.$store.commit('changeOrderElement', this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos - 1)))
      // }
      // if (this.activeDesign.content[0].items.indexOf(this.activeElement) >= 1 && i) {
      //   const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
      //   if (this.activeDesign.content[0].items.length !== (currPos + 1)) {
      //     this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos + 1))
      //     // this.$store.commit('changeOrderElement', this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos + 1)))
      //   }
      // }
      // newStore.commit('activeDesign', this.activeDesign)
    },
    changePosition (currentIndex, direction) {
      var array = this.activeDesign.content[0].items
      let newPosition
      let mode = 'move'
      switch (direction) {
        case 'up':
          newPosition = currentIndex + 1
          break
        case 'down':
          newPosition = currentIndex - 1
          break
        case 'top':
          newPosition = array.length - 1 // calculate the end of the line
          break
        case 'bottom':
          newPosition = 0 // put it all the way to the start
          break
        case isNaN(direction) || direction:
          // console.log('number')
          mode = 'swap'
          newPosition = direction
          break
        default:
          newPosition = currentIndex + 1
      }

      // end reference is the index + 1 to splice it
      var currentIndexEnd = currentIndex + 1
      // reference the object to change
      var slice = array.slice(currentIndex, currentIndexEnd)[0]

      if (mode === 'move') {
        // remove the item from the list
        array.splice(currentIndex, 1)
        // put the item back in the list up or down
        array.splice(newPosition, 0, slice)
        // log the things
        // console.log(slice) // this is the reference object - can be removed after.
        // console.log(array) // this is the new array list
      }

      if (mode === 'swap') {
        // get old content - position, dimension
        var oldSlice = array.slice(currentIndex, currentIndexEnd)[0].text // content
        var newSlice = array.slice(newPosition, newPosition + 1)[0] // object

        // Content from the new position
        slice.text = newSlice.text

        // set old data into the old new position
        newSlice.text = oldSlice

        console.log(array) // this is the new array list
      }
      // newStore.state.activeDesign.content[0].items = array

      // TODO Dispatch new Design at the end of the run.
      newStore.commit('activeDesign', this.activeDesign)
    },
    lock () {
      this.$store.commit('lockElement')
    },
    unlock () {
      this.$store.commit('unlockElement')
    },
    removeArtBoard () {
      this.$store.commit('activeArtBoard', null)
    },
    onElementRemove (element) {
      // this.$store.commit('removeElementToArtBoard', this.activeElement)
      // this.$store.commit('setActivateElement', null)
      // const i = this.activeDesign.content[0].items.indexOf(element)
      // this.activeDesign.content[0].items.splice(i, 1)
      if (this.activeDesign.content[0].type !== 'grid') {
        this.activeDesign.content[0].items.splice(this.activeDesign.content[0].items.indexOf(newStore.state.activeElement), 1)
      } else {
        this.activeElement.type = 'empty'
        this.activeElement.content = {}
      }
      newStore.state.activeElement = null
    }
  },
  mounted () {
    if (!this.mobile && this.position === 'bottom') {
      this.activeSub = null
      // window.addEventListener('keypress', function (e) {
      //   self.activeElement.content.editable = true
      // })
    }
    if (window.location !== window.parent.location) {
      this.iframe = true
    }
  },
  watch: {
    activeElement (state, old) {
      // newStore.commit('activeDesign', this.activeDesign)
      // this.$store.commit('activeArtBoard', this.activeDesign)
    }
  },
  computed: {
    mobile () {
      return newStore.state.mobile
    },
    sidebar () {
      return newStore.state.sidebar
    },
    user (state) {
      return newStore.state.user
    },
    userType (state) {
      return newStore.state.userType
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    activeElement () {
      const self = this
      if (newStore.state.activeElement !== null) {
        // self.activeSub = null
        self.optionsActive = false
      }
      return newStore.state.activeElement
    },
    color () {
      let color = 'white'
      if (newStore.state.activeDesign) {
        color = newStore.state.activeDesign.content[0].background
      }
      return color
    },
    activeGrid () {
      return newStore.state.activeGrid
    },
    invertedBackground () {
      const colorToInvert = newStore.state.activeDesign.content[0].background
      const inverted = invertColor(colorToInvert, true)
      return inverted
    }

    // mapState({
    //   activeElement (state) {
    //     return state.artBoardStore.activeElement
    //   },
    //   activeDesign (state) {
    //     return state.artBoardStore.artBoards
    //   },
    //   activeProduct (state) {
    //     return state.productsStore.product
    //   },
    //   color () {
    //     return this.activeDesign && this.activeDesign.content[0].background
    //   }
    // })
  }
}
</script>

<style lang="scss" scoped>
button {
  position: relative;
  i {
    color: black;
  }
  &.active i {
    color: #157CFD;
  }
}
.labelsmall {
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translate(-50%, 0%);
  font-size: 10px;
  color: black;
  opacity: 0.6;
}

.toolbar-ctn {
  position: fixed;
  box-sizing: border-box;
  &.top {
    top: 0px;
    left:0;
    z-index: 1;
  }
  &.bottom {
    bottom: 20px;
    z-index: 2;
    button {
      top: -6px;
    }
  }
}
.slide-menu {
  display: none;
}
@media only screen and (max-width: 760px){
  .slide-menu {
    position: fixed;
    display: block;
    background: white;
    border-radius: 20px 20px 0 0;
    width: 100%;
    min-height: 250px;
    height: 100%;
    box-sizing: border-box;
    bottom: -100%;
    height: auto;
    transition: all 350ms ease-in-out;
    z-index: 99;
    i {
      font-size: 18px;
    }
    &.active {
      bottom: 0;
    }
    &.layout {
      min-height: 350px;
    }
    &.full {
      min-height: 100%;
    }
    .btn-row {
      margin: 20px;
      margin-bottom:0px;
      display: flex;
      flex-direction: row;
      border-bottom:1px solid rgba(0,0,0,0.1);
      padding-bottom: 5px;
      button {
        margin-right: 10px;
        &.nomargin {
          margin-right:0px;
        }
        &.autoright {
          margin-left: auto;
        }
      }
    }
  }
}

.main-artboard-menu {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  button {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 761px){
  .main-artboard-menu {
    text-align: left;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}
.activeMenu {
  display: none;
}
.slide-content {
  height: 200px;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  padding:0 10px;
  box-sizing: border-box;
    align-items: center;

  .scroller {
    overflow-x: scroll;
    -webkit-overflow-scrolling: auto;
  }

  .scroller::-webkit-scrollbar {
    display: none;
  }

  .leftLine {
  }
}

.font-picker {
  display: none !important;
  position: inherit !important;
  width: 240px !important;
  display: flex !important;
  align-items: center;
}

.font-picker ul {
  width: 240px !important;
}

.font-picker ul.expanded {
  max-height: 200px !important;
  margin-top: -260px !important;
  width: 240px !important;
  border-radius: 16px !important;
  background: white !important;
}

.vue-swatches__trigger__wrapper {
  display: inherit !important;
}
.mobile-visible {
  display: flex;
  bottom: 0px;
}

.column-mobile {
  flex-direction: column;
}
.mobile-only {
  display: none;
}

@media only screen and (max-width: 760px){
}
</style>
