<template>
  <div v-if="panel" class="panel">
    <!-- <button v-if='!activeUser' @click='login()'>Login</button> -->
    <!-- <button v-if='activeUser' @click='logout()'>Logout</button> -->
    <!-- <button @click="logInUser()">Shopify Login</button> -->
    {{ userType }}
    {{ activeUser }}
    <div v-if="activeUser || activeUser !== 'guest_user'" @click="logout">Uitloggen</div>
    <div @click="closePanel">Sluiten</div>
    <div v-if="!activeUser || activeUser === 'guest_user'">
      <input v-model="email" placeholder="username" type="email" />
      <input v-model="password" placeholder="password"  type="password" />
      <button @click="login" class="cta">Submit</button>
    </div>

    <div v-if="userType === 'firebase'">
      <input v-model="user_id" placeholder="User Id" /><br>
      <input v-model="session_id" placeholder="Session ID" /><br>
      <button @click="loadSession" class="cta">Submit</button>
      <button @click="saveSession" class="cta">Save session</button>
    </div>
    <!-- <button @click='upload()'>Upload</button>
    <button @click='updateImage()'>Update</button> -->
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import newStore from '@/store/newstore'
import atob from 'atob'
import apiService from '@/api/endpoints'

export default {
  data () {
    return {
      email: null,
      password: null,
      documents: null,
      promise: null,
      user: null,
      user_id: null,
      session_id: null
    }
  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        if (ip === '178.85.203.56' || ip === '86.89.100.214' || ip === '213.93.205.189' || ip === '212.187.18.219' || ip === '185.50.208.154' || ip === '188.120.45.75') {
          newStore.commit('showSettings', true)
        }
      })
  },
  mounted () {
    var self = this
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        newStore.state.user = user.email
        newStore.state.userType = user.providerId
        console.log(user)
        self.getDetails()
      } else {
        // newStore.commit('setUser', null)
      }
    })
  },
  methods: {
    loadSession () {
      const payload = {
        id: this.session_id,
        type: this.user_id ? 'user' : 'guest_user',
        sender: 'cart',
        user: this.user_id
      }
      newStore.dispatch('loadDesign', payload)
    },
    saveSession () {
      const payload = {
        id: this.session_id,
        type: 'user',
        sender: 'cart',
        user: this.user_id,
        session: this.activeDesign
      }
      newStore.dispatch('saveDesignIw', payload)
    },
    logInUser () {
      const self = this
      const mutation = `mutation customerAccessTokenCreate {
        customerAccessTokenCreate(input: {
          email: "${this.email}",
          password: "${this.password}"
        }) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }`
      this.promise = this.apiCall(mutation, 'user').then(function (data) {
        if (data.data) {
          // console.log(data.data.customerAccessTokenCreate.customerAccessToken.accessToken)
          const token = data.data.customerAccessTokenCreate.customerAccessToken.accessToken
          self.getUserShopify(token)
        }
      })
    },
    getUserShopify (token) {
      const self = this
      const query = `query {
        customer(customerAccessToken: "${token}"){
          id
          email
        }
      }`
      this.apiCall(query).then(function (data) {
        if (data.data) {
          self.user = data.data.customer
          self.user.id = atob(self.user.id).split('/').pop()
          self.verifyUser()
        }
      })
    },
    verifyUser () {
      apiService.verifyLogin({
        data: {
          id: this.user.id,
          email: this.user.email
        }
      }).then((res) => {
        if (res.data.code === '200') {
          console.log(res)
          // window.open(res.data.data.preview)
        } else {
          // Optopn
        }
      })
    },
    apiCall (query, type) {
      return fetch('https://instawallprints.myshopify.com/api/graphql.json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': '4191bce3a7438deeb6012eaa1a142f75'
          },
          body: query
        })
        .then(response => response.json())
    },
    upload () {
      newStore.dispatch('uploadImages', {
        cdnUrl: 'https://ucarecdn.com/49a88297-76e0-4c3b-a569-3513c281f09a/', thumbnail: 'https://ucarecdn.com/49a88297-76e0-4c3b-a569-3513c281f09a/DSC06595_NaNXP0WGm09Z.jpeg', name: 'DSC06595_NaNXP0WGm09Z.jpeg', uploadCareID: 'b82560b6-d914-4308-85e6-2fec50cbcf23', sourceInfo: { source: 'local', file: {} }, userID: 'tCZGGyVetoYk0eh2BzVjc5BzLr92', uuid: '49a88297-76e0-4c3b-a569-3513c281f09a', cdnUrlModifiers: null, originalImageInfo: { dpi: null, width: 375, orientation: null, format: 'JPEG', sequence: false, height: 472, datetime_original: null, geo_location: null, color_mode: 'RGB' }, originalUrl: 'https://ucarecdn.com/49a88297-76e0-4c3b-a569-3513c281f09a/', size: 13194, mimeType: 'image/jpeg', isImage: true, isStored: true, newUser: this.activeUser.uid
      })
    },
    updateImage () {
      newStore.dispatch('updateImage', {
        cdnUrl: 'https://ucarecdn.com/4976e4c3b569-3513c281f09a/', thumbnail: 'https://ucarecdn.com/49a88297-76e0-4c3b-a569-3513c281f09a/DSC06595_NaNXP0WGm09Z.jpeg', name: 'DSC06595_NaNXP0WGm09Z.jpeg', uploadCareID: 'b82560b6-d914-4308-85e6-2fec50cbcf22', sourceInfo: { source: 'local', file: {} }, userID: 'tCZGGyVetoYk0eh2BzVjc5BzLr92', uuid: '49a88297-76e0-4c3b-a569-3513c281f09a', cdnUrlModifiers: null, originalImageInfo: { dpi: null, width: 375, orientation: null, format: 'JPEG', sequence: false, height: 472, datetime_original: null, geo_location: null, color_mode: 'RGB' }, originalUrl: 'https://ucarecdn.com/49a88297-76e0-4c3b-a569-3513c281f09a/', size: 13194, mimeType: 'image/jpeg', isImage: true, isStored: true
      })
    },
    save () {
      newStore.dispatch('updateDesign', this.activeDesign)
    },
    getDetails () {
      // newStore.dispatch('getUserDetails', this.activeUser.uid)
      // newStore.dispatch('bindDesigns', this.activeUser.uid)
      // newStore.dispatch('bindImages', this.activeUser.uid)
      // db.collection('User/' + this.activeUser.uid + '/MyDesign').where('userID', '==', this.activeUser.uid).get()
      //   .then(querySnapshot => {
      //     const documents = querySnapshot.docs.map(doc => doc.data())
      //     // do something with documents
      //     this.documents = documents
      //     console.log(documents)
      //   })
      // db.collection('User').where('userID', '==', this.activeUser.uid).get()
      //   .then(querySnapshot => {
      //     const documents = querySnapshot.docs.map(doc => doc.data())
      //     // do something with documents
      //     this.documents = documents
      //     console.log(documents)
      //   })
    },
    logout () {
      newStore.state.userType = ''
      newStore.dispatch('logout', null)
      firebase.auth().signOut()
    },
    closePanel () {
      newStore.commit('showPanel', false)
    },
    login () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((e) => {
          // alert('Successfully logged in')
          // this.$router.push('/dashboard');
          // this.$emit('clicked', e.user.uid)
          // newStore.state.user = e.user
          // newStore.commit('setUser', e.user)
          // this.$emit('userID', e.user.uid)
          newStore.state.userType = e.user.providerId
          console.log(e)
        })
        .catch(error => {
          alert(error.message)
        })
    }
  },
  computed: {
    activeUser () {
      return newStore.state.user
    },
    userType () {
      return newStore.state.userType
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    userDesigns () {
      return newStore.state.designs
    },
    userImages () {
      return newStore.state.images
    },
    settings () {
      return newStore.state.settings
    },
    panel () {
      return newStore.state.panel
    }
  }
}
</script>

<style>
.panel {
  width: 500px;
  height: 90%;
  background: white;
  overflow-y: scroll;
  position: fixed;
  z-index: 99999999;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  padding: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}
</style>
