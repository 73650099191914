<template>
  <div style="position: absolute; pointer-events: none;">
    <div id="container" ref="parent" class="container artboard-shadow" v-bind:style="{ width: w + 'px', height: h + 'px',transform:  'scale('+ scale +')', backgroundColor: activeDesign.content[0].background, pointerEvents: 'initial' }">
    <div class="artboard-empty" v-if="activeDesign.content[0].items.length === 0"></div>
    <!-- {{ overlappingId }} -->
    <vue-draggable-resizable
        :handle-info="{size: 14,offset: 0,switch: true}"
        :debug="false"
        :scale-ratio="scale"
        :isConflictCheck="false"
        class-name-active="my-active-class"
        class-name-handle="my-handle-class"
        v-bind:class="element.type"
        :disable-user-select="true"
        :scale="scale"
        :active.sync="element.active"
        :prevent-deactivation="element.deactivation"
        :lock-aspect-ratio="element.ratio"
        :parent="(element.type == 'text') ? false : element.parent"
        v-for="(element, index) in activeDesign.content[0].items"
        :key="element.id"
        :resizable="(element.type == 'text') ? false : true"
        :draggable="(element.editable == true) ? false : element.draggable"
        :x="element.x"
        :y="element.y"
        :z-index="index"
        :h="element.height"
        :w="(element.type == 'text') ? 'auto' : element.width"
        v-bind:style="{ tranform: 'translate('+ element.x +'px, '+ element.y +'px)', zIndex: ( element.active === true ) ? element.z : element.z }"
        @dragging="(left, top) => dragging(element, left, top)"
        @dragstop="(left, top) => dragStop(element, left, top)"
        @resizing="(left, top, width, height) => onResize(element, left, top, width, height)"
        @resizestop="(left, top, width, height) => onResizeStop(element, left, top, width, height)"
        @activated="() => onActivated(element)"
        :data-page-number="element.id"
        :snap="(activeDesign.type == 'Grid') ? false : true"
        :snap-tolerance="20"
        @refLineParams="getRefLineParams"
      >
        <template>
          <div v-if="element.type === 'image'" class="image-ctn" :style="{ border: activeDesign.content[0].gridSize + 'px solid ' + activeDesign.content[0].background, boxSizing: 'border-box', justifyContent: `${element.content.crop ? 'flex-start' : 'center' }`, alignItems: `${element.content.crop ? 'flex-start' : 'center' }`, width: '100%', height: '100%'}" >
            <img
              v-if="element.content.thumbnail != 'undefined' && element.content.thumbnail != null"
              :src="element.content.thumbnail | filterImg"
              :width="element | imageProps('width')"
              :height="element | imageProps('height')"
              :style="{transform: `translate(${element.content.crop ? element.content.crop.px : 0 }%, ${element.content.crop ? element.content.crop.py : 0 }%) scale(${element.content.crop ? element.content.crop.scaleX : 1 })`, transformOrigin: 'left top'}"
            />
          </div>
          <div v-if="element.type === 'shape'" v-html="element.content.shape" class="shape-ctn" ></div>
          <div v-if="element.type === 'empty'" class="empty-ctn" :style="{ border: element.stroke + 'px solid white', background: element.fill }"></div>
          <div v-if="element.type === 'text'" class="text-ctn" :style="{fontFamily: element.content.fontFamily, fontSize: element.content.fontSize + 'px', fontWeight: element.content.fontWeight, lineHeight: 'normal', color: element.content.color, textAlign: element.content.textAlign }">
            <VueContenteditableInput v-if="element.editable" tag="div" :id="element.id" style="border: none;" placeholder="Text" :contenteditable="true" v-model="element.content.txt" :disable-newline="true" :autofocus="true" />
            <div v-if="!element.editable">{{ element.content.txt !== '' ? element.content.txt : 'Text' }} </div>
          </div>
          <div v-if="element.type === 'background'" class="bg" v-bind:style="{ backgroundColor: element.color }">
              <div class="bg-item"></div>
          </div>
        </template>
      </vue-draggable-resizable>

      <vue-draggable-resizable style="pointer-events: none;" :z="0" :w="activeDesign.content[0].sizes.w" :h="activeDesign.content[0].sizes.h" :disable-user-select="true" :draggable="false" :resizable="false" @refLineParams="getRefLineParams" :snap="true">
      </vue-draggable-resizable>
      <span class="ref-line v-line"
          v-for="item in vLine"
          :key="item.id"
          v-show="item.display"
          :style="{ left: item.position, top: item.origin, height: item.lineLength}"
      />
      <span class="ref-line h-line"
          v-for="item in hLine"
          :key="item.id"
          v-show="item.display"
          :style="{ top: item.position, left: item.origin, width: item.lineLength}"
      />
      <div @click="deactivation()" style="width: 100%; height: 100%;"></div>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable-gorkys'
import VueContenteditableInput from 'vue-contenteditable-input'

import 'vue-context/dist/css/vue-context.css'
import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css'
import imageVue from '../../../../components/elements/image.vue'
import Text from '../../../../components/elements/text.vue'
import fontLoader from '@/helpers/FontLoader'
import newStore from '@/store/newstore'

export default {
  components: {
    VueDraggableResizable, VueContenteditableInput
  },
  props: {
    scale: Number,
    w: Number,
    h: Number,
    parentData: Array,
    pageNumber: null,
    artboardWidth: Number,
    event: Event
  },
  data () {
    return {
      artboardWidthD: this.artboardWidth,
      parentPageNumber: null,
      example: {
        shape: imageVue,
        image: Text
      },
      grid: [],
      gridOpacity: 0,
      sync: false,
      draggingId: null,
      startPosX: null,
      startPosY: null,
      startPosZ: null,
      activeStartPosZ: 999,
      prevOffsetX: 0,
      prevOffsetY: 0,
      overlappingId: '',
      vLine: [],
      hLine: []
    }
  },
  filters: {
    filterImg: function (value) {
      return value
    },
    imageProps: function (element, type) {
      // (element.content.object.originalImageInfo.width >= element.content.object.originalImageInfo.height) ? 'auto' : '100%'
      const cropActive = element.content.crop.px ? 1 : 0
      const ratioContainer = element.width / element.height
      const imageRatio = element.content.object.originalImageInfo.width / element.content.object.originalImageInfo.height

      const containerType = ratioContainer <= 1 ? 'porSquare' : 'landscape'
      const imageType = imageRatio <= 1 ? 'porSquare' : 'landscape'
      // console.log(ratioContainer, imageRatio, containerType, imageType, cropActive)
      let width = '100%'
      let height = 'auto'

      if (containerType === 'landscape') {
        width = 'auto'
        height = '100%'
        if (imageType === 'landscape' && imageRatio > ratioContainer && !cropActive) {
          width = 'auto'
          height = '100%'
        } else if (imageType === 'landscape' && imageRatio < ratioContainer && !cropActive) {
          width = '100%'
          height = 'auto'
        } else if (imageType === 'porSquare') {
          width = '100%'
          height = 'auto'
        }
      }

      if (containerType === 'porSquare') {
        width = 'auto'
        height = '100%'
        if (imageType === 'porSquare' && imageRatio > ratioContainer && !cropActive) {
          width = 'auto'
          height = '100%'
        } else if (imageType === 'porSquare' && imageRatio < ratioContainer && !cropActive) {
          width = '100%'
          height = 'auto'
        } else if (imageType === 'porSquare') {
          width = 'auto'
          height = '100%'
        }
      }

      if (type === 'width') {
        return width
      } else {
        return height
      }
    }
  },
  created () {
    const html5 = document.querySelector('.text-item')
    if (html5) {
      html5.setAttribute('placeholder', 'Your placeholder here')
    }
    this.activeDesign.content[0].items.forEach(item => {
      item.active = false
      if (item.type === 'text') {
        if (!this.fontsLoaded.includes(item.content.fontFamily)) {
          fontLoader.methods.load(item.content.fontFamily)
        }
        this.addFont(item.content.fontFamily)
      }
    })
  },
  methods: {
    deactivation () {
      this.activeDesign.content[0].items.forEach(item => {
        item.deactivation = false
        item.editable = false
        item.active = false
      })
      if (this.activeElement !== null) {
        newStore.dispatch('activeElement', null)
      }
    },
    dragStart (el) {
    },
    addFont (name) {
      if (!this.fontsLoaded.includes(name)) {
        fontLoader.methods.load(name)
        newStore.commit('addFontLoaded', name)
      }
    },
    getRefLineParams (params) {
      const { vLine, hLine } = params
      this.vLine = vLine
      this.hLine = hLine
    },
    onActivated (element) {
      newStore.dispatch('activeElement', element)

      this.activeDesign.content[0].items.forEach(item => {
        item.deactivation = false
        item.active = false
        item.parent = true
        if (element.id === item.id && element.type !== 'background') {
          item.deactivation = true
          item.active = true
        }
      })
    },
    dragging (el, left, top) {
      el.x = left
      el.y = top
      this.draggingId = el
    },
    resizing (id, width, height) {
      this.activeDesign.content[0].items[id].width = width
      this.activeDesign.content[0].items[id].height = height
      this.draggingId = null
      this.startPosX = null
      this.startPosY = null
    },
    onResize (el, x, y, width, height) {
      this.resizing = true
      el.width = width
      el.height = height
      el.x = x
      el.y = y
    },
    onResizeStop (el, x, y, width, height) {
      this.save()
    },
    onElementRemove (element) {
      this.$store.commit('removeElementToArtBoard', element)
    },
    dragStop (el, left, top) {
      this.save()
    },
    save () {
      newStore.commit('changeActiveDesign', this.activeDesign)
    },
    deltaX (offsetX) {
      const ret = offsetX - this.prevOffsetX
      this.prevOffsetX = offsetX
      return ret
    },
    deltaY (offsetY) {
      const ret = offsetY - this.prevOffsetY
      this.prevOffsetY = offsetY
      return ret
    }
  },
  computed: {
    fontsLoaded () {
      return newStore.state.fontList
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    activateElement () {
      return newStore.state.activeElement
    }
  }
}
</script>

<style lang="scss">

.draggable {
  border: none;
}
.dragging {
  pointer-events: none; /* ignore for document.elementFromPoint() */
}

.image-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   -ms-user-select: none;
   user-select: none;
  &:empty {
    background: url('/img/placeholder.png');
  }
}
.text-item[contenteditable=true]:empty:before {
  content: attr(placeholder);
  width: inherit;
  height: inherit;
  color: grey;
  display: block;
}

.text-item {
}

.artboard-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.artboard-shadow {

   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.image-ctn img {
}

.shape-ctn {
  width: 100%;
  height: auto;
}

.empty-ctn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.shape-ctn svg{
  width: 100%;
  height: auto;
}

.text-ctn {
  width: 100%;
  height:  100%;
  white-space:nowrap;
  .text-item {
    width: 100%;
    height:  100%;
    white-space:nowrap;
    p {
      margin: 0;
      font-variant: normal;
      white-space:nowrap;
    }
  }
}

.vdr {
  padding: 0!important;
  border: none;
  display: flex;
  /* border: inset 5px transparent; */
}

.vdr.landscape img{
  border: none;
  width: 100%;
  height: auto;
}
.vdr.portrait img{
  border: none;
  height: 100%;
  width: auto;
}
.btn-fixed {
  position: absolute;
  top: 0;
  left: 0;
}

.my-active-class:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 2px solid #0091FF;
}

// .my-active-class.text:before {
//   border: none;
// }

.my-handle-class {
  background-color: #0091FF;
  z-index:  999999;
  position: fixed;
}

.my-active-class .handle {
  position: fixed;
  z-index: 1000;
  border-radius: 99px;
  background: royalblue !important;
}

.Instawall.gridSmall {
  .vdr.image {
    border: 2px solid transparent;
  }
}

.Instawall.gridLarge {
  .vdr.image {
    border: 5px solid transparent;
  }
}

</style>
