<template>
  <div :class="(type !== 'collectionPhotos' ? 'large-container' : 'small-container')">
    <uploadCare
        :multipleMax="multipleMax"
        :multiple="multiple"
        :tabs="dataTabs"
        :crop="'disabled'"
        :imagesOnly="true"
        dataSystemDialog="false"
        :publicKey="(type === 'collectionPhotos') ? '51b80f634c000b0512eb' : '50768dea87907fecea30'"
        dataMinWidth="1920"
        dataMinHeight="1920"
        @success="onSuccess"
        @click="$postEvent('iframeClickEvent', 'Upload Fotos')"
        :class="(type !== 'collectionPhotos' ? 'large-ctn' : 'small-ctn')">
      <button v-if="type !== 'collectionPhotos'">{{ $t('system.upload') }}</button>
      <button v-if="type === 'collectionPhotos'"><i class="fal fa-upload"></i></button>
    </uploadCare>
  </div>
</template>

<script>
import uploadCare from 'uploadcare-vue'
// import apiService from '@/api/endpoints'
// import elementsLoader from '@/helpers/elements'
import { Timestamp } from '@/api/db'
import newStore from '@/store/newstore'

export default {
  components: {
    uploadCare
  },
  data () {
    return {
      dataTabs: 'file instagram gphotos facebook dropbox'
    }
  },
  props: {
    multipleMax: null,
    multiple: null,
    buttonName: null,
    type: null,
    collectionID: null
  },
  methods: {
    onSuccess ($event) {
      $event.thumbnail = $event.originalUrl + $event.name
      var payload = {
        date: Timestamp.fromDate(new Date()),
        cdnUrl: $event.cdnUrl,
        isImage: $event.isImage,
        isStored: $event.isStored,
        mimeType: $event.mimeType,
        originalUrl: $event.originalUrl,
        thumbnail: $event.thumbnail,
        originalImageInfo: $event.originalImageInfo,
        uuid: $event.uuid
      }
      if (this.type === 'collectionPhotos') {
        payload.collectionID = this.collectionID
        newStore.dispatch('uploadCollectionImages', payload)
      } else {
        newStore.dispatch('uploadImages', payload)
        // elementsLoader.methods.addImage(payload)
      }

      // if (this.type === 'photos') {
      //   if (this.user) {
      //     $event.userID = this.user.id
      //     this.$store.dispatch('uploadUserImageToServer', $event)
      //   } else {
      //     this.$store.dispatch('uploadGuestImageToServer', $event)
      //   }
      // } if (this.type === 'collectionPhotos') {
      //   const req = {
      //     collectionID: this.collectionID,
      //     images: [$event]
      //   }
      //   this.$store.commit('setOverlayLoader', true, { root: true })
      //   apiService.uploadCollectionImages(req).then((res) => {
      //     this.$store.dispatch('getMenuList')
      //   })
      // } else {
      //   this.$emit('onsuccess', $event.cdnUrl)
      // }
    }
  },
  computed: {
    user () {
      return this.$store.state.userStore.user
    }
  }
}
</script>

<style lang="scss">

.large-container {
  .large-ctn {
    width: 100%;
    height: auto;
    min-height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    button {
      background: #157CFD;
      border-radius: 8px;
      width: 100%;
      border: none;
      font-size: 0.8em;
      color: white;
      padding: 12px 0;
    }
  }
}
.small-container {
  display: inline-block!important;
  .small-ctn {
    width: 100%;
    button {
      background: #157CFD;
      border-radius: 8px;
      color: white;
    }
  }
}
</style>
