<template>
  <div id="app" class="app" v-cloak>
    <vue-confirm-dialog class="confirm-dialog dialog-iw"></vue-confirm-dialog>
    <!-- <OverLay v-if="isOverLayPopup"></OverLay> -->
    <router-view name="dialog"></router-view>
    <router-view name="common"></router-view>
    <div class="overlay" v-bind:class="{ active: false }">
      <div class="content">
        <div class="menu">
          <router-link to="/">Home</router-link>
          <router-link to="/products">Products</router-link>
          <router-link to="/support">Support</router-link>
        </div>
          <div v-on:click="close">close</div>
        <router-view></router-view>
      </div>
    </div>
    <Profile></Profile>
    <Canvas v-if="iframe" />
    <div v-if="!iframe">OH MY GOD, you got here. Nice, apply for a job jobs@instawall.nl as a techie or continue to our website: Instawallprints.com to shop some products.</div>
    <div class="previewGenerator">
      <img v-if="this.preview" :src="this.preview.canvasBlob" width="750" height="auto">
      <div id="previewContainerVue" class="invisible-content"></div>
    </div>
  </div>
</template>

<script>
// Import the Canvas to keep in the back
import Canvas from '@/views/Canvas/'
// import OverLay from '@/components/common/overlay'
import newStore from '@/store/newstore'
// import ShopifyHelper from '@/helpers/shopify.js'
import ArtboardCreator from '@/helpers/artboard-creator.js'
// import store, { Overlay } from '@/store/interface/'
// const shopify = new ShopifyHelper()

import Profile from '@/views/account/profile/login.vue'

export default {
  name: 'Home',
  components: {
    Canvas, Profile
    // OverLay
  },
  data: function () {
    return {
      iframe: true
    }
  },
  watch: {
    loader (flag) {
      this.$loading(flag)
    }
  },
  mounted () {
    if (window.location !== window.parent.location) {
      this.iframe = true
    }
    if (this.iframe) {
      ArtboardCreator.init()
    }
  },
  methods: {
    close () {
      // Overlay.toggle()
    },
    getProductList () {
      // shopify.getAll()
      // shopify.getProducts(this.$store)
    },
    initCart () {
      // shopify.addToCart()
    }
  },
  created () {
    // this.getProductList()
  },
  computed: {
    preview () {
      return newStore.state.preview
    }
    // overlayState () {
    //   return store.overlay
    // },
    // isOverLayPopup () {
    //   return this.$store.state.toggleStore.modalComponent
    // },
    // loader () {
    //   return this.$store.state.loaderStore.overLayLoader
    // }
  }
}
</script>

<style lang="scss">
.tippy-popper {
  font-family: Poppins;
  font-size: 12px !important;
}
.tippy-tooltip {
  font-size: 0.6rem;
}
.draggable-mirror {
  max-width: 80px;
  max-height: 80px;

.resolution {
  display:none;
}
  img {
    object-fit: cover;
  }
}
.previewGenerator {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  position: fixed;
  // display: flex;
  z-index: 7;
  justify-content: flex-end;
  align-items: flex-end;
  display: none;
  pointer-events: none;
  &.active {
    display: flex;
  }
  .invisible-content {
    display: none;
  }
  img {
    max-width: 50%;
    height: auto;
  }
}
.app {
  height: 100%;
}
body, html {
  width: 100%;
  height: 100%;
  overflow: 'hidden';
   -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

[contenteditable] {

   -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
     -khtml-user-select: text; /* Konqueror HTML */
       -moz-user-select: text; /* Old versions of Firefox */
        -ms-user-select: text; /* Internet Explorer/Edge */
            user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import 'vue-select/src/scss/vue-select.scss';

::-webkit-scrollbar-track
{
  background-color: transparent;
}

::-webkit-scrollbar
{
  margin-left: 5px;
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  margin-left: 5px;
  border-radius: 3px;
  background-color: #A1A7AF;
}

.spinner {
  z-index: 99999!important;
  .loading-circle {
    border-left-color: #157CFD !important;
    border-right-color: #157CFD !important;
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: white;
    color: black;
    border-radius: 5px;
    padding: 5px 10px 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.iconPicker__header input {
  width: 91%;
  padding: 1em;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;

  &.active {
    display: flex;
  }
}
.frameOptions {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
  i {
    font-size: 26px;
  }
}

.confirm-dialog {
  .vc-container {
    border-radius: 0.7rem;

    .vc-btn {
      color: #157CFD;
      font-size: 0.7rem;
      min-height: 39px;
    }
  }
}

#app {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}

.vue-editable-input{
  padding: 0!important;
}

@media only screen and (max-width: 760px) {
  body {
    background: white;
  }
.appTop {
  position: fixed;
  z-index: 99;
  background: none;
}
}

.dialog-iw {
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  .vc-container {
    box-shadow: none;
  }
}
</style>
