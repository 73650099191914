import Vue from 'vue'
import Vuex from 'vuex'
import apiService from '../../api/endpoints'

Vue.use(Vuex)

export default {
  state: {
    staticMenu: [
      {
        icon: 'fal fa-cloud-upload',
        status: 'active',
        thumbnail: 'https://thumbs.dreamstime.com/z/halloween-background-moon-dead-tree-halloween-background-moon-dead-tree-124918442.jpg',
        title: 'My Uploads',
        type: 'user'
      },
      // {
      //   icon: 'fal fa-pencil-paintbrush',
      //   status: 'active',
      //   thumbnail: 'https://thumbs.dreamstime.com/z/halloween-background-moon-dead-tree-halloween-background-moon-dead-tree-124918442.jpg',
      //   title: 'My Designs',
      //   type: 'user'
      // },
      {
        icon: 'fa fa-th',
        status: 'active',
        thumbnail: 'https://thumbs.dreamstime.com/z/halloween-background-moon-dead-tree-halloween-background-moon-dead-tree-124918442.jpg',
        title: 'Admin test',
        type: 'admin'
      }
    ],
    menu: [],
    activeMenu: {
      title: 'My Uploads'
    },
    selectedCollection: null,
    collections: []
  },
  mutations: {
    setMenu (state, data) {
      const arr = data.map((menu) => {
        if (menu.Collections) {
          menu.Collections.map((i) => {
            i.imagesCount = 3
            i.designCount = 3
            i.isEdit = false
            return i
          })
        }
        return menu
      })
      state.menu = state.staticMenu
      state.menu = state.menu.concat(arr)
    },
    setActiveMenu (state, data) {
      state.activeMenu = data
      state.menuDetails = data
    },
    setCollection (state, data) {
      state.selectedCollection = data
    },
    setCollectionList (state, data) {
      state.collections = data
    }
  },
  getters: {
    getCurrentMenu (state) {
      return state.activeMenu
    }
  },
  actions: {
    getMenuList ({ commit, getters }) {
      apiService.allMenus().then((res) => {
        commit('setOverlayLoader', false, { root: true })
        commit('setMenu', res.data.data)
        const collections = []
        res.data.data.forEach((i) => {
          if (i.Collections) {
            i.Collections.forEach((c) => {
              c.menuTitle = i.title
              collections.push(c)
            })
          }
          if (getters.getCurrentMenu.title === i.title) {
            commit('setActiveMenu', i)
          }
        })
        commit('setCollectionList', collections)
      })
    }
  }
}
