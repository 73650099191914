import newStore from '@/store/newstore'

const ElementsLoader = {
  methods: {
    randomId (length) {
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var result = ''
      for (var i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    // usage: getRandomString(20); // pass desired length of random string
    },
    addText () {
      const Txt = {
        id: Date.now() + '_txt',
        name: 'Text',
        type: 'text',
        width: 450,
        height: 'auto',
        x: 0.00,
        y: 0.00,
        z: 2,
        ratio: true,
        parent: false,
        resizable: false,
        editable: false,
        draggable: true,
        active: false,
        deactivation: false,
        content: {
          fontFamily: 'Roboto',
          fontSize: 100,
          fontWeight: 'normal',
          txt: '',
          color: '#000000',
          textAlign: 'left',
          src: {
            family: 'Roboto',
            variants: [
              'regular'
            ],
            subsets: [
              'latin',
              'latin-ext'
            ],
            version: 'v27',
            lastModified: '2020-09-02',
            files: {
              regular: 'http://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf'
            },
            category: 'display',
            kind: 'webfonts#webfont'
          }
        }
      }
      newStore.dispatch('insertImage', Txt)
    },
    addEmpty (position) {
      const empty = {
        id: this.randomId(20) + '_element',
        name: 'Empty',
        type: 'empty',
        width: position.width,
        height: position.height,
        x: position.x,
        y: position.y,
        z: 2,
        stroke: 0,
        fill: '#f5f5f5',
        ratio: false,
        resizable: true,
        draggable: true,
        parent: true,
        active: false,
        deactivation: false,
        content: {}
      }
      newStore.dispatch('insertImage', empty)
    },
    async addImage (obj) {
      // // -/resize/200x/
      // thumb: '-/scale_crop/270x270/smart/',
      let w = 3000
      let h = 3000
      let wT = 1500
      let hT = 1500
      const designType = newStore.state.activeDesign.content[0].type
      let aspect = newStore.state.activeDesign.content[0].sizes.w / newStore.state.activeDesign.content[0].sizes.h

      if (designType === 'grid') {
        const emptyArray = newStore.state.activeDesign.content[0].items.filter(empty => empty.type === 'empty')
        if (emptyArray.length > 0) {
          aspect = emptyArray[0].width / emptyArray[0].height
        }
      }

      if (aspect < 1) {
        w = Math.round(h * aspect)
        wT = Math.round(hT * aspect)
      } else {
        h = Math.round(w / aspect)
        hT = Math.round(wT / aspect)
      }

      const crop = '-/resize/' + w + 'x' + '/-/preview/'
      const cropThumb = '-/resize/' + wT + 'x' + '/-/preview/'

      // url: '-/scale_crop/1920x1920/smart/',
      const thumbnail = obj.cdnUrl + cropThumb
      const url = obj.cdnUrl + crop
      let type = 'image'
      let shape = ''
      const format = obj.originalImageInfo.format

      if (format === 'SVG') {
        type = 'shape'
        await fetch(url).then(res => res.text()).then(data => {
          shape = data
        })
      }
      // fetch(obj.thumbnail).then(res => res.text()).then(data => {

      const image = {
        id: Date.now() + '_img',
        name: 'Image',
        type: type,
        width: 'auto',
        height: 'auto',
        x: 0.00,
        y: 0.00,
        z: 2,
        content: {
          url: url,
          uuid: obj.uuid,
          thumbnail: thumbnail,
          filters: {
            base: obj.cdnUrl,
            thumb: cropThumb,
            url: crop,
            crop: crop,
            thumbWidth: wT,
            thumbHeight: hT,
            cropThumb: cropThumb,
            effect: ''
          },
          crop: '',
          object: obj,
          shape: shape
        },
        ratio: false,
        resizable: true,
        draggable: true,
        parent: true,
        active: false,
        deactivation: false
      }

      if (newStore.state.activeElement) {
        if (newStore.state.activeElement.type === 'image' || newStore.state.activeElement.type === 'empty') {
          newStore.dispatch('changeElementContent', JSON.parse(JSON.stringify(image)))
        }
      } else {
        const designType = newStore.state.activeDesign.content[0].type
        if (designType === 'grid') {
          const emptyArray = newStore.state.activeDesign.content[0].items.filter(empty => empty.type === 'empty')
          if (emptyArray.length > 0) {
            emptyArray[0].content = image.content
            emptyArray[0].type = image.type
          } else {
            console.log('so this artboard is full')
          }
        } else {
          const newWidth = newStore.state.activeDesign.content[0].sizes.w / 3
          const newX = (newStore.state.activeDesign.content[0].sizes.w / 2) - (newWidth / 2)
          const newY = (newStore.state.activeDesign.content[0].sizes.h / 2) - (newWidth / 2)
          image.width = newWidth
          image.height = newWidth
          image.x = newX
          image.y = newY
          newStore.dispatch('insertImage', image)
        }
      }
    }
  }
}

export default ElementsLoader
