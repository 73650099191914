import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// This is an old file, thinking about observables to keep things simple, but like advice.
export default {
  state: {
    product: null,
    productList: null
  },
  getters: {
    product: state => {
      return state.product
    },
    productList: state => {
      return state.productList
    }
  },
  mutations: {
    activeProduct (state, product) {
      state.product = product
    },
    productList (state, products) {
      state.productList = products
    }
  },
  actions: {
  }
}
