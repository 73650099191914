<template>
  <div id="Gallery" class="gallery-flex">
    <div class="media-grid-container">
      <button class="fixedBottom cta dark" @click="closeSidebar(), $postEvent('iframeClickEvent', 'Fotos Kiezen Klaar')">{{ $t('system.labels.done') }}</button>
      <!-- <searchInput v-bind:placeholder="'Search '+ getActiveMenu.title"></searchInput> -->
      <Progress class="float-right" :onlyProgress="true" />
      <adminGallery v-if="getActiveMenu.type === 'admin'"/>

      <div class="collection-list" v-if="getActiveMenu.type !== 'user'">
        <div class="menu-title">
          <h3>{{ getActiveMenu.title }}</h3>
          <!-- <div v-if="getActiveMenu.title === 'My Uploads'">
            <button v-if="user" @click="isEdit = !isEdit; checkedUploads = []">{{ isEdit ? 'Cancel' : 'Edit' }}</button>
            <button @click="deleteUpload()" v-if="isEdit && checkedUploads.length > 0">Delete</button>
          myCollection.where(firestore.FieldPath.documentId(), 'in', ["123","456","789"])
          </div> -->
        </div>
          <div v-if="settings && userType === 'firebase'">
            <button v-if="!addCollection" @click="addCollection = true">Voeg een collectie toe</button>
            <div v-if="addCollection">
              <input placeholder="Titel van de collectie" v-model="collectionTitle" />
              <button @click="saveCollection" class="cta">Save</button>
              <button @click="addCollection = false">Cancel</button>
            </div>
          </div>
        <div v-for="(collection, index) in collections.content" :key="index">
          <h4>{{ collection.info.title }} ({{ collection.images.length }})</h4>
          <uploadCare :type="'collectionPhotos'" :collectionID="collection.info.collectionID" :buttonName="'Add Photos'" :multipleMax="150" :multiple="true" v-if="userType === 'firebase'" class="uc-container"></uploadCare>
          <galleryList :collection="collection"></galleryList>
        </div>

      </div>
      <div v-if="getActiveMenu && getActiveMenu.type === 'user'">

        <div class="menu-title">
          <h3>{{ getActiveMenu.title }}</h3>
          <!-- <div v-if="getActiveMenu.title === 'My Uploads'">
            <button v-if="user" @click="isEdit = !isEdit; checkedUploads = []">{{ isEdit ? 'Cancel' : 'Edit' }}</button>
            <button @click="deleteUpload()" v-if="isEdit && checkedUploads.length > 0">Delete</button>
          </div> -->
        </div>
        <div v-if="getActiveMenu.title === 'Layout'">
          <layouts :inline="true"></layouts>
          <gridSize :inline="true"></gridSize>
          <borderSize :inline="true"></borderSize>
        </div>

        <uploadCare :type="'photos'" :buttonName="'Upload Photos'" :multipleMax="150" :multiple="true" v-if="getActiveMenu.title === 'My Uploads' && display === 'sidebar'" class="uc-container"></uploadCare>

        <div v-if="getActiveMenu.title === 'My Uploads'" class="media-grid-content">
           <layouts :hidden="true"></layouts>
          <div v-if="uploadsDesign.length === 0 && !mobile" style="width: 100%;   grid-area: 1 / 1 / span 2 / span 3; ">
            <p>{{$t('system.uploadDescription')}}</p>
            <layouts :inline="true"></layouts>
          </div>
          <div class="media-grid-item" v-for="(post) in uploadsDesign.slice(0, slice).reverse()" :key="post.id">
            <input type="checkbox" v-if="isEdit" class="leftBottom" v-model="checkedUploads" :id="post.id" :value="post.id">

            <div class="resolution" v-bind:class="{ lowres: ((checkResolution(post) === 'low'))}"><i class="fas fa-exclamation-circle" color="orange"></i> {{ $t('system.warnings.resolution.low') }}</div>

            <div class="postCounter" v-bind:class="{ checked: (checkUsed(post) === 1) }"><i class="far fa-check"></i></div>

            <div v-on:click="select(post, 'image'), $postEvent('iframeClickEvent', 'Fotos Kiezen')" style="display: flex;" v-bind:class="{ used: (checkUsed(post) === 1) }">
              <label :for="post.id">
                <gImage :src="post.cdnUrl+'-/scale_crop/180x180/smart/'"></gImage>
              </label>
            </div>
          </div>
        </div>

        <div v-if="getActiveMenu.title === 'My Designs'" class="media-grid-content designs">
          <div class="media-grid-item design-item" v-for="(post) in uploadsDesign.slice(0, slice)" :key="post.dID">
            <button class="float right clean" @click="deleteDesigns(post)"><i class="fal fa-circle"></i></button>
            <!-- <button v-if="user" class="clean rightBottom" @click="downloadPDF(post)"><i class="fal fa-file-pdf"></i></button> -->
            <div class="media-grid-image" v-on:click="selectDesign(post)" style="display: flex;">
              <gImage :src="post.thumbnail"></gImage>
            </div>
            <div class="item-title">{{ post.title }}</div>
          </div>
        </div>

        <button style="margin: 5px; width: 99%" v-if="uploadsDesign.length > 27 && !(uploadsDesign.length === slice)" @click="slice = uploadsDesign.length">Show All</button>

      </div>

      <div v-if="getActiveMenu && getActiveMenu.type === 'custom'">

        <div class="menu-title">
          <h3>{{ getActiveMenu.title }}</h3>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import uploadCare from '@/components/common/uploadcare.vue'
import gImage from '@/components/elements/image.vue'
import adminGallery from './components/adminGallery'
import apiService from '@/api/endpoints'
import elementsLoader from '@/helpers/elements'
import layouts from '@/views/Canvas/Artboard/Components/layouts.vue'
import gridSize from '@/views/Canvas/Artboard/Components/gridsize.vue'
import borderSize from '@/views/Canvas/Artboard/Components/bordersize.vue'
import Vue from 'vue'
import newStore from '@/store/newstore'
import { uuid } from 'vue-uuid'
import galleryList from './gallery-list.vue'
import Progress from '@/views/Canvas/Artboard/Components/progress'

export default {
  components: {
    gImage,
    uploadCare,
    adminGallery,
    galleryList,
    layouts,
    gridSize,
    borderSize,
    Progress
    // searchInput
  },
  filters: {
    reverse: function (value) {
      return value.slice().reverse()
    }
  },
  props: {
    display: null,
    activeMenu: null
  },
  watch: {
    getActiveMenu (menu) {
      if (menu) {
        this.slice = 27
        this.activeMenu = menu
        this.posts = menu.Collections ? menu.Collections : []
        this.posts = this.posts.sort((a, b) => a.order - b.order)
      }
    }
  },
  data () {
    return {
      slice: 27,
      fetch: null,
      posts: [],
      errors: [],
      isEdit: false,
      checkedUploads: [],
      usedImages: [],
      addCollection: false,
      collectionTitle: null
    }
  },
  created () {
    this.posts = newStore.state.menuDetails?.Collections || []
    this.posts = this.posts.sort((a, b) => a.order - b.order)
  },
  mounted () {
    this.loadMenus()
    this.checkUsed()
  },
  methods: {
    saveCollection () {
      const id = uuid.v1()
      const payload = {
        menuID: this.getActiveMenu.menuID,
        collectionID: id,
        title: this.collectionTitle
      }
      newStore.dispatch('addCollection', payload)
    },
    closeSidebar () {
      newStore.commit('sidebar', false)
    },
    checkUsed (id) {
      // const search = this.usedImages.indexOf(id)
      let search = []
      if (id) {
        search = this.checkUsedImages.filter(el => el === id.uuid)
      }
      return search.length
    },
    loadMenus () {
      newStore.dispatch('menu')
    },
    select (payload) {
      elementsLoader.methods.addImage(payload)
    },
    checkOverlap (el, left, top) {
      const width = el.width
      const height = el.height
      var overlapping = []
      // this.getArtBoardActive.content[0].items.map((item, index) => {
      var lastIndex = 0
      this.getActiveGrid.map((item, index) => {
        const tt = item.y
        const tl = item.x
        const th = item.height
        const tw = item.width

        const tfAndBr = (top >= tt && left >= tl && tt + th > top && tl + tw > left) || (top <= tt && left < tl && top + height > tt && left + width > tl)
        const brAndTf = (left <= tl && top >= tt && left + width > tl && top < tt + th) || (top < tt && left > tl && top + height > tt && left < tl + tw)
        const bAndT = (top <= tt && left >= tl && top + height > tt && left < tl + tw) || (top >= tt && left <= tl && top < tt + th && left > tl + tw)
        const tAndB = (top <= tt && left >= tl && top + height > tt && left < tl + tw) || (top >= tt && left <= tl && top < tt + th && left > tl + tw)
        const lAndR = (left >= tl && top >= tt && left < tl + tw && top < tt + th) || (top > tt && left <= tl && left + width > tl && top < tt + th)
        const rAndL = (top <= tt && left >= tl && top + height > tt && left < tl + tw) || (top >= tt && left <= tl && top < tt + th && left + width > tl)

        if (tfAndBr || brAndTf || bAndT || tAndB || lAndR || rAndL) {
          // if (item.type !== 'background' && item.id !== el.id && item.type === 'image') {
          if (item.taken !== false) {
            overlapping.push(item)
            console.log(index)
            lastIndex = index
          }
          // }
        }
      })

      if (overlapping.length > 0) {
        overlapping.map((change, index) => {
          change.taken = true
        })
      } else {
        console.log(lastIndex)
      }
      const firstEmpty = this.getActiveGrid.find(item => item.taken === false)
      if (firstEmpty) {
        el.width = firstEmpty.width
        el.height = firstEmpty.height
        el.x = firstEmpty.x
        el.y = firstEmpty.y
        el.index = firstEmpty.index
        newStore.dispatch('insertImage', el)
        firstEmpty.taken = true
      }
    },
    deleteUpload () {
      this.$confirm({
        message: 'Are you sure you want to delete photo?',
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            if (this.user) {
              newStore.dispatch('removeImages', {
                uploadCareID: this.checkedUploads
              })
              // this.$store.dispatch('deleteUploadImageToServer', {
              //   uploadCareID: this.checkedUploads,
              //   userID: this.user.id
              // })
            } else {
              this.$store.commit('deleteUploadsToLocal', this.checkedUploads)
            }
          }
        }
      })
    },
    downloadPDF (obj) {
      this.$store.commit('setOverlayLoader', true, { root: true })
      apiService.generatePdf({
        designID: obj.designID,
        userID: obj.userID
      }).then((res) => {
        if (res.data.code === '200') {
          this.$store.commit('setOverlayLoader', false, { root: true })
          window.open(res.data.data.pdfUrl)
        } else {
          this.$store.commit('setOverlayLoader', false, { root: true })
          Vue.toasted.error(res.data.data[0], {
            position: 'top-center',
            duration: 3000
          })
        }
      })
    },
    deleteDesigns (obj) {
      this.$confirm(
        {
          message: 'Are you sure you want to delete design?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              if (this.user) {
                const req = {
                  reqParams: {
                    designID: obj.designID,
                    userID: this.user.id
                  },
                  obj: obj
                }
                this.$store.dispatch('deleteDesignsToServer', req)
              } else {
                this.$store.commit('deleteDesign', obj)
              }
            }
          }
        }
      )
    },
    selectDesign (post) {
      // Todo check it ut
      newStore.dispatch('selectDesign', post)
      // newStore.dispatch('selectDesign', post)
      // this.setActiveProduct(post)
      // this.$store.commit('changeArtboardContent', post)
      // if (this.display === 'inline') {
      //   this.$store.commit('closeComponent')
      // }
    },
    checkResolution (image) {
      if (image.originalImageInfo.width < 720 || image.originalImageInfo.height < 720) {
        return 'low'
      } else {
        return 'high'
      }
    },
    setActiveProduct (artBoard) {
      if (artBoard.productID) {
        this.productList.forEach((i) => {
          if (artBoard.productID === i.id) {
            this.$store.commit('activeProduct', i)
          }
        })
      } else {
        this.$store.commit('activeProduct', null)
      }
    }
  },
  computed: {
    checkUsedImages () {
      const usedImages = []
      if (newStore.state.activeDesign) {
        const keys = newStore.state.activeDesign.content[0].items
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].type === 'image' && keys[i].content.uuid) {
            const idArray = keys[i].content.uuid
            usedImages.push(idArray)
          }
          if (keys[i].type === 'shape' && keys[i].content.uuid) {
            const idArray = keys[i].content.uuid
            usedImages.push(idArray)
          }
        }
      }
      return usedImages
    },
    user () {
      return newStore.state.user || []
    },
    userType () {
      return newStore.state.userType || []
    },
    settings () {
      return newStore.state.settings || false
    },
    designs () {
      return newStore.state.designs || []
    },
    mobile () {
      return newStore.state.mobile || false
    },
    collections () {
      const search = newStore.state.collections.find(collection => collection.title === this.activeMenu.title) || []
      return search
      // return newStore.state.collections
    },
    getActiveElement () {
      return newStore.state.activeElement
    },
    getActiveCollection () {
      return newStore.state.activeCollection
    },
    getActiveGrid () {
      return newStore.state.activeGrid
    },
    productList () {
      return this.$store.state.productsStore.productList
    },
    displayStyle () {
      return this.display
    },
    uploadsDesign () {
      if (this.getActiveMenu.title === 'My Uploads') {
        // return newStore.state.images || []
        return newStore.state.images.sort((a, b) => { return new Date(a.date) - new Date(b.date) }) || []
        // return this.$store.state.userStore.uploads || []
      } else {
        return newStore.state.designs || []
        // return this.$store.state.userStore.designs || []
      }
    },
    getActiveMenu () {
      return this.activeMenu ? this.activeMenu : newStore.state.activeMenu
    },
    // getActiveElement () {
    //   return this.$store.state.artBoardStore.activeElement
    // },
    getArtBoardActive () {
      return newStore.state.activeDesign
      // return this.$store.state.artBoardStore.artBoards
    }
  }
}

</script>

<style lang="scss">
.menu-title {
  display: flex;
  justify-content: space-between;
}

.flip-list-move {
  transition: transform 0.5s;
}

.float-right {
  position: absolute;
  right: 20px;
}

.no-move {
  transition: transform 0s;
}

.placeholder {
  opacity: 0;
}

.gallery-flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  height: auto;
  // min-height: 100%;

  background: white;
  height: auto;
  align-self: stretch;
  margin: 10px 0;
  border-radius: 16px;
  padding: 8px;

.resolution {
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 10px;
  display: none;
  background: white;
  border-radius: 99px;
  padding: 3px;
  padding-right: 5px;
  flex-direction: row;
  align-items: center;
  i {
    margin-right: 5px;
  }
 &.lowres {
   display: flex;
 }
}
}

.media-grid-container {
  overflow-x: hidden;
  width: 100%;
  flex: 1;
  h3 {
    margin-bottom: 10px;
  }
}

.media-grid-content {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3,31.7%);
  justify-content: stretch;
  align-items: flex-start;

  &.designs {
    grid-template-columns: repeat(2, auto);
  }
}

.uc-container {
  // position: sticky;
  // top: 0px;
}

  .fixedBottom {
    position: absolute;
    bottom: 30px;
    display: none;
    z-index: 10;
    left: 30px;
    right: 30px;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 99px;
    background: black !important;
    padding-left: 15px;
    padding-right: 15px;
  }

@media only screen and (max-width: 760px) {
  .uc-container {
    // position: sticky;
    // top: 0px;
  }
  .fixedBottom {
    display: flex;
  }
  .media-grid-container {
    padding-bottom: 90px;
  }
}

.media-grid-elements {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(1, auto);
  justify-content: space-around;
  align-items: flex-start;

  img {
    width: 100%;
  }
}

.media-grid-item {
  position: relative;
  cursor: pointer;
  transition: transform 250ms;
  transform: scale(1);
  border-radius: 5px;
  min-height: 80px;
  min-width: 30%;
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  align-items: center;

  &.design-item {
    min-width: 100%;
  }

  img {
    border-radius: 8px;
  }
  .used {
    pointer-events: none;
  }
  .used img {
    opacity: 0.5;
  }

  .float {
    &.right {
      position: absolute;
      right: 5px;
      top: 8px;
      width: 20px;
      height: 20px;
      color: #dbd5d5;
      background: white;
      border-radius: 100px;
      padding: 2px;
      z-index: 1;
    }
  }

  .rightBottom {
    position: absolute;
    right: 5px;
    bottom: 30px;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 100px;
    padding: 2px;
    z-index: 1;
  }

  .leftBottom {
    position: absolute;
    left: 5px;
    bottom: 30px;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 100px;
    padding: 2px;
    z-index: 1;
  }

  .edit {
    position: absolute;
    right: 5px;
    bottom: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .delete {
    position: absolute;
    left: 5px;
    bottom: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .media-grid-image {
    min-height: 100%;
    min-width: 100%;
  }
}

.designs {
  .media-grid-item {
    flex-direction: column;
  }

  .download {
    position: relative;
  }
}

.wide {
  .media-grid-content {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-template-rows: masonry;
  }

  .media-grid-item {
    max-height: auto;
    width: auto;
  }
}

.media-grid-item .image {
  border-radius: 8px;
}

.media-grid-item .image:hover {
  filter: brightness(0.95)
}

.item-title {
  padding-top: 5px;
}

.media-grid-item img {
  width: 100%;
  height: auto;
  z-index: 0;
}

.collection {
  padding: 5px;
  padding-bottom: 15px;
  margin-bottom: 50px;
  border-radius: 10px;
  border: 1px solid #dadada;

  .collection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    button {
      margin: 0 10px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }

}

INPUT[type=checkbox] {
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #0080ff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 2px;
}

INPUT[type=checkbox]:checked {
  background: #0080ff url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 7px 8px no-repeat;
}
.postCounter {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 18px;
  display: none;
  justify-content: center;
  align-items: center;
  height: 18px;
  background: #0080ff;
  border-radius: 99px;
  border: 2px solid white;
  padding: 5px;
  z-index:1;
  &.checked {
    display: flex;
    i {
      color: white;
    }
  }
}

</style>
