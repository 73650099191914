import axios from 'axios'
import store from '../store/index'

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})
Api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error?.response?.status
    if (status === 403) {
      store.dispatch('refreshUser', store.state.userStore.user.refresh_token)
    }
    return Promise.reject(error)
  }
)
export default Api
