import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    artBoards: null,
    grayscale: true,
    activeElement: null,
    fontList: ['Roboto']
  },
  getters: {
    artBoards: state => {
      return state.artBoards
    },
    fontsLoaded: state => {
      return state.fontList
    },
    activeElement: state => {
      return state.activeElement
    },
    artBoardsCount: (state, getters) => {
      return getters.artBoards?.content?.length
    }
  },
  mutations: {
    lockElement (state) {
      state.activeElement.draggable = false
      state.activeElement.resizable = false
    },
    unlockElement (state) {
      state.activeElement.draggable = true
      state.activeElement.resizable = true
    },
    addFontLoaded (state, name) {
      state.fontList.push(name)
    },
    changeActiveSize (state) {
      console.log('got here', state.activeElement)
    },
    changeOrderElement (state, i) {
      state.artBoards.content[0].items = i
      const st = Object.assign({}, state.artBoards)
      Vue.set(state, 'artBoards', st)
    },
    changeArtBoard (state, artBoard) {
      Vue.set(state, 'artBoards', null)
      setTimeout(() => {
        Vue.set(state, 'artBoards', JSON.parse(JSON.stringify(artBoard)))
      })
    },
    changeArtboardContent (state, artboard) {
      var newArtboard = state.artBoards
      var newC = artboard.content[0].items
      newArtboard.content[0].items = newC

      Vue.set(state, 'artBoards', null)
      setTimeout(() => {
        Vue.set(state, 'artBoards', JSON.parse(JSON.stringify(newArtboard)))
      })
      // console.log(state.artBoards.content[0].items.length)
      // state.artBoards.content[0].items.splice(1)

      // newC.push(artboard.content[0].items)
      // state.artBoards.content[0].items.splice(1)
      // state.artBoards.content[0].items = newC

      // console.log(state.artBoards.content[0].items.length)
      // state.artboards.content[0].items.splice(1)
    },
    checkFilters (state) {
      state.grayscale = !state.grayscale
      state.artBoards.content[0].items.forEach((i) => {
        if (i.type === 'image') {
          var urlModifier = ''
          if (state.grayscale === true) {
            i.filters.grayscale = true
          } else {
            i.filters.grayscale = false
          }
          // i.filters.grayscale = true
          if (i.filters.grayscale === true) {
            urlModifier += '-/grayscale/'
          }

          i.url = i.object.cdnUrl + i.filters.url + urlModifier
          i.thumbnail = i.object.cdnUrl + i.filters.thumb + urlModifier
        }
      })
    },
    activeArtBoard (state, artBoard) {
      Vue.set(state, 'artBoards', artBoard)
    },
    addElementToArtBoard (state, element) {
      state.artBoards.content[0].items.push(element)
    },
    changeElementContent (state, element) {
      state.activeElement.url = element.url
      state.activeElement.thumbnail = element.thumbnail
      state.activeElement.obj = element.obj
      state.artBoards.content[0].items.forEach((i) => {
        if (i.id === state.activeElement.id) {
          i.url = element.url
          i.thumbnail = element.thumbnail
          i.obj = element.obj
        }
      })
    },
    setChangesInArtBoard (state, artBoard) {
      Vue.set(state, 'artBoards', artBoard)
    },
    removeElementToArtBoard (state, element) {
      const i = state.artBoards.content[0].items.indexOf(element)
      state.artBoards.content[0].items.splice(i, 1)
    },
    setActivateElement (state, activeElement) {
      Vue.set(state, 'activeElement', activeElement)
    },
    historySetActiveArtBoard (state, artBoard) {
      Vue.set(state, 'artBoards', artBoard)
    }
  }
}
