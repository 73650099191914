<template>
    <div>
        <router-link to="/products">Products</router-link>
        <router-link to="/support">Support</router-link>
        <div @click='$router.back()'>c</div>
        <component :is="component_is">sss</component>
        {{ component_is }}
    </div>
</template>

<script>
export default {
  name: 'Common',
  props: ['component'],
  data () {
    return {
      component_is: this.$component
    }
  }
}
</script>

<style>

</style>
