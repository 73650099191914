export const messages = {
  en: {
    message: {
      hello: 'Hi world'
    },
    system: {
      upload: 'Upload pictures',
      uploadDescription: 'Choose a layout for your Instawall, upload photos and get started. Be creative, you are!',
      uploadEmpty: 'You haven\'t uploaded any photos yet, check out our gallery or upload a photo.',
      layout: 'Choose a layout',
      grid: 'Spacing',
      border: 'outer border',
      startTyping: 'Type here',
      back: 'Back',
      send: {
        tooltip: 'Email your Instawall and continue later'
      },
      labels: {
        cancel: 'cancel',
        email: 'email address',
        send: 'send',
        close: 'close',
        save: 'save',
        settings: 'settings',
        crop: 'crop',
        done: 'done'
      },
      tooltips: {
        upload: 'Upload photos here',
        layout: 'Choose a layout here',
        grid: 'Space between photos',
        border: 'Outer border',
        filters: 'Effects',
        crop: 'Crop'
      },
      warnings: {
        resolution: {
          low: 'Low quality'
        }
      }
    },
    product: {
      select: 'customize product'
    },
    icons: {
      photos: 'photos',
      delete: 'Delete',
      uploads: 'Uploads',
      layout: 'Layout',
      quotes: 'Quotes',
      patterns: 'Patterns',
      creative: 'Creative',
      grid: 'Lines',
      border: 'Border',
      filter: 'Filter',
      crop: 'Crop',
      color: 'Color',
      collapse: 'Collapse',
      expand: 'Expand'
    },
    cta: {
      buy: 'Order',
      done: 'Done'
    },
    user: {
      myUploads: 'My photos'
    },
    magic: {
      title: 'Send your project to your email.',
      body: 'No time? Continue later by sending yourself a magiclink.',
      bodyUser: 'Please note, we are sending a copy of your design.',
      success: 'The link has been sent'
    },
    control: {
      title: 'Almost done!',
      body: 'Please check your Instawall one more time to make sure that all the photos are cropped correctly and/or there are any duplicate photos in there.',
      cancel: 'Back',
      order: 'Order now'
    },
    filter: {
      none: 'None',
      silver: 'Silver',
      gray: 'Gray',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  },
  nl: {
    message: {
      hello: 'Hoi world'
    },
    system: {
      upload: 'Upload foto\'s',
      uploadDescription: 'Kies een layout voor jouw Instawall, upload foto\'s en ga aan de slag. Be creative, you are!',
      uploadEmpty: 'Je hebt nog geen foto\'s geupload, bekijk onze gallerij of upload een foto.',
      layout: 'Kies een layout',
      grid: 'Tussenruimte',
      border: 'Buitenrand',
      startTyping: 'Type hier',
      back: 'Terug',
      send: {
        tooltip: 'E-mail je Instawall en ga later verder'
      },
      labels: {
        cancel: 'annuleren',
        email: 'e-mail adres',
        send: 'versturen',
        close: 'sluiten',
        save: 'opslaan',
        settings: 'Instellingen',
        crop: 'bijsnijden',
        done: 'Gereed'
      },
      tooltips: {
        upload: 'Upload hier foto\'s',
        layout: 'Kies hier een layout',
        grid: 'Ruimte tussen de foto\'s',
        border: 'Buitenrand',
        filters: 'Effecten',
        crop: 'Bijsnijden'
      },
      warnings: {
        resolution: {
          low: 'Lage kwaliteit'
        }
      }
    },
    product: {
      select: 'product aanpassen'
    },
    icons: {
      photos: 'Foto\'s',
      delete: 'Delete',
      uploads: 'Uploads',
      layout: 'Layout',
      quotes: 'Quotes',
      patterns: 'Patronen',
      creative: 'Creative',
      grid: 'Lijnen',
      border: 'Rand',
      filter: 'Filter',
      crop: 'Bijsnijden',
      color: 'Kleur',
      collapse: 'Inklappen',
      expand: 'Uitklappen'
    },
    cta: {
      buy: 'Bestellen',
      done: 'Klaar'
    },
    user: {
      myUploads: 'Mijn foto\'s'
    },
    magic: {
      title: 'Stuur je project naar je mail.',
      body: 'Geen tijd? Ga later verder door jezelf een magiclink te sturen.',
      bodyUser: 'Let op, wij versturen een kopie van je ontwerp.',
      success: 'De link is verzonden'
    },
    control: {
      title: 'Almost done!',
      body: 'Controleer je Instawall nog even of alle foto\'s goed zijn bijgesneden en/of er eventueel dubbele foto\'s in zitten.',
      cancel: 'Terug',
      order: 'Nu bestellen'
    },
    filter: {
      none: 'Geen',
      silver: 'Zilvertinten',
      gray: 'Grijstinten',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    },
    uploading: 'Uploaden... Even geduld.',
    loadingInfo: 'Laden informatie...',
    errors: {
      default: 'Fout',
      baddata: 'Ongeldige waarde',
      size: 'Bestand is te groot',
      upload: 'Kan niet uploaden',
      user: 'Upload geannuleerd',
      info: 'Kan informatie niet laden',
      image: 'Alleen afbeeldingen toegestaan',
      createGroup: 'Kan bestandsgroep niet maken',
      deleted: 'Bestand is verwijderd'
    },
    draghere: 'Drop hier een bestand',
    file: {
      one: '%1 bestand',
      other: '%1 bestanden'
    },
    buttons: {
      cancel: 'Annuleren',
      remove: 'Verwijderen',
      choose: {
        files: {
          one: 'Kies een bestand',
          other: 'Kies bestanden'
        },
        images: {
          one: 'Upload foto\'s',
          other: 'Upload Foto\'s'
        }
      }
    },
    dialog: {
      done: 'Klaar',
      showFiles: 'Toon bestanden',
      tabs: {
        names: {
          preview: 'Voorvertoning',
          file: 'Computer',
          url: 'Directe links'
        },
        file: {
          drag: '',
          nodrop: 'Upload foto\'s van je computer',
          or: '',
          button: 'Foto\'s kiezen',
          also: 'Je kan ook selecteren van'
        },
        camera: {
          title: 'Bestand van webcamera',
          retry: 'Opnieuw toegang aanvragen'
        },
        url: {
          title: 'Bestanden op het web',
          line1: 'Selecteer een bestand op het web.',
          line2: 'Voer de link in.',
          input: 'Plak de link hier...',
          button: 'Upload'
        },
        preview: {
          unknownName: 'onbekend',
          change: 'Annuleren',
          back: 'Terug',
          done: 'Toevoegen',
          unknown: {
            title: 'Uploaden... Wacht op de voorvertoning.',
            done: 'Voorvertoning overslaan an accepteren'
          },
          regular: {
            title: 'Dit bestand toevoegen?',
            line1: 'Je staat op het punt bovenstaand bestand toe te voegen.',
            line2: 'Bevestig'
          },
          image: {
            title: 'Voeg deze afbeelding toe?',
            change: 'Annuleren'
          },
          crop: {
            title: 'Afbeelding bijknippen en toevoegen',
            done: 'Klaar'
          },
          error: {
            default: {
              title: 'Oeps!',
              text: 'Er is een fout opgetreden tijdens het uploaden.',
              back: 'Probeer opnieuw'
            },
            image: {
              title: 'Alleen afbeeldingen worden geaccepteerd.',
              text: 'Probeer opnieuw met een andere bestand.',
              back: 'Selecteer afbeelding'
            },
            size: {
              title: 'Het geselecteerd bestand is groter dan de limiet.',
              text: 'Probeer opnieuw met een andere bestand.'
            },
            loadImage: {
              title: 'Fout',
              text: 'Kan afbeelding niet laden'
            }
          },
          multiple: {
            title: 'Je hebt de volgende bestanden geselecteerd %files%',
            question: 'Wil je al deze bestanden toevoegen?',
            tooManyFiles:
                'Je hebt teveel bestanden geselecteerd. %max% is het maximum.',
            tooFewFiles:
                'Je hebt de volgende bestanden geselecteerd %files%. Minimaal %min% is verplicht.',
            clear: 'Verwijder alle bestanden',
            done: 'Klaar'
          }
        }
      }
    }
  },
  de: {
    message: {
      hello: 'Hallo Welt'
    },
    system: {
      upload: 'Fotos hochladen',
      uploadDescription: 'Wählen Sie ein Layout für Ihre Instawall, laden Sie Fotos hoch und legen Sie los. Seien Sie kreativ, Sie sind es!',
      uploadEmpty: 'Sie haben noch keine Fotos hochgeladen, sehen Sie sich unsere Galerie an oder laden Sie ein Foto hoch.',
      layout: 'Wählen Sie ein Layout',
      grid: 'Abstände',
      border: 'äußerer Rahmen',
      startTyping: 'Hier eingeben',
      back: 'Zurück',
      send: {
        tooltip: 'Mailen Sie Ihre Instawall und fahren Sie später fort'
      },
      labels: {
        cancel: 'Abbrechen',
        email: 'E-Mail-Adresse',
        send: 'senden',
        close: 'schließen',
        save: 'speichern',
        settings: 'Einstellungen',
        crop: 'Zuschneiden',
        done: 'Erledigt'
      },
      tooltips: {
        upload: 'Hier Fotos hochladen',
        layout: 'Wählen Sie hier ein Layout',
        grid: 'Abstand zwischen den Fotos',
        border: 'Äußerer Rahmen',
        filter: 'Effekte',
        crop: 'Zuschneiden'
      },
      warnings: {
        resolution: {
          low: 'Niedrige Qualität'
        }
      }
    },
    product: {
      select: 'Produkt anpassen'
    },
    icons: {
      photos: 'Fotos',
      delete: 'Löschen',
      uploads: 'Uploads',
      layout: 'Layout',
      quotes: 'Zitate',
      patterns: 'Muster',
      creative: 'Kreativ',
      grid: 'Linien',
      border: 'Umrandung',
      filter: 'Filter',
      crop: 'Zuschneiden',
      color: 'Farbe',
      collapse: 'Zusammenklappen',
      expand: 'Erweitern'
    },
    cta: {
      buy: 'Bestellen',
      done: 'Erledigt'
    },
    user: {
      myUploads: 'Meine Fotos'
    },
    magic: {
      title: 'Senden Sie Ihr Projekt an Ihre E-Mail.',
      body: 'Keine Zeit? Machen Sie später weiter, indem Sie sich einen magiclink schicken.',
      bodyUser: 'Bitte beachten Sie, dass wir eine Kopie Ihres Entwurfs versenden.',
      success: 'Der Link wurde versendet'
    },
    control: {
      title: 'Fast fertig!',
      body: 'Bitte überprüfen Sie Ihre Instawall, um sicherzugehen, dass alle Bilder richtig zugeschnitten sind und/oder es keine doppelten Bilder gibt.',
      cancel: 'Zurück',
      order: 'Jetzt bestellen'
    },
    filter: {
      none: 'Geen',
      silver: 'Silbertöne',
      gray: 'Graustufen',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  },
  es: {
    message: {
      hello: 'Hola mundo'
    },
    system: {
      upload: 'Subir fotos',
      uploadDescription: 'Elige un diseño para tu Instawall, sube fotos y empieza. Sé creativo, ¡tú lo eres!',
      uploadEmpty: 'Aún no has subido ninguna foto, echa un vistazo a nuestra galería o sube una foto.',
      layout: 'Elige un diseño',
      grid: 'Espaciado',
      border: 'Borde exterior',
      startTyping: 'Escribe aquí',
      back: 'Atrás',
      send: {
        tooltip: 'Envía tu Instawall y continúa más tarde'
      },
      labels: {
        cancel: 'cancelar',
        email: 'dirección de correo electrónico',
        send: 'enviar',
        close: 'cerrar',
        save: 'guardar',
        settings: 'ajustes',
        crop: 'recortar',
        done: 'Hecho'
      },
      tooltips: {
        upload: 'Sube fotos aquí',
        layout: 'Elige un diseño aquí',
        grid: 'Espacio entre fotos',
        border: 'Borde exterior',
        filters: 'Efectos',
        crop: 'Recortar'
      },
      warnings: {
        resolution: {
          low: 'Calidad baja'
        }
      }
    },
    product: {
      select: 'personalizar producto'
    },
    icons: {
      photos: 'fotos',
      delete: 'Eliminar',
      uploads: 'Subidas',
      layout: 'Diseño',
      quotes: 'Citas',
      patterns: 'Patrones',
      creative: 'Creativo',
      grid: 'Líneas',
      border: 'Borde',
      filter: 'Filtro',
      crop: 'Recortar',
      color: 'Color',
      collapse: 'Colapsar',
      expand: 'Expandir'
    },
    cta: {
      buy: 'Pedido',
      done: 'Hecho'
    },
    user: {
      myUploads: 'Mis fotos'
    },
    magic: {
      title: 'Envía tu proyecto a tu correo electrónico.',
      body: '¿No tienes tiempo? Continúa más tarde enviándote un magiclink',
      bodyUser: 'Tenga en cuenta que le enviamos una copia de su diseño',
      success: 'El enlace ha sido enviado'
    },
    control: {
      title: '¡Ya casi está!',
      body: 'Por favor, comprueba tu Instawall una vez más para asegurarte de que todas las fotos están recortadas correctamente y/o de que no hay fotos duplicadas',
      cancel: 'Back',
      order: 'Pide ahora'
    },
    filter: {
      none: 'No',
      silver: 'Tonos plateados',
      gray: 'Escala de grises',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour à tous'
    },
    system: {
      upload: 'Télécharger des images',
      uploadDescription: 'Choisissez une mise en page pour votre Instawall, téléchargez des photos et commencez. Soyez créatif, vous l\'êtes !',
      uploadEmpty: 'Vous n\'avez pas encore téléchargé de photos, consultez notre galerie ou téléchargez une photo.',
      layout: 'Choisir une mise en page',
      grid: 'Espacement',
      border: 'bordure extérieure',
      startTyping: 'Tapez ici',
      back: 'Retour',
      send: {
        tooltip: 'Envoyez votre Instawall par e-mail et continuez plus tard'
      },
      labels: {
        cancel: 'annuler',
        email: 'adresse électronique',
        send: 'envoyer',
        close: 'fermer',
        save: 'save',
        settings: 'sauver',
        crop: 'culture',
        done: 'fait'
      },
      tooltips: {
        upload: 'Télécharger les photos ici',
        layout: 'Choisissez une mise en page ici',
        grid: 'Espace entre les photos',
        border: 'Bordure extérieure',
        filters: 'Effets',
        crop: 'Culture'
      },
      warnings: {
        resolution: {
          low: 'Faible qualité'
        }
      }
    },
    product: {
      select: 'personnaliser le produit'
    },
    icons: {
      photos: 'photos',
      delete: 'Supprimer',
      uploads: 'Téléchargements',
      layout: 'Mise en page',
      quotes: 'Citations',
      patterns: 'Patterns',
      creative: 'Modèles',
      grid: 'Lignes',
      border: 'Frontière',
      filter: 'Filtre',
      crop: 'Culture',
      color: 'Couleur',
      collapse: 'Effondrement',
      expand: 'Élargir'
    },
    cta: {
      buy: 'Commande',
      done: 'Terminé'
    },
    user: {
      myUploads: 'Mes photos'
    },
    magic: {
      title: 'Envoyez votre projet à votre adresse électronique.',
      body: 'Vous n\'avez pas le temps ? Continuez plus tard en vous envoyant un magiclink.',
      bodyUser: 'Veuillez noter que nous envoyons une copie de votre dessin ou modèle.',
      success: 'Le lien a été envoyé'
    },
    control: {
      title: 'C\'est presque terminé !',
      body: 'Veuillez vérifier votre Instawall une dernière fois pour vous assurer que toutes les photos sont recadrées correctement et/ou qu\'il n\'y a pas de photos en double.',
      cancel: 'Retour',
      order: 'Commander'
    },
    filter: {
      none: 'Non',
      silver: 'Tons argentés',
      gray: 'Niveaux de gris',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  },
  da: {
    message: {
      hello: 'Hej verden'
    },
    system: {
      upload: 'Upload billeder',
      uploadDescription: 'Vælg et layout til din Instawall, upload billeder, og kom i gang. Vær kreativ, det er du!',
      uploadEmpty: 'Du har ikke uploadet nogen billeder endnu, se vores galleri eller upload et billede.',
      layout: 'Vælg et layout',
      grid: 'Afstand',
      border: 'ydre grænse',
      startTyping: 'Skriv her',
      back: 'Tilbage',
      send: {
        tooltip: 'E-mail din Instawall og fortsæt senere'
      },
      labels: {
        cancel: 'Aflys',
        email: 'e-mail-adresse',
        send: 'Send',
        close: 'tæt på',
        save: 'gemme',
        settings: 'Indstillinger',
        crop: 'afgrøde',
        done: 'færdig'
      },
      tooltips: {
        upload: 'Upload billeder her',
        layout: 'Vælg et layout her',
        grid: 'Mellemrum mellem fotos',
        border: 'Ydre kant',
        filters: 'Effekter',
        crop: 'Afgrøde'
      },
      warnings: {
        resolution: {
          low: 'Lav kvalitet'
        }
      }
    },
    product: {
      select: 'Tilpas produktet'
    },
    icons: {
      photos: 'fotos',
      delete: 'Slet',
      uploads: 'Uploads',
      layout: 'Layout',
      quotes: 'Citater',
      patterns: 'Mønstre',
      creative: 'Kreativ',
      grid: 'Linjer',
      border: 'Grænse',
      filter: 'Filter',
      crop: 'Afgrøde',
      color: 'Farve',
      collapse: 'Kollaps',
      expand: 'Udvid'
    },
    cta: {
      buy: 'Ordre',
      done: 'Færdig'
    },
    user: {
      myUploads: 'Mine billeder'
    },
    magic: {
      title: 'Send dit projekt til din e-mail.',
      body: 'Har du ikke tid? Fortsæt senere ved at sende dig selv et magiclink.',
      bodyUser: 'Bemærk venligst, at vi sender en kopi af dit design.',
      success: 'Linket er blevet sendt'
    },
    control: {
      title: 'Næsten færdig!',
      body: 'Tjek venligst din Instawall en ekstra gang for at sikre, at alle fotos er beskåret korrekt, og/eller at der ikke er nogen duplikerede fotos derinde.',
      cancel: 'Tilbage',
      order: 'Bestil nu'
    },
    filter: {
      none: 'Ingen',
      silver: 'Sølv toner',
      gray: 'Gråtoner',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  },
  sv: {
    message: {
      hello: 'Hej världen'
    },
    system: {
      upload: 'Ladda upp bilder',
      uploadDescription: 'Välj en layout för din Instawall, ladda upp foton och kom igång. Var kreativ, det är du!',
      uploadEmpty: 'Du har inte laddat upp några foton ännu, kolla in vårt galleri eller ladda upp ett foto.',
      layout: 'Välj en layout',
      grid: 'Avstånd',
      border: 'yttre gräns',
      startTyping: 'Skriv här',
      back: 'Tillbaka',
      send: {
        tooltip: 'E-posta din Instawall och fortsätt senare'
      },
      labels: {
        cancel: 'avbryta',
        email: 'e-postadress',
        send: 'skicka',
        close: 'nära',
        save: 'spara',
        settings: 'inställningar',
        crop: 'grödor',
        done: 'klar'
      },
      tooltips: {
        upload: 'Ladda upp foton här',
        layout: 'Välj en layout här',
        grid: 'Avstånd mellan foton',
        border: 'Yttre gräns',
        filters: 'Effekter',
        crop: 'Skörd'
      },
      warnings: {
        resolution: {
          low: 'Låg kvalitet'
        }
      }
    },
    product: {
      select: 'Anpassa produkt'
    },
    icons: {
      photos: 'foton',
      delete: 'Radera',
      uploads: 'Uppladdningar',
      layout: 'Layout',
      quotes: 'Citat',
      patterns: 'Mönster',
      creative: 'Kreativ',
      grid: 'Linjer',
      border: 'Gräns',
      filter: 'Filter',
      crop: 'Skörd',
      color: 'Färg',
      collapse: 'Kollaps',
      expand: 'Expandera'
    },
    cta: {
      buy: 'Order',
      done: 'Klar'
    },
    user: {
      myUploads: 'Mina bilder'
    },
    magic: {
      title: 'Skicka ditt projekt till din e-postadress.',
      body: 'Har du inte tid? Fortsätt senare genom att skicka en magisk länk till dig själv.',
      bodyUser: 'Observera att vi skickar en kopia av din design.',
      success: 'Länken har skickats'
    },
    control: {
      title: 'Nästan klart!',
      body: 'Kontrollera din Instawall en gång till för att se till att alla foton är korrekt beskurna och/eller att det inte finns några dubbla foton där.',
      cancel: 'Tillbaka',
      order: 'Beställ nu'
    },
    filter: {
      none: 'Nej',
      silver: 'Silvertoner',
      gray: 'Gråskala',
      adaris: 'Adaris',
      zatvel: 'Zatvel'
    }
  }
}
