<template>
  <div class="media-grid-content">
    <div class="media-grid-item" v-for="(image, index) in collectionList.images" :key="index">
      <div class="resolution" v-bind:class="{ lowres: ((checkResolution(image) === 'low'))}"><i class="fas fa-exclamation-circle" color="orange"></i> Slechte kwaliteit</div>
      <div class="postCounter" v-bind:class="{ checked: (checkUsed(image) === 1) }"><i class="far fa-check"></i></div>
      <div v-on:click="select(image, 'image'), $postEvent('iframeClickEvent', 'Fotos Kiezen')" style="display: flex;" v-bind:class="{ used: (checkUsed(image) === 1) }">
        <label :for="image.id">
          <gImage class="media-image" :src="image.cdnUrl+'-/scale_crop/180x180/smart/'"></gImage>
        </label>
      </div>
    </div>
    <button v-if="collection.images.length > 6 && !more" @click="more = true">Show all</button>
  </div>
</template>

<script>
import gImage from '@/components/elements/image.vue'
import newStore from '@/store/newstore'
import elementsLoader from '@/helpers/elements'

export default {
  components: {
    gImage
  },
  data () {
    return {
      more: false
    }
  },
  props: {
    collection: []
  },
  mounted () {
    this.checkUsed()
  },
  methods: {
    select (payload) {
      elementsLoader.methods.addImage(payload)
    },
    checkUsed (id) {
      let search = []
      if (id) {
        search = this.checkUsedImages.filter(el => el === id.uuid)
      }
      return search.length
    },
    checkResolution (image) {
      if (image.originalImageInfo.width < 720 || image.originalImageInfo.height < 720) {
        return 'low'
      } else {
        return 'high'
      }
    }
  },
  computed: {
    collectionList () {
      if (!this.more) {
        const newCollection = {
          images: this.collection.images
        }
        const images = newCollection.images.slice(0, 6)
        newCollection.images = images
        return newCollection
      } else {
        return this.collection
      }
    },
    checkUsedImages () {
      const usedImages = []
      if (newStore.state.activeDesign) {
        const keys = newStore.state.activeDesign.content[0].items
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].type === 'image' && keys[i].content.uuid) {
            const idArray = keys[i].content.uuid
            usedImages.push(idArray)
          }
          if (keys[i].type === 'shape' && keys[i].content.uuid) {
            const idArray = keys[i].content.uuid
            usedImages.push(idArray)
          }
        }
      }
      return usedImages
    }
  }
}
</script>

<style lang="scss">
.media-image img {
  border: 1px solid #E4E4E4;
}
.media-grid-content {
.resolution {
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 10px;
  display: none;
  background: white;
  border-radius: 99px;
  padding: 3px;
  padding-right: 5px;
  flex-direction: row;
  align-items: center;
  i {
    margin-right: 5px;
  }
 &.lowres {
   display: flex;
 }
}
}
</style>
